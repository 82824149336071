import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getVehicleTires } from 'domains/tires/Tire.store';
import { useResetQuickAccessCategories } from 'pages/CatalogPage/common/QuickAccess/QuickAccess';
import ApprovedTires from 'pages/CatalogPage/DH/Tires/ApprovedTires';
import BasicMount from 'pages/CatalogPage/DH/Tires/BasicMount';
import Informations from 'pages/CatalogPage/DH/Tires/Informations';
import OriginalFactoryTires from 'pages/CatalogPage/DH/Tires/OriginalFactoryTires';
import { SCollapse, TiresContainer } from 'pages/CatalogPage/DH/Tires/Tires.styled';
import { MarginBox, Panel, Text } from 'UI';
import { hasData } from 'utils';

const Tires = ({ setCatalogShowMenu }: { setCatalogShowMenu: (b: boolean) => void }) => {
  const tires = useSelector(getVehicleTires);
  const { t } = useTranslation();
  const [selectedTire, setSelectedTire] = useState<string | undefined>(undefined);

  useResetQuickAccessCategories();

  useEffect(() => {
    setCatalogShowMenu(false);
    return () => {
      setCatalogShowMenu(true);
    };
    // eslint-disable-next-line
  }, []);

  if (!hasData(tires)) {
    return <></>;
  }

  const activePanels = tires.originalFactoryTires
    ? ['informations', 'original_factory_tires', 'approved_tires']
    : ['informations', 'original_factory_tires', 'basic_mount', 'approved_tires'];

  return (
    <TiresContainer direction={'column'}>
      <MarginBox mt={50} />
      <SCollapse noBorder noShadow defaultActiveKey={activePanels} expandIconPosition={'end'}>
        {tires.informations && (
          <Panel
            header={<Text type={'h6'}>{t('catalog.vehicle.tires.informations', 'Informations')}</Text>}
            key="informations"
          >
            <Informations informations={tires.informations} />
          </Panel>
        )}
        {tires.originalFactoryTires && (
          <Panel
            header={
              <Text type={'h6'}>{t('catalog.vehicle.tires.original_factory_tires', 'Original factory tires')}</Text>
            }
            key="original_factory_tires"
          >
            <OriginalFactoryTires
              originalFactoryTires={tires.originalFactoryTires}
              selectedTire={selectedTire}
              setSelectedTire={setSelectedTire}
            />
          </Panel>
        )}
        {tires.basicMount && (
          <Panel
            header={<Text type={'h6'}>{t('catalog.vehicle.tires.basic_mount', 'Basic mount')}</Text>}
            key="basic_mount"
          >
            <BasicMount basicMount={tires.basicMount} selectedTire={selectedTire} setSelectedTire={setSelectedTire} />
          </Panel>
        )}
        {tires.approvedTires && (
          <Panel
            header={<Text type={'h6'}>{t('catalog.vehicle.tires.approved_tires', 'Approved tires')}</Text>}
            key="approved_tires"
          >
            <ApprovedTires
              approvedTires={tires.approvedTires}
              selectedTire={selectedTire}
              setSelectedTire={setSelectedTire}
            />
          </Panel>
        )}
      </SCollapse>
      <MarginBox mt={600} />
    </TiresContainer>
  );
};
export default Tires;
