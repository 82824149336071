import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DeliveryLeadTime } from '@1po/1po-bff-fe-spec/generated/backoffice/delivery_lead_time/response/DeliveryLeadTimeResponse';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import {
  DeliveryLeadTime as OrderDeliveryLeadTime,
  Warehouse,
  WarehouseDeliveryStatus,
} from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { SpinSize } from 'antd-v5/lib/spin';
import { RootState } from 'app/AppStore';
import { Ban, ExclamationTriangleIcon, TruckIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Popover from 'components/Popover';
import { DelayedStock, isDelayedStock } from 'components/StockInfo/DelayedStock';
import { EmptyStock, isEmptyStock } from 'components/StockInfo/EmptyStock';
import { getIcon, StockText, StockTextWithDeliveryType } from 'components/StockInfo/StockTexts';
import PrivateComponent from 'composers/PrivateComponent';
import { getBasketReference } from 'domains/basket/Basket.store';
import { getIAMReference, getStockInfo, MAX_QUANTITY_WITH_STOCKS } from 'domains/references';
import { useFetchReferenceStocks } from 'domains/references/References.requests';
import { isRefMKTP } from 'domains/references/References.utils';
import { UserRole } from 'domains/user';
import { theme } from 'styles';
import { Box, CenterFlex, Flex, Icon, Spin, Text } from 'UI';
import { FOUND, getData, hasData, NO_DATA, SearchData } from 'utils';
import { PaddingBox } from './StockDisplay.styled';

export const StockInformationUnavailable = () => {
  return (
    <Flex align={'center'}>
      <Icon IconComponent={TruckIcon} size={20} mt={4} mr={5} color={theme.color.grey55} />
      <Text type={'light_12_black_45'}>
        <Trans i18nKey={'catalog.reference.stock.stock_noinfo'}>{'Stock information unavailable.'}</Trans>
      </Text>
    </Flex>
  );
};

const ResolveMini = ({
  component,
  small,
  stock,
  relativeDate = new Date(),
  isMKTP,
  size = 20,
  spinSize,
}: {
  component: React.ReactNode;
  stock: SearchData<ReferenceStock> | undefined;
  small: boolean;
  relativeDate?: Date;
  isMKTP: boolean;
  size?: number;
  spinSize?: SpinSize;
}) => {
  if (small) {
    const warehouses = getData(stock?.data)?.warehouses;
    const currentStock = warehouses && warehouses.length > 0 ? warehouses[0] : undefined;

    return (
      <Popover
        content={
          <PaddingBox>
            <Flex justify={'center'}>{component}</Flex>
          </PaddingBox>
        }
        placement={'bottom'}
        arrowColor={theme.color.white}
        trigger={'hover'}
      >
        <DataContainer
          data={stock?.searchStatus}
          ErrorState={() => (
            <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.warning} size={size} noPointer />
          )}
          Loading={() => (
            <Box width={size} height={size}>
              <CenterFlex>
                <Spin size={spinSize || 'large'} />
              </CenterFlex>
            </Box>
          )}
          NotFound={() => <></>}
        >
          <Box width={size} height={size}>
            <CenterFlex>
              {currentStock ? (
                <>
                  {isDelayedStock(stock?.data) ? (
                    <Icon
                      IconComponent={TruckIcon}
                      size={size}
                      mt={4}
                      mr={5}
                      display={'block'}
                      color={theme.color.warning}
                      noPointer
                    />
                  ) : (
                    <>
                      {getIcon(
                        currentStock.type,
                        currentStock.expectedDeliveryDate,
                        currentStock.depan,
                        relativeDate,
                        isMKTP,
                        size,
                      )}
                    </>
                  )}
                </>
              ) : (
                <Icon IconComponent={Ban} color={theme.color.error} size={size} noPointer />
              )}
            </CenterFlex>
          </Box>
        </DataContainer>
      </Popover>
    );
  }
  return <>{component}</>;
};

interface StockInfoComponentProps {
  isApplicableToCurrentVehicle?: boolean;
  vehicleKey: string | undefined;
  referenceNumber: string;
  narrow?: boolean;
  small?: boolean;
  size?: number;
  spinSize?: SpinSize;
  sellerTresorCode?: string;
  buyerTresorCode?: string;
}

const StockDisplay = ({
  isApplicableToCurrentVehicle = false,
  vehicleKey,
  referenceNumber,
  narrow,
  small = false,
  size,
  spinSize,
  sellerTresorCode,
  buyerTresorCode,
}: StockInfoComponentProps) => {
  const { t } = useTranslation();
  const stock = getData(
    useSelector((state: RootState) => getStockInfo(state, referenceNumber, sellerTresorCode, buyerTresorCode)),
  );
  const IAMReference = useSelector((state: RootState) => getIAMReference(state, { referenceNumber, vehicleKey }));
  const isMKTP = isRefMKTP(getData(IAMReference));
  const basketReference = useSelector((state: RootState) =>
    getBasketReference(state, {
      referenceNumber,
      vehicleKey: isApplicableToCurrentVehicle && vehicleKey ? vehicleKey : undefined,
    }),
  );

  const stockRequestDetail = hasData(IAMReference)
    ? {
        referenceNumber: IAMReference.referenceNumber,
        type: IAMReference.type,
        quantity: 1,
        origin: IAMReference.origin,
        supplierCode: IAMReference.supplierCode,
        referenceSource: IAMReference.referenceSource,
      }
    : undefined;

  useFetchReferenceStocks(referenceNumber, stockRequestDetail, sellerTresorCode, buyerTresorCode);

  if ((sellerTresorCode && !buyerTresorCode) || (!sellerTresorCode && buyerTresorCode)) {
    return (
      <CenterFlex>
        <Spin size={spinSize || 'small'} />
      </CenterFlex>
    );
  }

  if (isEmptyStock(stock)) {
    return ResolveMini({ component: <EmptyStock />, stock, small, isMKTP, size, spinSize });
  }

  const moreThen999 = basketReference !== undefined && basketReference.quantity > MAX_QUANTITY_WITH_STOCKS;
  if (isDelayedStock(stock?.data) || moreThen999) {
    return ResolveMini({ component: <DelayedStock />, stock, small, isMKTP, size, spinSize });
  }

  const containsValidWarehouse =
    (stock?.data?.warehouses ?? []).filter((warehouse) => warehouse.type !== 'INVALID').length > 0;

  const Stocks = () => (
    <PrivateComponent
      render={() => (
        <DataContainer
          data={stock}
          NotFound={() => <></>}
          ErrorState={() => (
            <ErrorWithLabel
              label={t('common.stock.backend_error', 'Stocks temporarily unavailable, please try again later.')}
              narrow
            />
          )}
          Loading={() => (
            <CenterFlex>
              <Spin size={spinSize || 'small'} />
            </CenterFlex>
          )}
        >
          {containsValidWarehouse ? (
            <Flex direction={'column'}>
              {stock?.searchStatus === FOUND &&
                stock?.data?.warehouses.map((s, index) => (
                  <Flex key={`stock_display_warehouse_${index}`} size={0}>
                    <StockText
                      narrow={narrow}
                      stock={stock?.data}
                      quantity={basketReference?.quantity}
                      type={s.type}
                      displayIcon
                      index={index}
                      isMKTP={isMKTP}
                    />
                  </Flex>
                ))}
            </Flex>
          ) : (
            <StockInformationUnavailable />
          )}
        </DataContainer>
      )}
      requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
    />
  );

  return ResolveMini({
    component: <Stocks />,
    stock,
    small,
    isMKTP,
    size,
    spinSize,
  });
};

export const StockDisplayCustom = ({
  narrow,
  stock,
  quantity,
  referenceNumber,
}: {
  narrow: boolean;
  stock: NO_DATA | ReferenceStock;
  quantity: number;
  referenceNumber: string;
}) => {
  const { t } = useTranslation();
  const moreThen999 = quantity > MAX_QUANTITY_WITH_STOCKS;

  const IAMReference = useSelector((state: RootState) =>
    getIAMReference(state, { referenceNumber, vehicleKey: undefined }),
  );
  const isMKTP = isRefMKTP(getData(IAMReference));

  if (isDelayedStock(stock) || moreThen999) {
    return <DelayedStock />;
  }

  return (
    <DataContainer
      data={stock}
      NotFound={() => <></>}
      Loading={() => <Spin size={'small'} />}
      ErrorState={() => (
        <ErrorWithLabel
          label={t('common.stock.backend_error', 'Stocks temporarily unavailable, please try again later.')}
          narrow
        />
      )}
    >
      <Flex>
        {hasData(stock) &&
          stock.warehouses.map((s, index) => (
            <StockText
              key={`stock_display_warehouse_${index}`}
              narrow={narrow}
              stock={stock}
              quantity={quantity}
              type={s.type}
              index={index}
              isMKTP={isMKTP}
              displayIcon
            />
          ))}
      </Flex>
    </DataContainer>
  );
};

export const OrderStockDisplay = ({
  orderId,
  referenceNumber,
  narrow,
  warehouseDeliveryStatuses,
  quantity,
  orderDate,
  isMKTP,
}: {
  orderId: string;
  referenceNumber: string;
  narrow: boolean;
  warehouseDeliveryStatuses: WarehouseDeliveryStatus[] | undefined;
  quantity: number;
  orderDate: string;
  isMKTP: boolean;
}) => {
  if (!warehouseDeliveryStatuses || quantity > MAX_QUANTITY_WITH_STOCKS) {
    return <DelayedStock />;
  }

  const mapDeliveryLeadTime = (
    warehouse: Warehouse,
    leadTime: OrderDeliveryLeadTime | undefined,
  ): DeliveryLeadTime | undefined => {
    if (!leadTime) {
      return undefined;
    }
    return {
      ...leadTime,
      warehouse,
      isDisabled: false,
    } as DeliveryLeadTime;
  };

  const mapStocks = (status: WarehouseDeliveryStatus): ReferenceStock => {
    return {
      reference: referenceNumber,
      confirmedQuantity: status.confirmedQuantity,
      warehouses: [
        {
          order: 0,
          type: status.type,
          availableQuantity: status.availableQuantity,
          confirmedQuantity: status.confirmedQuantity,
          expectedDeliveryDate: status.expectedDeliveryDate,
          deliveryInformation: status.deliveryInformation,
          depan: status.depan,
        },
      ],
    };
  };

  return (
    <Flex direction={'row'}>
      <Flex direction={'column'}>
        {warehouseDeliveryStatuses.map((status, index) => (
          <StockTextWithDeliveryType
            key={`${orderId}_${referenceNumber}_stockStatus_${index}`}
            narrow={narrow}
            stock={mapStocks(status)}
            quantity={quantity}
            type={status.type}
            index={0}
            deliveryLeadTime={mapDeliveryLeadTime(status.type, status.deliveryLeadTime)}
            displayIcon
            relativeDate={new Date(orderDate)}
            isMKTP={isMKTP}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default StockDisplay;
