import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TechnicalCriteria } from 'pages/CatalogPage/DH/VehicleTechnicalDetails/TechnicalCriteria';
import { MarginBox, Tab, Tabs, Text } from 'UI';

const STabs = styled(Tabs)`
  .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue};
    border-radius: 0 0 0 0;
  }
  .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.blue};
  }
`;

export function VehicleTechnicalDetails() {
  const { t } = useTranslation();
  return (
    <>
      <MarginBox mt={30} />
      <Text type={'h1_banner_light'}>
        {t('catalog.details.vehicle_technical_details', 'Vehicle technical details')}
      </Text>
      <STabs type={'card'}>
        <Tab key={'1'} tab={t('catalog.details.technical_criteria', 'Technical criteria')}>
          <TechnicalCriteria />
        </Tab>
      </STabs>
    </>
  );
}
