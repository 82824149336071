/* eslint-disable max-len */
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import {
  IAMLaborTime,
  LaborTimeFilterOption,
} from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetIAMLaborTimesResponse';
import { GetMaintenancePlanResponse } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { MarketingReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetMarketingReferencesResponse';
import { IndexReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetPlateReferencesResponse';
import {
  Provider,
  Reference as IAMReference,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/model/Reference';
import {
  RangeFacet,
  TextFacet,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { IAMRepairMethod } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsResponse';
import { IAMTreeRepairMethod } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsTreeResponse';
import { VehicleBrand } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchBMMVehicleBrandsResponse';
import { VehicleEngine } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchBMMVehicleEnginesResponse';
import { IAMVehicleBrand } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleBrandsResponse';
import { IAMVehicleModel } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleModelsResponse';
import { IAMVehicleVersionGroup } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleVersionsResponse';
import { SearchVehicleResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchVehicleResponse';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/VehicleSearchHistoryResponse';
import { TechnicalDataDetailContent } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataDetailContent';
import { TechnicalDataL1 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL1';
import { IAMEquivalentReferences } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/model/IAMEquivalentReferences';
import { FullText } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetFullTextAutocompleteResponse';
import {
  ReferenceSource,
  ReferenceType,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { Level3Detail as Level3DetailRQ } from '@1po/1po-bff-fe-spec/generated/catalog/tree/request/Level3Detail';
import { Level3Detail as Level3DetailResponse } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/Level3Detail';
import { PlateDetail } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/PlateDetail';
import { UniversalProduct } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsResponse';
import { UniversalProductsCategory } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { TechnicalCriteriaType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/TechnicalCriteriaType';
import { GetIAMVehicleTechnicalCriteriaResponse } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetIAMVehicleTechnicalCriteriaResponse';
import { OTSType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetVehicleOTSResponse';
import { VehicleTechnicalCriteria } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetVehicleTechnicalCriteriaResponse';
import {
  EXPLODED_TREE_RESPONSE,
  GET_EXPLODED_TREE_IAM_RESPONSE,
  GET_FULL_TEXT_AUTOCOMPLETE_RESPONSE,
  GET_IAM_EQUIVALENT_REFERENCES_RESPONSE,
  GET_IAM_LABOR_TIMES_RESPONSE,
  GET_IAM_REFERENCES_RESPONSE,
  GET_IAM_REPAIR_METHODS_RESPONSE,
  GET_IAM_REPAIR_METHODS_TREE_RESPONSE,
  GET_IAM_TECHNICAL_DATA_DETAILS_RESPONSE,
  GET_IAM_TECHNICAL_DATA_TREE_RESPONSE,
  GET_IAM_TEXT_SEARCH_RESPONSE,
  GET_IAM_VEHICLE_BRANDS_RESPONSE,
  GET_IAM_VEHICLE_MODELS_RESPONSE,
  GET_IAM_VEHICLE_TECHNICAL_CRITERIA_RESPONSE,
  GET_IAM_VEHICLE_VERSIONS_RESPONSE,
  GET_LEVEL3_DETAILS_RESPONSE,
  GET_MAINTENANCE_PLAN_RESPONSE,
  GET_MARKETING_REFERENCES_RESPONSE,
  GET_OTS_INFORMATION_RESPONSE,
  GET_PLATE_DETAIL_RESPONSE,
  GET_PLATE_REFERENCES_RESPONSE,
  GET_REFERENCE_DETAILS_RESPONSE,
  GET_REFERENCE_TEXT_SEARCH_RESPONSE,
  GET_TEXT_SEARCH_RESPONSE,
  GET_UNIVERSAL_PRODUCTS_RESPONSE,
  GET_UNIVERSAL_PRODUCTS_TREE_RESPONSE,
  GET_VEHICLE_BRANDS_RESPONSE,
  GET_VEHICLE_ENGINES_RESPONSE,
  GET_VEHICLE_TECHNICAL_CRITERIA_RESPONSE,
  SEARCH_VEHICLE_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/model/TireItem';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { FiltersRange, RangeFacetLocal, TextFacetLocal } from 'components/Filter/Filter.types';
import { ORIGIN } from 'domains/references';
import { getIamCatalogBrandsView } from 'domains/user';
import {
  FOUND,
  getData,
  hasData,
  head,
  isLoading,
  LOADING,
  mergeMaps,
  mergeObjectInMap,
  NO_DATA,
  NOT_FOUND,
  SEARCH_STATUS,
} from 'utils';
import {
  ADD_REFERENCE_FROM_CATALOG_TO_CART,
  ADD_TIRE_FROM_CATALOG_TO_CART,
  CATALOG_NAMESPACE,
  CatalogState,
  ExplodedTree,
  FulltextSearch,
  GET_DATAHUB_TIRE_REQUEST,
  GET_FULLTEXT_AUTOCOMPLETE_REQUEST,
  GET_IAM_LABOR_TIMES_REQUEST,
  GET_IAM_REFERENCES_REQUEST,
  GET_IAM_REPAIR_METHODS_REQUEST,
  GET_IAM_REPAIR_METHODS_TREE_REQUEST,
  GET_IAM_TECHNICAL_DATA_DETAILS_REQUEST,
  GET_IAM_TECHNICAL_DATA_TREE_REQUEST,
  GET_IAM_VEHICLE_MODELS_REQUEST,
  GET_IAM_VEHICLE_TECHNICAL_CRITERIA_REQUEST,
  GET_IAM_VEHICLE_VERSIONS_REQUEST,
  GET_LEVELS3_DETAIL_REQUEST,
  GET_MAINTENANCE_PLAN_REQUEST,
  GET_MARKETING_REFERENCES_REQUEST,
  GET_OTS_INFORMATION_REQUEST,
  GET_PLATE_REFERENCES_REQUEST,
  GET_TEXT_SEARCH_REQUEST,
  GET_UNIVERSAL_PRODUCTS_REQUEST,
  GET_UNIVERSAL_PRODUCTS_TREE_REQUEST,
  GET_VEHICLE_BRANDS_REQUEST,
  GET_VEHICLE_CATEGORIES_REQUEST,
  GET_VEHICLE_CATEGORY_IMAGES_REQUEST,
  GET_VEHICLE_ENGINES_REQUEST,
  GET_VEHICLE_TECHNICAL_CRITERIA_REQUEST,
  IamCatalogBrandsType,
  IAMFulltextSearchResult,
  IAMLaborSubtableLabel,
  IAMLaborTimeLocal,
  IAMLaborTimesWrapper,
  IAMReferenceNodeWrapper,
  IAMReferences,
  IAMVehicleBrandLocal,
  MarketingReferencesWrapper,
  MultiPlate,
  OEM_BRAND_KEY,
  OTHER_BRAND_KEY,
  PlateIdParam,
  PlateImage,
  PlateInfo,
  PlateReferencesDetail,
  REMOVE_CART_REFERENCE_IN_CATALOG,
  REQUEST_SINGLE_REFERENCE,
  SEARCH_VEHICLE,
  SEARCH_VEHICLE_BMM,
  SEARCH_VEHICLE_IAM_BMM,
  SearchType,
  SearchVehicleMethod,
  SWITCH_VEHICLE_REQUEST,
  TECHNICAL_CRITERIA_SIMPLIFIED_APPLICABILITY,
  TechnicalDataDetailLocal,
  TechnicalInfoLocal,
  UniversalProductsSelection,
  VehicleBrandLocal,
  VehicleItemState,
  VehicleTechnicalDataLocal,
} from './Catalog.types';

import 'map.prototype.tojson';

function enableTechnicalCriteria(simplifiedApplicabilityEnabled: boolean, code: string | undefined) {
  if (!simplifiedApplicabilityEnabled) {
    return true;
  }
  if (!code) {
    return false;
  }

  return TECHNICAL_CRITERIA_SIMPLIFIED_APPLICABILITY.includes(code);
}

function parseExplodedTreeLevel(tree: ExplodedTree, parentIds: string[]): ExplodedTree | undefined {
  if (parentIds.length === 0) return tree;
  const next = head(tree.filter(({ nodeId }) => nodeId === parentIds[0]));
  return next && next.items ? parseExplodedTreeLevel(next.items, parentIds.slice(1)) : undefined;
}

function getMarketingReferenceWrapperMapForVehicle(vehicle: VehicleItemState): Map<string, MarketingReferencesWrapper> {
  return vehicle.nodeIdToMarketingReferences
    ? vehicle.nodeIdToMarketingReferences
    : new Map<string, MarketingReferencesWrapper>();
}

function getIAMReferenceMapForVehicle(vehicle: VehicleItemState): Map<string, IAMReferenceNodeWrapper> {
  return vehicle.nodeIdToIAMReferences ? vehicle.nodeIdToIAMReferences : new Map<string, IAMReferenceNodeWrapper>();
}

function getIAMLaborTimesWrapperMapForVehicle(vehicle: VehicleItemState): Map<string, IAMLaborTimesWrapper | NO_DATA> {
  return vehicle.nodeIdToIAMLaborTimes
    ? vehicle.nodeIdToIAMLaborTimes
    : new Map<string, IAMLaborTimesWrapper | NO_DATA>();
}

function getTechnicalCriteriaGroupsForVehicle(
  vehicle: VehicleItemState,
): Map<TechnicalCriteriaType, TechnicalInfoLocal[]> {
  const technicalCriteria = getData(vehicle.technicalCriteria);
  return technicalCriteria?.groups ? technicalCriteria.groups : new Map<TechnicalCriteriaType, TechnicalInfoLocal[]>();
}

export function getTechnicalDataForVehicle(
  vehicle: VehicleItemState,
): Map<string, VehicleTechnicalDataLocal | NO_DATA> {
  return vehicle.technicalData ? vehicle.technicalData : new Map<string, VehicleTechnicalDataLocal | NO_DATA>();
}

export function getTechnicalDataDetail(
  technicalData: VehicleTechnicalDataLocal | undefined,
): Map<string, TechnicalDataDetailLocal> {
  return technicalData?.technicalDataDetail
    ? technicalData.technicalDataDetail
    : new Map<string, TechnicalDataDetailLocal>();
}

export function getExplodedTreeItem(
  tree: ExplodedTree | undefined,
  parentIds: Array<string>,
): VehiclePartCategoryTreeItem | undefined {
  if (!tree) {
    return undefined;
  }
  const next = head(tree.filter(({ nodeId }) => nodeId === parentIds[0]));
  if (!next) {
    return undefined;
  }
  return parentIds.length === 1 ? next : getExplodedTreeItem(next.items, parentIds.slice(1));
}

export function getExplodedIAMTreeItem(
  tree: VehicleIAMPartCategoryTreeItem[] | undefined,
  parentIds: Array<string>,
): VehicleIAMPartCategoryTreeItem | undefined {
  if (!hasData(tree)) {
    return undefined;
  }
  const next = head(tree.filter(({ nodeId }) => nodeId === parentIds[0]));
  if (!next) {
    return undefined;
  }
  return parentIds.length === 1 ? next : getExplodedIAMTreeItem(next.items, parentIds.slice(1));
}

const getVehicleItemState = (query: string | undefined, vehicles: Map<string, VehicleItemState | undefined>) => {
  if (query === undefined) {
    return undefined;
  }
  return vehicles.get(query);
};

const getVehicleExplodedTree = (query: string | undefined, vehicles: Map<string, VehicleItemState | undefined>) => {
  if (query === undefined) {
    return undefined;
  }
  return vehicles.get(query)?.explodedTree;
};

const getVehicleExplodedIAMTree = (query: string | undefined, vehicles: Map<string, VehicleItemState | undefined>) => {
  if (query === undefined) {
    return undefined;
  }
  return vehicles.get(query)?.explodedIAMTree;
};

const getVehicleExplodedOEMTree = (query: string | undefined, vehicles: Map<string, VehicleItemState | undefined>) => {
  if (query === undefined) {
    return undefined;
  }
  return vehicles.get(query)?.explodedOEMTree;
};

const getUpdatedVehicleBrandLocalArray = (
  brands: VehicleBrandLocal[],
  brandCode: string,
  modelCode: string,
  modelTypeCode: string,
  engines: VehicleEngine[] | NO_DATA,
) => {
  const brand = brands.find((b) => b.code === brandCode);
  const model = brand?.models.find((mdl) => mdl.code === modelCode);
  const modelType = model?.types.find((mdlType) => mdlType.code === modelTypeCode);
  if (brand && model && modelType) {
    modelType.engines = engines;
    const modelTypeIndex = model.types.findIndex((type) => type.code === modelTypeCode);
    model.types[modelTypeIndex] = modelType;
    const modelIndex = brand?.models.findIndex((mdl) => mdl.code === modelCode);
    brand.models[modelIndex] = model;
    const brandIndex = brands.findIndex((b) => b.code === brandCode);
    brands[brandIndex] = brand;
  }
  return brands;
};

const getUpdatedIAMBrandVersions = (
  brands: IAMVehicleBrandLocal[] | NO_DATA,
  modelCode: string,
  groups: IAMVehicleVersionGroup[] | NO_DATA,
) => {
  if (hasData(brands)) {
    // refactor this please
    const brand = brands.find(
      (brand) => hasData(brand.models) && brand.models.find((model) => model.code == modelCode),
    );

    if (brand) {
      brand.groups = groups;
    }
  }
  return brands;
};

const getPlateFromVehicleMap = (
  vehicles: Map<string, VehicleItemState | undefined>,
  vehicleKey: string,
  plateId: string,
) => {
  return vehicles.get(vehicleKey)?.plates?.get(plateId);
};

const getNodeIdFromVehicleMap = (
  vehicles: Map<string, VehicleItemState | undefined>,
  vehicleKey: string,
  nodeId: string,
) => {
  return vehicles.get(vehicleKey)?.nodeIdToIAMReferences?.get(nodeId);
};

export function plateHasDescription(descriptions: NO_DATA | PlateDetail[]): boolean {
  return Array.isArray(descriptions) && descriptions.length > 0;
}

const initialFulltextSearch: FulltextSearch = {
  fulltextAutocomplete: undefined,
  lastAutocompleteKey: undefined,
  fulltextSearchResult: undefined,
};

function getIAMRepairMethodsWrapperMapForVehicle(vehicle: VehicleItemState): Map<string, IAMRepairMethod[] | NO_DATA> {
  return vehicle.nodeIdToIAMRepairMethods
    ? vehicle.nodeIdToIAMRepairMethods
    : new Map<string, IAMRepairMethod[] | NO_DATA>();
}

// Init states
const initialState: CatalogState = {
  vehicles: new Map<string, VehicleItemState | undefined>(),
  requestedItems: new Map<string, string>(),
  vehicleBrands: undefined,
  iam_vehicleBrands: undefined,
  universalProducts: {
    tree: undefined,
    items: new Map<string, UniversalProduct | NO_DATA>(),
    selection: undefined,
    filter: {
      textFilters: new Map<string, string[]>(),
      rangeFilters: new Map<string, FiltersRange>(),
    },
  },
  iamFilters: {
    oemFilters: {
      textFilters: new Map<string, string[]>(),
      rangeFilters: new Map<string, FiltersRange>(),
    },
    otherBrandFilters: {
      textFilters: new Map<string, string[]>(),
      rangeFilters: new Map<string, FiltersRange>(),
    },
    categories: [],
  },
  activeQuickAccess: {
    activeCategory: undefined,
    activeSubCategory: undefined,
  },
  multipleVehiclesFound: undefined,
  foundVehiclesAreFromDifferentCountries: undefined,
  searchStatus: undefined,
};

// Saga actions
export const fetchSearchVehicleRequestSaga = createAction<{
  value: string;
  queryType: SearchVehicleMethod;
  countryCode: string;
  includeLatestEstimate: boolean;
  catalogSource?: CatalogSource;
  versionCode?: string | undefined;
}>(SEARCH_VEHICLE);
export const fetchSearchVehicleResponseSaga = createAction(SEARCH_VEHICLE_RESPONSE);
export const fetchExplodedTreeResponseSaga = createAction(EXPLODED_TREE_RESPONSE);
export const fetchExplodedIAMTreeResponseSaga = createAction(GET_EXPLODED_TREE_IAM_RESPONSE);

export const fetchLevels3DetailsRequestSaga = createAction<{
  level3Details: Level3DetailRQ[];
  plateIds: string[];
  vehicleKey: string;
}>(GET_LEVELS3_DETAIL_REQUEST);
export const fetchPlateDetailDataResponseSaga = createAction(GET_PLATE_DETAIL_RESPONSE);
export const fetchLevels3DetailsResponseSaga = createAction(GET_LEVEL3_DETAILS_RESPONSE);
export const fetchPlateReferencesRequestSaga = createAction<{
  plateId: string;
  vehicleKey: string;
}>(GET_PLATE_REFERENCES_REQUEST);
export const fetchPlateReferencesResponseSaga = createAction(GET_PLATE_REFERENCES_RESPONSE);
export const fetchMarketingReferencesRequestSaga = createAction<{
  nodeId: string;
  vehicleKey: string;
}>(GET_MARKETING_REFERENCES_REQUEST);
export const fetchMarketingReferencesResponseSaga = createAction(GET_MARKETING_REFERENCES_RESPONSE);
export const fetchSwitchVehicleRequestSaga = createAction<{
  requestId: string;
  vehicleDetail: VehicleDetail;
}>(SWITCH_VEHICLE_REQUEST);
export const fetchGetVehicleCategoriesRequestSaga = createAction<{
  vehicleKey: string | undefined;
}>(GET_VEHICLE_CATEGORIES_REQUEST);
export const fetchVehicleCategoryImagesRequestSaga = createAction<{
  categories: { nodeId: string; imageKey?: string }[];
}>(GET_VEHICLE_CATEGORY_IMAGES_REQUEST);
export const fetchOtsInformationRequestSaga = createAction<{ vin: string }>(GET_OTS_INFORMATION_REQUEST);
export const fetchOtsInformationResponseSaga = createAction(GET_OTS_INFORMATION_RESPONSE);
// BMM DH
export const fetchSearchBMMVehicleBrandsSaga = createAction(GET_VEHICLE_BRANDS_REQUEST);
export const fetchSearchBMMVehicleBrandsResponseSaga = createAction(GET_VEHICLE_BRANDS_RESPONSE);
export const fetchSearchBMMVehicleEnginesSaga = createAction<{
  brandCode: string;
  modelCode: string;
  modelTypeCode: string;
}>(GET_VEHICLE_ENGINES_REQUEST);
export const fetchSearchBMMVehicleEnginesResponseSaga = createAction(GET_VEHICLE_ENGINES_RESPONSE);
export const fetchSearchVehicleByBMMRequestSaga = createAction<{
  brandCode: string;
  engineCode: string;
  modelTypeCode: string;
  modelCode: string;
  gearboxCode: string;
  requestId: string;
  iamVersionCode: string;
  includeLatestEstimate: boolean;
  catalogSource?: CatalogSource;
}>(SEARCH_VEHICLE_BMM);

// BMM IAM
export const searchIAMBMMVehicleSaga = createAction<{
  brandCode: string;
  modelCode: string;
  versionCode: string;
  requestId: string;
}>(SEARCH_VEHICLE_IAM_BMM);
export const fetchSearchIAMBMMVehicleBrandsResponseSaga = createAction(GET_IAM_VEHICLE_BRANDS_RESPONSE);
export const fetchSearchIAMBMMVehicleModelsSaga = createAction<{
  brandCode: string;
}>(GET_IAM_VEHICLE_MODELS_REQUEST);
export const fetchSearchIAMBMMVehicleModelsResponseSaga = createAction(GET_IAM_VEHICLE_MODELS_RESPONSE);
export const fetchSearchIAMBMMVehicleVersionsSaga = createAction<{
  modelCode: string;
}>(GET_IAM_VEHICLE_VERSIONS_REQUEST);
export const fetchSearchIAMBMMVehicleVersionsResponseSaga = createAction(GET_IAM_VEHICLE_VERSIONS_RESPONSE);

export const fetchSingleReferenceRequestSaga = createAction<{
  reference: string;
  vehicleKey?: string;
}>(REQUEST_SINGLE_REFERENCE);
export const fetchSingleReferenceResponseSaga = createAction(GET_REFERENCE_DETAILS_RESPONSE);
export const fetchReferenceTextSearchResponseSaga = createAction(GET_REFERENCE_TEXT_SEARCH_RESPONSE);
export const fetchIAMLaborTimesRequestSaga = createAction<{
  vehicleKey: string;
  versionCode: string;
  nodeId: string;
}>(GET_IAM_LABOR_TIMES_REQUEST);
export const fetchIAMLaborTimesResponseSaga = createAction(GET_IAM_LABOR_TIMES_RESPONSE);
export const fetchIAMTechnicalDataTreeRequestSaga = createAction(GET_IAM_TECHNICAL_DATA_TREE_REQUEST);
export const fetchIAMTechnicalDataDetailsRequestSaga = createAction<{
  categoryIds: string[];
}>(GET_IAM_TECHNICAL_DATA_DETAILS_REQUEST);
export const fetchIAMTechnicalDataTreeResponseSaga = createAction(GET_IAM_TECHNICAL_DATA_TREE_RESPONSE);
export const fetchIAMTechnicalDataDetailsResponseSaga = createAction(GET_IAM_TECHNICAL_DATA_DETAILS_RESPONSE);
export const fetchMaintenancePlanRequestSaga = createAction(GET_MAINTENANCE_PLAN_REQUEST);
export const fetchMaintenancePlanResponseSaga = createAction(GET_MAINTENANCE_PLAN_RESPONSE);
export const fetchFulltextAutocompleteRequestSaga = createAction<{
  vehicleKey: string;
  searchText: string;
}>(GET_FULLTEXT_AUTOCOMPLETE_REQUEST);
export const fetchFulltextAutocompleteResponseSaga = createAction(GET_FULL_TEXT_AUTOCOMPLETE_RESPONSE);
export const fetchTextSearchRequestSaga = createAction<{
  vehicleKey: string | undefined;
  searchId: string;
  searchText: string;
  queryType: 'REFERENCE';
  catalogSource: CatalogSource | undefined;
}>(GET_TEXT_SEARCH_REQUEST);
export const fetchFullTextSearchResponseSaga = createAction(GET_TEXT_SEARCH_RESPONSE);
export const fetchIAMFullTextSearchResponseSaga = createAction(GET_IAM_TEXT_SEARCH_RESPONSE);
export const fetchIAMEquivalentReferencesResponseSaga = createAction(GET_IAM_EQUIVALENT_REFERENCES_RESPONSE);
export const fetchUniversalProductsTreeRequestSaga = createAction(GET_UNIVERSAL_PRODUCTS_TREE_REQUEST);
export const fetchUniversalProductsTreeResponseSaga = createAction(GET_UNIVERSAL_PRODUCTS_TREE_RESPONSE);
export const fetchUniversalProductsResponseSaga = createAction(GET_UNIVERSAL_PRODUCTS_RESPONSE);
export const fetchUniversalProductsRequestSaga = createAction<string[]>(GET_UNIVERSAL_PRODUCTS_REQUEST);
export const fetchVehicleTechnicalCriteriaRequestSaga = createAction(GET_VEHICLE_TECHNICAL_CRITERIA_REQUEST);
export const fetchVehicleTechnicalCriteriaResponseSaga = createAction(GET_VEHICLE_TECHNICAL_CRITERIA_RESPONSE);
export const fetchIAMVehicleTechnicalCriteriaRequestSaga = createAction(GET_IAM_VEHICLE_TECHNICAL_CRITERIA_REQUEST);
export const fetchIAMVehicleTechnicalCriteriaResponseSaga = createAction(GET_IAM_VEHICLE_TECHNICAL_CRITERIA_RESPONSE);
export const fetchIAMReferencesRequestSaga = createAction<{
  versionCode: string;
  vehicleKey: string;
  nodeId: string;
}>(GET_IAM_REFERENCES_REQUEST);
export const fetchIAMReferencesResponseSaga = createAction(GET_IAM_REFERENCES_RESPONSE);
export const fetchDHTireRequestSaga = createAction<VehicleDetail>(GET_DATAHUB_TIRE_REQUEST);
export const addReferenceFromCatalogToCart = createAction<{
  referenceNumber: string;
  addOtherReference?: boolean;
  //added from dh plate
  plateId?: string;
  index?: number;
}>(ADD_REFERENCE_FROM_CATALOG_TO_CART);
export const addTireFromCatalogToCart = createAction<{ tire: TireItem }>(ADD_TIRE_FROM_CATALOG_TO_CART);
export const removeCartReferenceInCatalog = createAction<{
  referenceNumber: string;
  vehicleKey: string | undefined;
  basketReferenceType: 'VEHICLE' | 'OTHER';
  referenceType: ReferenceType;
  origin: ORIGIN | undefined;
  supplierCode: string | undefined;
  referenceSource: ReferenceSource;
}>(REMOVE_CART_REFERENCE_IN_CATALOG);

export const fetchIAMRepairMethodsRequestSaga = createAction<{
  vehicleKey: string;
  versionCode: string;
  nodeId: string;
}>(GET_IAM_REPAIR_METHODS_REQUEST);
export const fetchIAMRepairMethodsResponseSaga = createAction(GET_IAM_REPAIR_METHODS_RESPONSE);

export const fetchIAMRepairMethodsTreeRequestSaga = createAction<{
  vehicleKey: string;
  versionCode: string;
}>(GET_IAM_REPAIR_METHODS_TREE_REQUEST);
export const fetchIAMRepairMethodsTreeResponseSaga = createAction(GET_IAM_REPAIR_METHODS_TREE_RESPONSE);

// Slice
const slice = createSlice({
  name: CATALOG_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setSearchVehicleLoading: (
      state,
      {
        payload,
      }: PayloadAction<{
        requestId: string;
        query: string;
      }>,
    ) => {
      state.requestedItems.set(payload.requestId, payload.query);
      state.lastSearchedVehicleKey = undefined;
    },
    setVehicleDetail: (
      state,
      {
        payload,
      }: PayloadAction<{
        requestId: string;
        vehicle: VehicleDetail | undefined;
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { requestId, vehicle, status } = payload;
      const query = state.requestedItems.get(requestId);
      const vehicleKey = vehicle?.vehicleKey;
      const key = status === FOUND ? vehicleKey : query;

      if (key) {
        state.vehicles.set(key, {
          vehicleDetail: vehicle,
          searchVehicleStatus: status,
          explodedTree: { searchStatus: vehicle?.catalogSource === 'DATAHUB' ? LOADING : undefined },
          explodedIAMTree: { searchStatus: vehicle?.catalogSource === 'IAM' ? LOADING : undefined },
          explodedOEMTree: { searchStatus: vehicle?.catalogSource === 'IAM' ? LOADING : undefined },
        });
      }
      state.requestedItems.delete(requestId);
    },
    resetVehicleSearchStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        requestId: string;
        query: string;
      }>,
    ) => {
      state.requestedItems.delete(payload.requestId);
      const vehicle = state.vehicles.get(payload.query);
      if (!vehicle) {
        return;
      }
      state.vehicles.set(payload.query, {
        ...vehicle,
        searchVehicleStatus: undefined,
      });
    },
    setExplodedTree: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        items: VehiclePartCategoryTreeItem[];
      }>,
    ) => {
      const { vehicleKey, items } = payload;
      const item = state.vehicles.get(vehicleKey);
      state.vehicles.set(vehicleKey, {
        ...item,
        explodedTree: {
          searchStatus: FOUND,
          data: items,
        },
      });
    },
    setOtsType: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        otsType: OTSType;
      }>,
    ) => {
      const { vehicleKey, otsType } = payload;
      if (vehicleKey) {
        const item = state.vehicles.get(vehicleKey);
        if (item) {
          state.vehicles.set(vehicleKey, {
            ...item,
            otsType: {
              data: otsType,
              searchStatus: FOUND,
            },
          });
        }
      }
    },
    setOtsTypeStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string | undefined;
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { vehicleKey } = payload;
      if (vehicleKey) {
        const item = state.vehicles.get(vehicleKey);
        if (item) {
          state.vehicles.set(vehicleKey, {
            ...item,
            otsType: {
              data: item.otsType?.data,
              searchStatus: FOUND,
            },
          });
        }
      }
    },
    resetExplodedTree: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;

      const item = state.vehicles.get(vehicleKey);
      state.vehicles.set(vehicleKey, {
        ...item,
        explodedTree: undefined,
      });
    },
    setExplodedTreeNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        status: NO_DATA;
        provider?: Provider;
      }>,
    ) => {
      const { vehicleKey, status, provider } = payload;

      const item = state.vehicles.get(vehicleKey);
      if (item?.vehicleDetail?.catalogSource === 'IAM') {
        provider === 'RENAULT'
          ? state.vehicles.set(vehicleKey, {
              ...item,
              explodedIAMTree: {
                searchStatus: status,
              },
            })
          : state.vehicles.set(vehicleKey, {
              ...item,
              explodedOEMTree: {
                searchStatus: status,
              },
            });
      } else {
        state.vehicles.set(vehicleKey, {
          ...item,
          explodedTree: { searchStatus: status },
        });
      }
    },
    setExplodedIAMTree: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        items: VehicleIAMPartCategoryTreeItem[];
        provider: Provider;
      }>,
    ) => {
      const { vehicleKey, items, provider } = payload;

      const item = state.vehicles.get(vehicleKey);
      provider === 'RENAULT'
        ? state.vehicles.set(vehicleKey, {
            ...item,
            explodedIAMTree: {
              searchStatus: FOUND,
              data: items,
            },
          })
        : state.vehicles.set(vehicleKey, {
            ...item,
            explodedOEMTree: {
              searchStatus: FOUND,
              data: items,
            },
          });
    },
    setLastSearchedVehicleKey: (state, { payload }: PayloadAction<string | undefined>) => {
      state.lastSearchedVehicleKey = payload;
    },
    setQuickAccessActiveCategory: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeQuickAccess.activeCategory = payload;
    },
    setQuickAccessActiveSubCategory: (state, { payload }: PayloadAction<string | undefined>) => {
      state.activeQuickAccess.activeSubCategory = payload;
    },
    setSearchKey: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchKey: string;
        vehicleKey: string | undefined;
      }>,
    ) => {
      const vehicleKey = payload.vehicleKey;

      if (!vehicleKey) return;

      const vehicleItem = state.vehicles.get(vehicleKey);

      if (!vehicleItem) return;

      const searchKey = payload.searchKey;
      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        searchKey,
      });
    },
    setPlateDetailData: (
      state,
      {
        payload,
      }: PayloadAction<{
        plateId: string;
        thumbnailImageKey: string;
        svgImageKey: string;
        plateDetail: PlateDetail[] | NO_DATA;
        genericParts: string[];
        vehicleKey: string;
      }>,
    ) => {
      const { plateId, thumbnailImageKey, svgImageKey, plateDetail, genericParts, vehicleKey } = payload;

      const vehicleItem = state.vehicles.get(vehicleKey);
      if (!vehicleItem) return;

      const plates = vehicleItem.plates ?? new Map<string, PlateInfo>();
      const curr = plates.get(plateId);
      mergeObjectInMap(plates, plateId, {
        plateId,
        thumbnailImageKey,
        svgImageKey,
        references: curr?.references,
        plateDetail,
        genericParts,
      });

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        plates,
      });
    },
    resetPlateDetailData: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;

      const vehicleItem = state.vehicles.get(vehicleKey);
      if (!vehicleItem) return;

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        plates: undefined,
      });
    },
    setLevels3Details: (
      state,
      {
        payload,
      }: PayloadAction<{
        lv3s: Level3DetailResponse[];
        vehicleKey: string;
      }>,
    ) => {
      const { lv3s, vehicleKey } = payload;
      const vehicleItem = state.vehicles.get(vehicleKey);
      if (!vehicleItem) return;

      const multiPlates = lv3s.reduce((acc, next) => {
        const plateIds = next.multiplates?.map((multiPlate) => {
          return {
            plateIds: multiPlate.plateIds,
          } as MultiPlate;
        });
        return acc.set(next.nodeId, plateIds);
      }, vehicleItem.multiPlates ?? new Map<string, MultiPlate[] | NO_DATA>());

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        multiPlates,
      });
    },
    resetLevels3Details: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;
      const vehicleItem = state.vehicles.get(vehicleKey);
      if (!vehicleItem) return;

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        multiPlates: undefined,
      });
    },
    setLevels3DetailNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        lv3s: Level3DetailRQ[];
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { lv3s, vehicleKey, status } = payload;
      const vehicleItem = state.vehicles.get(vehicleKey);
      if (!vehicleItem) return;

      const multiPlates = lv3s.reduce(
        (acc, next) => acc.set(next?.nodeId, status),
        vehicleItem.multiPlates ?? new Map<string, MultiPlate[] | NO_DATA>(),
      );

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        multiPlates,
      });
    },
    setPlateReferenceNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        plateId: string;
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { plateId, vehicleKey, status } = payload;

      const vehicleItem = state.vehicles.get(vehicleKey);

      if (!vehicleItem) {
        return;
      }

      const plates = vehicleItem.plates ?? new Map<string, PlateInfo>();
      mergeObjectInMap(plates, plateId, { references: status });

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        plates,
      });
    },
    setPlateReferencesData: (
      state,
      {
        payload,
      }: PayloadAction<{
        plateId: string;
        indexToReference: IndexReference[];
        vehicleKey: string;
      }>,
    ) => {
      const { plateId, indexToReference, vehicleKey } = payload;

      const vehicleItem = state.vehicles.get(vehicleKey);
      if (!vehicleItem) return;

      const details = indexToReference.map((detail) => {
        return {
          ...detail,
          alternativeReferenceNumbers: detail.alternativeReferences ?? [],
        };
      });
      const plates = vehicleItem.plates ?? new Map<string, PlateInfo>();
      mergeObjectInMap(plates, plateId, { references: details });

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        plates,
      });
    },
    setMarketingReferencesForVehicleLoading: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        nodeId: string;
      }>,
    ) => {
      const { vehicleKey, nodeId } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (vehicle) {
        const nodeIdToMarketingReferences = getMarketingReferenceWrapperMapForVehicle(vehicle);
        const currWrapper = nodeIdToMarketingReferences.get(nodeId);

        const updatedMarketingRefereceWrapper = {
          status: LOADING,
          referenceNumbers: currWrapper?.referenceNumbers,
          lastId: currWrapper?.lastId,
        } as MarketingReferencesWrapper;

        nodeIdToMarketingReferences.set(nodeId, updatedMarketingRefereceWrapper);
        state.vehicles.set(vehicleKey, {
          ...vehicle,
          nodeIdToMarketingReferences,
        });
      }
    },
    setMarketingReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        nodeId: string;
        incomingReferences: Array<MarketingReference>;
      }>,
    ) => {
      const { vehicleKey, nodeId, incomingReferences } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) {
        return;
      }
      // update vehicle
      const nodeIdToMarketingReferences = getMarketingReferenceWrapperMapForVehicle(vehicle);
      const currWrapper = nodeIdToMarketingReferences.get(nodeId);
      const incomingRefNumbers = incomingReferences.map((ref) => ref.referenceNumber);

      const referenceNumbers =
        currWrapper && currWrapper.referenceNumbers
          ? [...new Set(currWrapper.referenceNumbers.concat(...incomingRefNumbers))]
          : incomingRefNumbers;

      // TODO set last ID
      const updatedMarketingReferenceWrapper = {
        status: FOUND,
        referenceNumbers,
      } as MarketingReferencesWrapper;

      nodeIdToMarketingReferences.set(nodeId, updatedMarketingReferenceWrapper);
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToMarketingReferences,
      });
    },
    resetMarketingReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) {
        return;
      }
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToMarketingReferences: undefined,
      });
    },
    setNodeIAMReferencesStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        nodeId: string;
        vehicleKey: string;
        loadingState: string;
        provider?: string;
      }>,
    ) => {
      const { nodeId, vehicleKey, loadingState, provider } = payload;

      const vehicleItem = state.vehicles.get(vehicleKey);

      if (!vehicleItem) return;

      const nodeIdToIAMReferences = vehicleItem.nodeIdToIAMReferences ?? new Map<string, IAMReferenceNodeWrapper>();

      if (loadingState !== LOADING && loadingState !== NOT_FOUND) return;

      const currentIAMReferenceNodeWrapper = nodeIdToIAMReferences.get(nodeId);

      mergeObjectInMap(nodeIdToIAMReferences, nodeId, {
        otherBrandReferences:
          !provider || provider === 'RENAULT' ? loadingState : currentIAMReferenceNodeWrapper?.otherBrandReferences,
        oemReferences:
          !provider || provider === 'TECDOC' ? loadingState : currentIAMReferenceNodeWrapper?.oemReferences,
      });

      state.vehicles.set(vehicleKey, {
        ...vehicleItem,
        nodeIdToIAMReferences,
      });
    },
    setNodeIAMReferences: (
      state,
      {
        payload,
      }: PayloadAction<{
        versionCode: string;
        vehicleKey: string;
        nodeId: string;
        incomingReferences: IAMReference[];
        textFilters?: TextFacet[];
        rangeFilters?: RangeFacet[];
      }>,
    ) => {
      const { vehicleKey, nodeId, incomingReferences, textFilters, rangeFilters } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) {
        return;
      }
      const provider = incomingReferences[0].provider;
      const nodeIdToIAMReferences = getIAMReferenceMapForVehicle(vehicle);
      const currentIAMReferenceNodeWrapper = nodeIdToIAMReferences.get(nodeId);
      const currentIAMReferencesWrapper =
        provider === 'RENAULT'
          ? currentIAMReferenceNodeWrapper?.otherBrandReferences
          : currentIAMReferenceNodeWrapper?.oemReferences;
      const incomingRefNumbers = incomingReferences.map((ref) => ref.referenceNumber);

      const referenceNumbers = hasData(currentIAMReferencesWrapper)
        ? [...new Set(currentIAMReferencesWrapper.referenceNumbers.concat(...incomingRefNumbers))]
        : incomingRefNumbers;

      const textFiltersLocal = textFilters?.map((t) => {
        return {
          ...t,
          active: true,
        } as TextFacetLocal;
      });

      const rangeFiltersLocal = rangeFilters?.map((t) => {
        return {
          ...t,
          active: true,
        } as RangeFacetLocal;
      });
      const updatedIAMReferences = {
        referenceNumbers,
        textFilters: textFiltersLocal,
        rangeFilters: rangeFiltersLocal,
      } as IAMReferences;

      mergeObjectInMap(nodeIdToIAMReferences, nodeId, {
        otherBrandReferences:
          provider === 'RENAULT' ? updatedIAMReferences : currentIAMReferenceNodeWrapper?.otherBrandReferences,
        oemReferences: provider === 'TECDOC' ? updatedIAMReferences : currentIAMReferenceNodeWrapper?.oemReferences,
      });

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToIAMReferences,
      });
    },
    setEmptyFulltextAutocomplete: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
      }>,
    ) => {
      const { vehicleKey } = payload;
      const vehicle = state.vehicles.get(vehicleKey);

      if (vehicle) {
        const fulltextSearch = vehicle.fulltextSearch ?? initialFulltextSearch;

        state.vehicles.set(vehicleKey, {
          ...vehicle,
          fulltextSearch: {
            ...fulltextSearch,
            fulltextAutocomplete: undefined,
            lastAutocompleteKey: undefined,
          },
        });
      }
    },
    setFulltextAutocomplete: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        searchText: string;
        fullTexts: FullText[];
      }>,
    ) => {
      const { vehicleKey, searchText, fullTexts } = payload;
      const vehicle = state.vehicles.get(vehicleKey);

      if (vehicle) {
        const fulltextSearch = vehicle.fulltextSearch ?? initialFulltextSearch;

        if (searchText !== fulltextSearch?.lastAutocompleteKey) {
          return;
        }

        state.vehicles.set(vehicleKey, {
          ...vehicle,
          fulltextSearch: {
            ...fulltextSearch,
            fulltextAutocomplete: fullTexts?.map((fulltext) => ({
              ...fulltext,
              label: fulltext.label.toLowerCase(),
            })),
          },
        });
      }
    },
    setLastAutocompleteKey: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        searchText: string;
      }>,
    ) => {
      const { vehicleKey, searchText } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (vehicle) {
        const fulltextSearch = vehicle.fulltextSearch ?? initialFulltextSearch;
        state.vehicles.set(vehicleKey, {
          ...vehicle,
          fulltextSearch: {
            ...fulltextSearch,
            lastAutocompleteKey: searchText,
          },
        });
      }
    },
    setFulltextAutocompleteNoData: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        searchText: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, searchText, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (vehicle) {
        const fulltextSearch = vehicle.fulltextSearch ?? initialFulltextSearch;

        if (searchText !== fulltextSearch?.lastAutocompleteKey) {
          return;
        }
        state.vehicles.set(vehicleKey, {
          ...vehicle,
          fulltextSearch: {
            ...fulltextSearch,
            fulltextAutocomplete: status,
          },
        });
      }
    },
    setUniversalProductsTextFilter: (state, { payload }) => {
      const { id, item } = payload;
      const idItems = state.universalProducts.filter.textFilters.get(id) ?? [];
      const newItems = idItems.includes(item) ? idItems.filter((x) => x !== item) : [...idItems, item];
      if (newItems.length > 0) {
        state.universalProducts.filter.textFilters.set(id, newItems);
      } else {
        state.universalProducts.filter.textFilters.delete(id);
      }
    },
    setUniversalProductsRangeFilter: (state, { payload }) => {
      const { id, range } = payload;
      if (range) {
        state.universalProducts.filter.rangeFilters.set(id, range);
      } else {
        state.universalProducts.filter.rangeFilters.delete(id);
      }
    },
    resetUniversalProductsFilters: (state) => {
      state.universalProducts.filter.textFilters.clear();
      state.universalProducts.filter.rangeFilters.clear();
    },
    setFulltextSearchResult: (
      state,
      {
        payload,
      }: PayloadAction<{
        refNumbers: string[];
      }>,
    ) => {
      const { refNumbers } = payload;

      if (!state.searchStatus) return;

      state.searchStatus.fullTextSearch = {
        ...state.searchStatus.fullTextSearch,
        fulltextSearchResult: { refNumbers },
      };
    },
    setIAMFulltextMainSearchResult: (
      state,
      {
        payload,
      }: PayloadAction<{
        fullTexts: IAMFulltextSearchResult[];
      }>,
    ) => {
      const { fullTexts } = payload;

      if (!state.searchStatus) return;

      state.searchStatus.iamFullTextSearch = fullTexts;
    },
    setIAMFulltextEquivalentSearchResult: (
      state,
      {
        payload,
      }: PayloadAction<{
        mainToEquivalentReferences: IAMEquivalentReferences[];
      }>,
    ) => {
      const { mainToEquivalentReferences } = payload;

      if (!state.searchStatus || !state.searchStatus.iamFullTextSearch) return;

      const groups = state.searchStatus.iamFullTextSearch;

      mainToEquivalentReferences.forEach((mainEquivalentPair) => {
        const groupToUpdate = groups.find((group) => {
          if (!group.mainReferences) return false;
          return group.mainReferences[0] === mainEquivalentPair.mainReference;
        });
        if (!groupToUpdate) return;
        groupToUpdate.equivalentReferences = mainEquivalentPair.equivalentReferences.map((ref) => ref.referenceNumber);
        groupToUpdate.additionalEquivalencesLoading = false;
      });
    },
    setIAMFulltextEquivalentNoData: (state, { payload }: PayloadAction<NO_DATA>) => {
      if (!state.searchStatus || !state.searchStatus.iamFullTextSearch) {
        return;
      }

      state.searchStatus.iamFullTextSearch.forEach((group) => (group.equivalentReferences = payload));
    },
    setSearchStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        searchId: string;
        searchText: string;
        vehicleKey: string | undefined;
        status: SEARCH_STATUS;
        source: CatalogSource | undefined;
        type: SearchType | undefined;
      }>,
    ) => {
      const { searchId, searchText, vehicleKey, status, source, type } = payload;

      if (!state.searchStatus) {
        state.searchStatus = {
          searchId,
          searchText,
          vehicleKey,
          status,
          source,
          type,
          fullTextSearch: initialFulltextSearch,
        };
        return;
      }
      state.searchStatus.searchId = searchId;
      state.searchStatus.searchText = searchText;
      state.searchStatus.vehicleKey = vehicleKey;
      state.searchStatus.status = status;
      state.searchStatus.source = source;
      state.searchStatus.type = type;
    },
    setVehicleBrands: (state, { payload }: PayloadAction<VehicleBrand[]>) => {
      state.vehicleBrands = payload.map((item) => ({ ...item, source: 'DH' })) as VehicleBrandLocal[];
    },
    setVehicleBrandsNoDataStatus: (state, { payload }: PayloadAction<NO_DATA>) => {
      state.vehicleBrands = payload;
    },
    setIAMVehicleBrands: (state, { payload }: PayloadAction<IAMVehicleBrand[]>) => {
      state.iam_vehicleBrands = payload.map((item) => ({ ...item, source: 'IAM' })) as IAMVehicleBrandLocal[];
    },
    setIAMVehicleBrandsNoDataStatus: (state, { payload }: PayloadAction<NO_DATA>) => {
      state.iam_vehicleBrands = payload;
    },
    setIAMVehicleModels: (
      state,
      {
        payload,
      }: PayloadAction<{
        models: IAMVehicleModel[];
        brandCode: string;
      }>,
    ) => {
      const { models, brandCode } = payload;
      if (hasData(state.iam_vehicleBrands)) {
        const brand = state.iam_vehicleBrands.find((brand) => brand.code === brandCode);
        if (brand) {
          brand.models = models;
        }
      }
    },
    setIAMVehicleModelsNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        brandCode: string;
        status: NO_DATA;
      }>,
    ) => {
      const { brandCode, status } = payload;
      if (hasData(state.iam_vehicleBrands)) {
        const brand = state.iam_vehicleBrands.find((brand) => brand.code === brandCode);
        if (brand) {
          brand.models = status;
        }
      }
    },
    setIAMVehicleVersions: (
      state,
      {
        payload,
      }: PayloadAction<{
        groups: IAMVehicleVersionGroup[];
        modelCode: string;
      }>,
    ) => {
      const { modelCode, groups } = payload;
      if (hasData(state.iam_vehicleBrands)) {
        state.iam_vehicleBrands = getUpdatedIAMBrandVersions(state.iam_vehicleBrands, modelCode, groups);
      }
    },
    setIAMVehicleVersionsNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        modelCode: string;
        status: NO_DATA;
      }>,
    ) => {
      const { modelCode, status } = payload;
      if (hasData(state.iam_vehicleBrands)) {
        state.iam_vehicleBrands = getUpdatedIAMBrandVersions(state.iam_vehicleBrands, modelCode, status);
      }
    },
    clearIAMVehicleVersions: (
      state,
      {
        payload,
      }: PayloadAction<{
        brandCode: string;
      }>,
    ) => {
      const { brandCode } = payload;
      if (hasData(state.iam_vehicleBrands)) {
        const brand = state.iam_vehicleBrands.find((brand) => brand.code == brandCode);
        if (brand) {
          brand.groups = undefined;
        }
      }
    },
    setVehicleEnginesNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        brandCode: string;
        modelCode: string;
        modelTypeCode: string;
        status: NO_DATA;
      }>,
    ) => {
      const { brandCode, modelCode, modelTypeCode, status } = payload;
      if (hasData(state.vehicleBrands)) {
        state.vehicleBrands = getUpdatedVehicleBrandLocalArray(
          state.vehicleBrands,
          brandCode,
          modelCode,
          modelTypeCode,
          status,
        );
      }
    },
    setVehicleEngines: (
      state,
      {
        payload,
      }: PayloadAction<{
        engines: VehicleEngine[];
        brandCode: string;
        modelCode: string;
        modelTypeCode: string;
      }>,
    ) => {
      const { engines, brandCode, modelCode, modelTypeCode } = payload;
      if (hasData(state.vehicleBrands)) {
        state.vehicleBrands = getUpdatedVehicleBrandLocalArray(
          state.vehicleBrands,
          brandCode,
          modelCode,
          modelTypeCode,
          engines,
        );
      }
    },
    setIAMLaborTimeNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        nodeId: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, nodeId, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;
      const nodeIdToIAMLaborTimes = getIAMLaborTimesWrapperMapForVehicle(vehicle);
      nodeIdToIAMLaborTimes.set(nodeId, status);
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToIAMLaborTimes,
      });
    },
    setIAMLaborTimes: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        nodeId: string;
        laborTimes: IAMLaborTime[];
        operations?: LaborTimeFilterOption[];
        elements?: LaborTimeFilterOption[];
      }>,
    ) => {
      const { vehicleKey, nodeId, laborTimes, operations, elements } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const emptySubmenuLabel = {
        id: '',
        designation: '',
        time: 0,
        technicity: 'NORMAL',
        subtable: [],
        isIncluded: false,
        isSubLaborTime: true,
      };

      function pushToSubmenu(submenu: IAMLaborTime[], label: IAMLaborSubtableLabel, labTimes?: IAMLaborTime[]): void {
        if (labTimes === undefined || labTimes.length === 0) return;
        submenu.push({
          ...emptySubmenuLabel,
          subtableLabel: label,
          nodeId: uuidv4(),
        } as IAMLaborTimeLocal);
        labTimes.forEach((subLabor) => {
          const newSubLabor = subLabor as IAMLaborTimeLocal;
          newSubLabor.nodeId = uuidv4();
          newSubLabor.subtable = [];
          newSubLabor.isIncluded = label === 'included';
          newSubLabor.isSubLaborTime = true;
          submenu.push(newSubLabor);
        });
      }

      const nodeIdToIAMLaborTimes = getIAMLaborTimesWrapperMapForVehicle(vehicle);
      const laborTimeLocals = laborTimes as IAMLaborTimeLocal[];
      laborTimeLocals.forEach((labor) => {
        labor.nodeId = uuidv4();
        labor.subtable = [];
        labor.isIncluded = false;
        labor.isSubLaborTime = false;
        if (labor.includedLaborTimes) {
          pushToSubmenu(labor.subtable, 'included', labor.includedLaborTimes);
        }
        if (labor.optionalLaborTimes) {
          pushToSubmenu(labor.subtable, 'optional', labor.optionalLaborTimes);
        }
      });

      nodeIdToIAMLaborTimes.set(nodeId, {
        elements: elements ?? [],
        operations: operations ?? [],
        laborTimes: laborTimeLocals,
        quickFilters: [],
      });
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToIAMLaborTimes,
      });
    },
    setIAMRepairMethods: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        nodeId: string;
        repairMethods: IAMRepairMethod[];
      }>,
    ) => {
      const { vehicleKey, nodeId, repairMethods } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;
      const nodeIdToIAMRepairMethods = getIAMRepairMethodsWrapperMapForVehicle(vehicle);
      nodeIdToIAMRepairMethods.set(nodeId, repairMethods);
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToIAMRepairMethods,
      });
    },
    setIAMRepairMethodsTree: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        repairMethods: IAMTreeRepairMethod[];
      }>,
    ) => {
      const { vehicleKey, repairMethods } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        repairMethods: {
          data: repairMethods,
          searchStatus: FOUND,
        },
      });
    },
    setIAMRepairMethodsTreeNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        repairMethods: {
          searchStatus: status,
        },
      });
    },
    setIAMRepairMethodsNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        nodeId: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, nodeId, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;
      const nodeIdToIAMRepairMethods = getIAMRepairMethodsWrapperMapForVehicle(vehicle);
      nodeIdToIAMRepairMethods.set(nodeId, status);
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        nodeIdToIAMRepairMethods,
      });
    },
    setIamTextFilter: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        item: string;
        filterType: IamCatalogBrandsType;
      }>,
    ) => {
      const { id, item, filterType } = payload;
      const idItems =
        filterType === OEM_BRAND_KEY
          ? state.iamFilters.oemFilters.textFilters.get(id) ?? []
          : state.iamFilters.otherBrandFilters.textFilters.get(id) ?? [];
      const newItems = idItems.includes(item) ? idItems.filter((x) => x !== item) : [...idItems, item];
      if (newItems.length > 0) {
        if (filterType === OEM_BRAND_KEY) {
          state.iamFilters.oemFilters.textFilters.set(id, newItems);
          return;
        }
        state.iamFilters.otherBrandFilters.textFilters.set(id, newItems);
      } else {
        if (filterType === OEM_BRAND_KEY) {
          state.iamFilters.oemFilters.textFilters.delete(id);
          return;
        }
        state.iamFilters.otherBrandFilters.textFilters.delete(id);
      }
    },
    setIamRangeFilter: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        range?: FiltersRange;
        filterType: IamCatalogBrandsType;
      }>,
    ) => {
      const { id, range, filterType } = payload;
      if (range) {
        if (filterType === OEM_BRAND_KEY) {
          state.iamFilters.oemFilters.rangeFilters.set(id, range);
          return;
        }
        state.iamFilters.otherBrandFilters.rangeFilters.set(id, range);
      } else {
        if (filterType === OEM_BRAND_KEY) {
          state.iamFilters.oemFilters.rangeFilters.delete(id);
          return;
        }
        state.iamFilters.otherBrandFilters.rangeFilters.delete(id);
      }
    },
    setIamCategoriesFilter: (state, { payload }) => {
      const newCategories = state.iamFilters.categories.includes(payload)
        ? state.iamFilters.categories.filter((x) => x !== payload)
        : [...state.iamFilters.categories, payload];
      state.iamFilters = {
        ...state.iamFilters,
        categories: newCategories,
      };
    },
    resetIamTextFilter: (
      state,
      { payload }: PayloadAction<{ filterType: IamCatalogBrandsType; filterOption: string }>,
    ) => {
      const { filterType, filterOption } = payload;
      if (filterType === OEM_BRAND_KEY) {
        state.iamFilters.oemFilters.textFilters.delete(filterOption);
        return;
      }
      state.iamFilters.otherBrandFilters.textFilters.delete(filterOption);
    },
    resetIamTextFilters: (state, { payload }: PayloadAction<{ filterType: IamCatalogBrandsType }>) => {
      const { filterType } = payload;
      if (filterType === OEM_BRAND_KEY) {
        state.iamFilters.oemFilters.textFilters.clear();
        return;
      }
      state.iamFilters.otherBrandFilters.textFilters.clear();
    },
    resetIamRangeFilters: (state, { payload }: PayloadAction<{ filterType: IamCatalogBrandsType }>) => {
      const { filterType } = payload;
      if (filterType === OEM_BRAND_KEY) {
        state.iamFilters.oemFilters.rangeFilters.clear();
        return;
      }
      state.iamFilters.otherBrandFilters.rangeFilters.clear();
    },
    resetIamCategoriesFilter: (state) => {
      state.iamFilters = { ...state.iamFilters, categories: [] };
    },
    setUniversalProductsTree: (state, { payload }: PayloadAction<UniversalProductsCategory[] | NO_DATA>) => {
      state.universalProducts.tree = payload;
    },
    setUniversalProducts: (state, { payload }: PayloadAction<UniversalProduct[]>) => {
      const newProducts = payload.reduce(
        (acc, next) => acc.set(next?.referenceNumber, next),
        state.universalProducts.items,
      );
      state.universalProducts.items = mergeMaps(state.universalProducts.items, newProducts);
    },
    setUniversalProductsNoDataState: (
      state,
      {
        payload,
      }: PayloadAction<{
        referenceNumbers: string[];
        status: NO_DATA;
      }>,
    ) => {
      const { referenceNumbers, status } = payload;
      referenceNumbers.forEach((key: string) => {
        state.universalProducts.items.set(key, status);
      });
    },
    setUniversalProductSelection: (state, { payload }: PayloadAction<UniversalProductsSelection>) => {
      state.universalProducts.selection = payload;
    },
    setVehicleTechnicalCriteria: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        vehicleTechnicalCriteria: VehicleTechnicalCriteria;
      }>,
    ) => {
      const { vehicleKey, vehicleTechnicalCriteria } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;
      const groups = getTechnicalCriteriaGroupsForVehicle(vehicle);

      vehicleTechnicalCriteria.groups.forEach((group) =>
        groups.set(
          group.groupName,
          group.technicalInfos.map((info) => ({ ...info, enabled: true })),
        ),
      );

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalCriteria: {
          simplifiedApplicability: false,
          groups,
        },
      });
    },
    setIAMVehicleTechnicalCriteria: (state, { payload }: PayloadAction<GetIAMVehicleTechnicalCriteriaResponse>) => {
      const { vehicleKey, version, mainEngine, transmission } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalIAMCriteria: {
          version,
          mainEngine,
          transmission,
        },
      });
    },
    setVehicleTechnicalCriteriaNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);

      if (!vehicle) return;

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalCriteria: status,
      });
    },
    setIAMVehicleTechnicalCriteriaNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);

      if (!vehicle) return;

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalIAMCriteria: status,
      });
    },
    resetPreviousTechnicalCriteria: (state) => {
      if (!state.lastSearchedVehicleKey || !state.vehicles) {
        return;
      }
      const previousVehicle = state.vehicles.get(state.lastSearchedVehicleKey);
      const techCrit = previousVehicle?.technicalCriteria;
      if (!techCrit) {
        return;
      }
      state.vehicles.set(state.lastSearchedVehicleKey, {
        ...previousVehicle,
        technicalCriteria: {
          simplifiedApplicability: false,
          groups: new Map<TechnicalCriteriaType, TechnicalInfoLocal[]>(),
        },
      });
    },
    resetTechnicalCriteria: (state) => {
      const vehicleKey = state.lastSearchedVehicleKey;
      if (!vehicleKey) return;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const groups = getTechnicalCriteriaGroupsForVehicle(vehicle);

      groups.forEach((group, key) =>
        mergeObjectInMap(
          groups,
          key,
          group.map((info) => ({
            ...info,
            enabled: true,
          })),
        ),
      );

      state.vehicles?.set(vehicleKey, {
        ...vehicle,
        technicalCriteria: {
          simplifiedApplicability: false,
          groups,
        },
      });
    },
    setVehicleTechnicalCriteriaEnabled: (
      state,
      {
        payload,
      }: PayloadAction<{
        code: string;
        groupType: TechnicalCriteriaType;
        enabled: boolean;
      }>,
    ) => {
      const { code, groupType, enabled } = payload;
      const vehicleKey = state.lastSearchedVehicleKey;
      if (!vehicleKey) return;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const groups = getTechnicalCriteriaGroupsForVehicle(vehicle);
      const group = groups.get(groupType);
      if (!group) return;

      mergeObjectInMap(
        groups,
        groupType,
        group.map((info) => (info.code === code ? { ...info, enabled } : info)),
      );

      const criteria = Array.from(groups.values()).flat();
      const simplifiedApplicability =
        criteria
          .filter((x) => x.enabled && x.code)
          .every((info) => TECHNICAL_CRITERIA_SIMPLIFIED_APPLICABILITY.includes(info.code as string)) &&
        !criteria
          .filter((x) => !x.enabled && x.code)
          .some((info) => TECHNICAL_CRITERIA_SIMPLIFIED_APPLICABILITY.includes(info.code as string));

      state.vehicles?.set(vehicleKey, {
        ...vehicle,
        technicalCriteria: {
          simplifiedApplicability,
          groups,
        },
      });
    },
    setSimplifiedApplicability: (
      state,
      {
        payload,
      }: PayloadAction<{
        enabled: boolean;
      }>,
    ) => {
      const { enabled } = payload;
      const vehicleKey = state.lastSearchedVehicleKey;
      if (!vehicleKey) return;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const groups = getTechnicalCriteriaGroupsForVehicle(vehicle);

      groups.forEach((group, key) =>
        mergeObjectInMap(
          groups,
          key,
          group.map((info) => ({
            ...info,
            enabled: enableTechnicalCriteria(enabled, info.code),
          })),
        ),
      );

      state.vehicles?.set(vehicleKey, {
        ...vehicle,
        technicalCriteria: {
          simplifiedApplicability: enabled,
          groups,
        },
      });
    },
    setMultipleVehicleFound: (state, { payload }: PayloadAction<SearchVehicleResponse | undefined>) => {
      state.multipleVehiclesFound = payload;
    },
    setFoundVehiclesAreFromDifferentCountries: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.foundVehiclesAreFromDifferentCountries = payload;
    },
    setVehicleTechnicalDataTree: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        vehicleCode: string;
        l1List: TechnicalDataL1[];
      }>,
    ) => {
      const { vehicleKey, vehicleCode, l1List } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const vehicleTechnicalDataLocal = getTechnicalDataForVehicle(vehicle);
      const vehicleTechnicalData = getData(vehicleTechnicalDataLocal.get(vehicleCode));
      if (vehicleTechnicalData) {
        vehicleTechnicalDataLocal.set(vehicleCode, { ...vehicleTechnicalData, technicalDataTree: l1List });
      } else {
        vehicleTechnicalDataLocal.set(vehicleCode, { technicalDataTree: l1List });
      }
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalData: vehicleTechnicalDataLocal,
      });
    },
    setVehicleTechnicalDataTreeNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        vehicleCode: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, vehicleCode, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const vehicleTechnicalDataLocal = getTechnicalDataForVehicle(vehicle);
      vehicleTechnicalDataLocal.set(vehicleCode, status);
      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalData: vehicleTechnicalDataLocal,
      });
    },
    setVehicleTechnicalDataDetails: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        vehicleCode: string;
        l3Id: string;
        contentList: TechnicalDataDetailContent[];
      }>,
    ) => {
      const { vehicleKey, vehicleCode, l3Id, contentList } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const technicalData = getTechnicalDataForVehicle(vehicle);
      const vehicleTechnicalData = getData(technicalData.get(vehicleCode));

      const technicalDataContentMap = getTechnicalDataDetail(vehicleTechnicalData);
      technicalDataContentMap.set(l3Id, { technicalDataContentList: contentList, searchStatus: FOUND });

      if (!vehicleTechnicalData) return;

      technicalData.set(vehicleCode, {
        ...vehicleTechnicalData,
        technicalDataDetail: technicalDataContentMap,
      });

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalData,
      });
    },
    setVehicleTechnicalDataDetailsNoDataStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        vehicleCode: string;
        l3Ids: string[];
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, vehicleCode, l3Ids, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (!vehicle) return;

      const technicalData = getTechnicalDataForVehicle(vehicle);
      const vehicleTechnicalData = getData(technicalData.get(vehicleCode));

      const technicalDataContentMap = getTechnicalDataDetail(vehicleTechnicalData);
      l3Ids.forEach((l3Id) => {
        const l3Data = technicalDataContentMap.get(l3Id);
        if (l3Data) {
          technicalDataContentMap.set(l3Id, { ...l3Data, searchStatus: status });
        } else {
          technicalDataContentMap.set(l3Id, { searchStatus: status });
        }
      });

      if (!vehicleTechnicalData) return;

      technicalData.set(vehicleCode, {
        ...vehicleTechnicalData,
        technicalDataDetail: technicalDataContentMap,
      });

      state.vehicles.set(vehicleKey, {
        ...vehicle,
        technicalData,
      });
    },
    setMaintenancePlanStatus: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, status } = payload;
      const vehicle = state.vehicles.get(vehicleKey);
      if (vehicle) {
        state.vehicles.set(vehicleKey, { ...vehicle, maintenancePlan: status });
      }
    },
    setMaintenancePlanData: (state, { payload }: PayloadAction<GetMaintenancePlanResponse>) => {
      const vehicleKey = payload.vin;
      const vehicle = state.vehicles.get(vehicleKey);
      if (vehicle) {
        const idServices = [
          ...new Set(
            payload.operationByServiceList
              .map((o) => o.carriedOutByOperationByMileageOrTimeIdList)
              .reduce((a, b) => a.concat(b), []),
          ),
        ].sort();
        const oilStandards = [...new Set(payload.engineOilList.map((o) => o.standard))].sort();
        const temperatures = [...new Set(payload.engineOilList.map((o) => o.temperature))].sort();
        const engineOilData = temperatures.map((temp) => {
          const dataForTemp = payload.engineOilList.filter((o) => o.temperature === temp);
          return { temp, dataForTemp };
        });

        state.vehicles.set(vehicleKey, {
          ...vehicle,
          maintenancePlan: {
            ...payload,
            operationsByServiceIds: idServices,
            engineOilList: engineOilData,
            engineOilStandards: oilStandards,
          },
        });
      }
    },
  },
});

// Actions
export const {
  setInitialState,
  setSearchVehicleLoading,
  setVehicleDetail,
  resetVehicleSearchStatus,
  setExplodedTree,
  resetExplodedTree,
  setExplodedIAMTree,
  setLastSearchedVehicleKey,
  setSearchKey,
  setPlateDetailData,
  resetPlateDetailData,
  setLevels3Details,
  resetLevels3Details,
  setLevels3DetailNoDataStatus,
  setPlateReferenceNoDataStatus,
  setPlateReferencesData,
  setUniversalProductsTextFilter,
  setUniversalProductsRangeFilter,
  resetUniversalProductsFilters,
  setMarketingReferencesForVehicleLoading,
  setMarketingReferences,
  resetMarketingReferences,
  setNodeIAMReferencesStatus,
  setNodeIAMReferences,
  setSearchStatus,
  setVehicleBrands,
  setVehicleBrandsNoDataStatus,
  setQuickAccessActiveSubCategory,
  setQuickAccessActiveCategory,
  setExplodedTreeNoDataStatus,
  setVehicleEngines,
  setVehicleEnginesNoDataStatus,
  setIAMVehicleBrands,
  setIAMVehicleBrandsNoDataStatus,
  setIAMVehicleModels,
  setIAMVehicleModelsNoDataStatus,
  setIAMVehicleVersions,
  setIAMVehicleVersionsNoDataStatus,
  clearIAMVehicleVersions,
  setEmptyFulltextAutocomplete,
  setFulltextAutocomplete,
  setFulltextAutocompleteNoData,
  setLastAutocompleteKey,
  setFulltextSearchResult,
  setIAMFulltextMainSearchResult,
  setIAMFulltextEquivalentSearchResult,
  setIAMFulltextEquivalentNoData,
  setIAMLaborTimeNoDataStatus,
  setOtsType,
  setOtsTypeStatus,
  setIAMLaborTimes,
  setIamCategoriesFilter,
  resetIamTextFilter,
  resetIamTextFilters,
  resetIamRangeFilters,
  resetIamCategoriesFilter,
  setUniversalProductsTree,
  setUniversalProducts,
  setUniversalProductsNoDataState,
  setVehicleTechnicalCriteria,
  setIamTextFilter,
  setIamRangeFilter,
  setIAMVehicleTechnicalCriteria,
  setVehicleTechnicalCriteriaNoDataStatus,
  setIAMVehicleTechnicalCriteriaNoDataStatus,
  resetPreviousTechnicalCriteria,
  resetTechnicalCriteria,
  setUniversalProductSelection,
  setVehicleTechnicalCriteriaEnabled,
  setSimplifiedApplicability,
  setMultipleVehicleFound,
  setFoundVehiclesAreFromDifferentCountries,
  setVehicleTechnicalDataTree,
  setVehicleTechnicalDataTreeNoDataStatus,
  setVehicleTechnicalDataDetails,
  setVehicleTechnicalDataDetailsNoDataStatus,
  setMaintenancePlanData,
  setMaintenancePlanStatus,
  setIAMRepairMethods,
  setIAMRepairMethodsNoDataStatus,
  setIAMRepairMethodsTree,
  setIAMRepairMethodsTreeNoDataStatus,
} = slice.actions;

// Getters/Selectors
export const getSearchVehicleResult = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (_state: RootState, query: string | undefined) => query,
  (vehicles, query) => getVehicleItemState(query, vehicles),
);

export const getLastSearchedVehicleKey = createSelector(
  (state: RootState) => state.catalog.lastSearchedVehicleKey,
  (lastSearchedVehicleKey) => lastSearchedVehicleKey,
);
export const getSearchKey = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (_state: RootState, vehicleKey: string | undefined) => vehicleKey,
  (vehicles, vehicleKey) => {
    if (vehicleKey) {
      return vehicles.get(vehicleKey)?.searchKey;
    }
  },
);

export const getLastVehicle = createSelector(
  (state: RootState) => getVehicleItemState(state.catalog.lastSearchedVehicleKey, state.catalog.vehicles),
  (lastVehicle) => lastVehicle,
  {
    memoizeOptions: {
      maxSize: 10,
    },
  },
);

export const getLastVehicleDetail = createSelector(
  (state: RootState) =>
    getVehicleItemState(state.catalog.lastSearchedVehicleKey, state.catalog.vehicles)?.vehicleDetail,
  (vehicleDetail) => vehicleDetail,
  {
    memoizeOptions: {
      maxSize: 10,
    },
  },
);

export const getExplodedTree = createSelector(
  (state: RootState, query: string | undefined) => getVehicleExplodedTree(query, state.catalog.vehicles),
  (tree) => tree,
);

export const getExplodedIAMTree = createSelector(
  (state: RootState, query: string | undefined) => getVehicleExplodedIAMTree(query, state.catalog.vehicles),
  (tree) => tree,
);

export const getExplodedOEMTree = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (_state: RootState, query: string | undefined) => query,
  (vehicles, query) => {
    return getVehicleExplodedOEMTree(query, vehicles);
  },
);

export const getCatalogIAMExplodedTree = createSelector(
  (state: RootState) => state.catalog.vehicles,
  getIamCatalogBrandsView,
  (_state: RootState, query: string | undefined) => query,
  (vehicles, view, query) => {
    switch (view) {
      case OTHER_BRAND_KEY:
        return getVehicleExplodedIAMTree(query, vehicles);
      case OEM_BRAND_KEY:
        return getVehicleExplodedOEMTree(query, vehicles);
    }
  },
);

export const getExplodedTreeLevel = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      query: string | undefined;
      parentIds: string[];
    },
  ) => params,
  (vehicles, params) => {
    const tree = getVehicleExplodedTree(params.query, vehicles);
    return tree?.data ? parseExplodedTreeLevel(tree.data, params.parentIds) : undefined;
  },
);

export const getDetailOfLevels3 = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      nodeIds: string[];
      query: string;
    },
  ) => params,
  (vehicles, params) => {
    const { query, nodeIds } = params;
    const result = new Map<string, MultiPlate[] | NO_DATA>();
    const multiPlates = vehicles.get(query)?.multiPlates;
    nodeIds.forEach((nodeId) => {
      result.set(nodeId, multiPlates?.get(nodeId));
    });
    return result;
  },
);

export const getDetailOfLevel3 = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      nodeId: string | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, nodeId } = params;
    return vehicleKey && nodeId ? vehicles.get(vehicleKey)?.multiPlates?.get(nodeId) : undefined;
  },
);

export const getPlate = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId } = params;
    return vehicleKey && plateId ? getPlateFromVehicleMap(vehicles, vehicleKey, plateId) : undefined;
  },
);

export const getPlates = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateIds: string[] | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateIds } = params;
    return vehicleKey && plateIds
      ? plateIds?.map((plateId) => getPlateFromVehicleMap(vehicles, vehicleKey, plateId)).filter((x) => x)
      : undefined;
  },
);

export const getPlatesImagesKeys = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateIds: string[] | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateIds } = params;
    return vehicleKey && plateIds
      ? plateIds.map((plateId) => {
          const plateData = getPlateFromVehicleMap(vehicles, vehicleKey, plateId);
          return { plateId, imageKey: plateData?.thumbnailImageKey ?? undefined };
        })
      : [];
  },
);

export const getPlateImageKey = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId } = params;
    const plateData = vehicleKey && plateId ? getPlateFromVehicleMap(vehicles, vehicleKey, plateId) : undefined;
    return { plateId, imageKey: plateData?.thumbnailImageKey ?? undefined };
  },
);

export const getPlateFullImageKey = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId } = params;
    const plateData = vehicleKey && plateId ? getPlateFromVehicleMap(vehicles, vehicleKey, plateId) : undefined;
    return { plateId, imageKey: plateData?.svgImageKey ?? undefined } as PlateImage;
  },
);

export const getPlatesFullImageKeys = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateIds: string[] | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateIds } = params;
    return vehicleKey && plateIds
      ? plateIds.map((plateId) => {
          const plateData = vehicleKey && plateId ? getPlateFromVehicleMap(vehicles, vehicleKey, plateId) : undefined;
          return { plateId, imageKey: plateData?.svgImageKey ?? undefined } as PlateImage;
        })
      : [];
  },
);

export const getPlateGenericParts = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string | undefined | null;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId } = params;
    if (!plateId || !vehicleKey) {
      return [];
    }
    return getPlateFromVehicleMap(vehicles, vehicleKey, plateId)?.genericParts ?? [];
  },
);

export const getMultiplateLabels = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string;
      vehicleKey: string;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId } = params;
    return vehicleKey && plateId ? getPlateFromVehicleMap(vehicles, vehicleKey, plateId)?.plateDetail : undefined;
  },
);

export const getPlateReferences = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId } = params;
    return vehicleKey && plateId ? getPlateFromVehicleMap(vehicles, vehicleKey, plateId)?.references : undefined;
  },
);

export const getPlateReference = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateId: string | undefined;
      vehicleKey: string | undefined;
      index: number | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateId, index } = params;
    if (vehicleKey && plateId && index) {
      const plRef = getPlateFromVehicleMap(vehicles, vehicleKey, plateId)?.references;
      return isLoading(plRef) ? LOADING : getData(plRef)?.find((r) => r.index === index);
    }
    return undefined;
  },
);

export const getMultiplePlateReferences = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      plateIds: string[] | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, plateIds } = params;
    return vehicleKey && plateIds
      ? plateIds.reduce(
          (acc, next) => acc.set(next, getPlateFromVehicleMap(vehicles, vehicleKey, next)?.references),
          new Map<string, NO_DATA | PlateReferencesDetail[]>(),
        )
      : undefined;
  },
);

export const getNodeIAMReferencesWrapperForVehicle = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    params: {
      nodeId: string | undefined;
      vehicleKey: string | undefined;
    },
  ) => params,
  (vehicles, params) => {
    const { vehicleKey, nodeId } = params;
    return vehicleKey && nodeId ? getNodeIdFromVehicleMap(vehicles, vehicleKey, nodeId) : undefined;
  },
);

export const getMarketingReferencesWrapperForVehicle = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    payload: {
      query: string;
      nodeId: string;
    },
  ) => payload,
  (vehicles, payload) => {
    const { query, nodeId } = payload;
    return vehicles.get(query)?.nodeIdToMarketingReferences?.get(nodeId);
  },
);

export const getVehicleBrands = createSelector(
  (state: RootState) => state.catalog.vehicleBrands,
  (vehicleBrands) => vehicleBrands,
);

export const getIAMVehicleBrands = createSelector(
  (state: RootState) => state.catalog.iam_vehicleBrands,
  (vehicleBrands) => vehicleBrands,
);

export const getIAMLaborTimesWrapper = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    payload: {
      query: string;
      nodeId: string;
    },
  ) => payload,
  (vehicles, payload) => {
    const { query, nodeId } = payload;
    return vehicles.get(query)?.nodeIdToIAMLaborTimes?.get(nodeId);
  },
);

export const getIamCatalogFilters = createSelector(
  (state: RootState) => state.catalog.iamFilters,
  (iamFilters) => iamFilters,
);

export const getActiveQuickAccess = createSelector(
  (state: RootState) => state.catalog.activeQuickAccess,
  (activeQuickAccess) => activeQuickAccess,
);

export const getFulltextAutocomplete = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (_state: RootState, query: string) => query,
  (vehicles, query) => {
    return vehicles.get(query)?.fulltextSearch?.fulltextAutocomplete;
  },
);

export const getFulltextSearchResult = createSelector(
  (state: RootState) => state.catalog.searchStatus,
  (searchStatus) => {
    return searchStatus?.fullTextSearch?.fulltextSearchResult;
  },
);

export const getIAMFulltextSearchResult = createSelector(
  (state: RootState) => state.catalog.searchStatus,
  (searchStatus) => searchStatus?.iamFullTextSearch,
);

export const getSearchStatus = createSelector(
  (state: RootState) => state.catalog.searchStatus,
  (searchStatus) => searchStatus,
);

export const findPlateUrlInVehicleTree = createSelector(
  (state: RootState) => state.catalog.vehicles,
  getLastSearchedVehicleKey,
  (_state: RootState, plateId: string) => plateId,
  (vehicles, lastSearchedVehicleKey, plateId) => {
    let path: string | undefined;
    const vehicle = getVehicleItemState(lastSearchedVehicleKey, vehicles);
    const tree = vehicle?.explodedTree?.data;
    tree?.forEach((lv1) =>
      lv1.items.forEach((lv2) =>
        lv2.items.forEach((lv3) => {
          if (lv3.plateIds.includes(plateId) && !path) {
            path = `${lastSearchedVehicleKey}/vehicle/${lv1.nodeId}/${lv2.nodeId}/${lv3.nodeId}?${PlateIdParam}=${plateId}`;
          }
        }),
      ),
    );
    return path;
  },
);

export const getLastTechnicalCriteria = createSelector(
  (state: RootState) => state.catalog.vehicles,
  getLastSearchedVehicleKey,
  (vehicles, lastSearchedVehicleKey) => {
    if (!lastSearchedVehicleKey) {
      return undefined;
    }
    return vehicles.get(lastSearchedVehicleKey)?.technicalCriteria;
  },
);

export const getLastIAMTechnicalCriteria = createSelector(
  (state: RootState) => state.catalog.vehicles,
  getLastSearchedVehicleKey,
  (vehicles, lastSearchedVehicleKey) => {
    if (!lastSearchedVehicleKey) {
      return undefined;
    }
    return vehicles.get(lastSearchedVehicleKey)?.technicalIAMCriteria;
  },
);

export const getVehicleContext = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (_state: RootState, vehicleKey: string) => vehicleKey,
  (vehicles, vehicleKey) => {
    const vehicle = vehicles.get(vehicleKey);
    const versionCode = vehicle?.vehicleDetail?.iamVehicle?.versionCode;
    const vehicleTechnicalCriteria = getData(vehicle?.technicalCriteria);
    const groups = vehicleTechnicalCriteria?.groups;
    if (!groups) return { vehicleKey, versionCode, ignoredCriteria: undefined };
    const technicalInfos = Array.from(groups.values()).flat() as TechnicalInfoLocal[];
    return {
      vehicleKey,
      versionCode,
      ignoredCriteria: technicalInfos.filter((crit) => !crit.enabled && crit.code).map((crit) => crit.code) as string[],
    };
  },
);

export const getUniversalProductsTree = createSelector(
  (state: RootState) => state.catalog.universalProducts.tree,
  (universalProductsTree) => universalProductsTree,
);

export const getAllUniversalProducts = createSelector(
  (state: RootState) => state.catalog.universalProducts.items,
  (universalProducts) => universalProducts,
);

export const getUniversalProduct = createSelector(
  (state: RootState) => state.catalog.universalProducts.items,
  (_state: RootState, reference: string) => reference,
  (universalProducts, reference) => universalProducts.get(reference),
);

export const getUniversalProducts = createSelector(
  (state: RootState) => state.catalog.universalProducts.items,
  (_state: RootState, references: string[]) => references,
  (universalProducts, references) => new Map([...universalProducts].filter(([key]) => references.includes(key))),
);

export const getUniversalProductsReferencesLevel3 = createSelector(
  (state: RootState) => state.catalog.universalProducts.tree,
  (state: RootState) => state.catalog.universalProducts.selection,
  (tree, selectedReferences) => {
    if (isLoading(tree) || isLoading(selectedReferences)) {
      return LOADING;
    }
    if (!hasData(tree) || !hasData(selectedReferences)) {
      return undefined;
    }
    const level1 = tree.filter((item) => item.nodeId === selectedReferences.level1);
    if (!selectedReferences.level1) {
      return undefined;
    }
    if (level1.length === 0) {
      return [];
    }

    const level2 =
      level1[0].childCategories &&
      level1[0].childCategories.filter((item) => item.nodeId === selectedReferences.level2);
    if (!selectedReferences.level2) {
      return undefined;
    }
    if (!level2 || level2.length === 0) {
      return [];
    }

    const level3 =
      level2[0].childCategories &&
      level2[0].childCategories.filter((item) => item.nodeId === selectedReferences.level3);
    if (!selectedReferences.level3) {
      return undefined;
    }
    if (!level3 || level3.length === 0) {
      return [];
    }

    const referencesLevel3 = level3[0].references;
    return referencesLevel3 ? referencesLevel3 : [];
  },
);

export const getUniversalProductsSelectionL1 = createSelector(
  (state: RootState) => state.catalog.universalProducts.selection,
  (selectedReferences) => (isLoading(selectedReferences) ? LOADING : getData(selectedReferences)?.level1),
);

export const getUniversalProductsSelection = createSelector(
  (state: RootState) => state.catalog.universalProducts.selection,
  (selectedReferences) => selectedReferences,
);

export const getUniversalProductsFilter = createSelector(
  (state: RootState) => state.catalog.universalProducts.filter,
  (filter) => filter,
);

export const getMultipleVehicleFound = createSelector(
  (state: RootState) => state.catalog.multipleVehiclesFound,
  (multipleVehiclesFound) => multipleVehiclesFound,
);

export const getFoundVehiclesAreFromDifferentCountries = createSelector(
  (state: RootState) => state.catalog.foundVehiclesAreFromDifferentCountries,
  (foundVehiclesAreFromDifferentCountries) => foundVehiclesAreFromDifferentCountries,
);

export const getQueryByRequestId = createSelector(
  (state: RootState) => state.catalog.requestedItems,
  (_state: RootState, requestId: string | undefined) => requestId,
  (requestedItems, requestId) => {
    if (requestId === undefined) {
      return undefined;
    }
    return requestedItems.get(requestId);
  },
);

export const wasQueryRequested = createSelector(
  (state: RootState) => state.catalog.requestedItems,
  (_state: RootState, query: string | undefined) => query,
  (requestedItems, query) => {
    if (query === undefined) {
      return false;
    }
    return [...requestedItems.values()].find((v) => v === query) !== undefined;
  },
);

export const getLastSearchVehicleTechnicalData = createSelector(getLastVehicle, (lastVehicle) => {
  if (!lastVehicle) return undefined;
  const versionCode = lastVehicle.vehicleDetail?.iamVehicle?.versionCode;
  if (!versionCode) return undefined;
  return getData(lastVehicle.technicalData?.get(versionCode))?.technicalDataTree;
});

export const getVehicleTechnicalDataCategoryL2List = createSelector(
  getLastVehicle,
  (_state: RootState, categoryId: string | undefined) => categoryId,
  (vehicle, categoryId) => {
    const vehicleDetail = vehicle?.vehicleDetail;
    const versionCode = vehicleDetail?.iamVehicle?.versionCode;

    if (!vehicle || !versionCode) {
      return undefined;
    }

    const vehicleTechnicalData = getData(vehicle.technicalData?.get(versionCode));
    return vehicleTechnicalData?.technicalDataTree.find((category) => category.id === categoryId)?.l2List;
  },
);

export const getVehicleTechnicalDataCategoryL3Ids = createSelector(
  getLastVehicle,
  (_state: RootState, categoryId: string | undefined) => categoryId,
  (vehicle, categoryId) => {
    const vehicleDetail = vehicle?.vehicleDetail;
    const versionCode = vehicleDetail?.iamVehicle?.versionCode;

    if (!vehicle || !versionCode) {
      return undefined;
    }

    const technicalData = getData(vehicle.technicalData?.get(versionCode));
    const l2List = technicalData?.technicalDataTree.find((category) => category.id === categoryId)?.l2List;

    if (!l2List) {
      return undefined;
    }
    const l3list: string[] = [];
    l2List.forEach((l2) => l2.l3List.forEach((l3) => l3list.push(l3.id)));
    return l3list;
  },
);

export const getVehicleTechnicalDataCategoryL3Data = createSelector(
  getLastVehicle,
  (_state: RootState, categoryId: string) => categoryId,
  (vehicle, categoryId) => {
    const vehicleDetail = vehicle?.vehicleDetail;
    const versionCode = vehicleDetail?.iamVehicle?.versionCode;

    if (!vehicle || !versionCode) {
      return undefined;
    }

    const technicalData = getData(vehicle.technicalData?.get(versionCode))?.technicalDataDetail;
    return technicalData?.get(categoryId);
  },
);

export const getMaintenancePlan = createSelector(getLastVehicle, (vehicle) => {
  if (!vehicle) return undefined;
  return vehicle.maintenancePlan;
});

export const getOtsType = createSelector(getLastVehicle, (vehicle) => {
  if (!vehicle) return undefined;
  return vehicle.otsType;
});

export const getLastSearchVehicleVersionCode = createSelector(getLastVehicle, (lastVehicle) => {
  if (!lastVehicle) return undefined;
  return lastVehicle.vehicleDetail?.iamVehicle?.versionCode;
});

export const getIAMRepairMethodsWrapper = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    payload: {
      query: string;
      nodeId: string;
    },
  ) => payload,
  (vehicles, payload) => {
    const { query, nodeId } = payload;
    return vehicles.get(query)?.nodeIdToIAMRepairMethods?.get(nodeId);
  },
);

export const getIAMRepairMethodsTree = createSelector(
  (state: RootState) => state.catalog.vehicles,
  (
    _state: RootState,
    payload: {
      query: string;
    },
  ) => payload,
  (vehicles, payload) => {
    const { query } = payload;
    return vehicles.get(query)?.repairMethods;
  },
);

// Export reducer
export default slice.reducer;
