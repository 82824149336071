/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getDHReference, getDHReferences } from 'domains/references';
import { useFetchCrossSellingReferences, useFetchReuseStocks, usePrice } from 'domains/references/References.requests';
import { SparePartsViewType } from 'domains/user';
import CrossSellingSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/CrossSellingSection/CrossSellingSection';
import ReferenceCardWrapper from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardWrapper';
import { getData, getSearchData, hasData } from 'utils';

export interface SearchResultCardsContainerProps {
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  vehicleBrand: VehicleBrandType;
  seePlateState: [string | undefined, (x: string | undefined) => void];
}

export const SearchResultReferenceContainer = ({
  referenceNumber,
  sparePartsView,
  vehicleBrand,
  seePlateState,
}: SearchResultCardsContainerProps) => {
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const ref = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));
  const prices = usePrice(referenceNumber);
  const alternativeReferencesNumbers = getData(ref)?.alternativeReferences ?? [];
  const alternativeReferences = useSelector((state: RootState) =>
    getDHReferences(state, { vehicleKey, referenceNumbers: alternativeReferencesNumbers }),
  );

  useFetchCrossSellingReferences(alternativeReferencesNumbers, vehicleKey);
  useFetchReuseStocks(alternativeReferencesNumbers);

  if (!hasData(ref)) {
    return null;
  }

  return (
    <>
      <ReferenceCardWrapper
        referenceNumber={ref.referenceNumber}
        name={ref.name}
        referenceBrand={ref.brand}
        vehicleBrand={vehicleBrand}
        price={getSearchData(prices)}
        sparePartsView={sparePartsView}
        displayPlateLink={true}
        linkedReferences={ref.linkedReferences ?? []}
        isApplicableToCurrentVehicle={ref.isApplicableToCurrentVehicle}
        displaySupersessionTooltip={ref.supersessionChainReferences && ref.supersessionChainReferences.length > 0}
        seePlateState={seePlateState}
        genericPart={ref.genericPart}
      />
      <CrossSellingSection mainRef={ref.referenceNumber} crossSellingReferences={ref.crossSelling} type={'catalog'} />
      {alternativeReferences.map((alt, index) => (
        <>
          <ReferenceCardWrapper
            key={`searched-ref-${referenceNumber}-alt-${index}`}
            referenceNumber={alt.referenceNumber}
            name={alt.name}
            referenceBrand={alt.brand}
            vehicleBrand={vehicleBrand}
            price={getSearchData(prices)}
            sparePartsView={sparePartsView}
            displayPlateLink={true}
            linkedReferences={alt.linkedReferences ?? []}
            isApplicableToCurrentVehicle={alt.isApplicableToCurrentVehicle}
            displaySupersessionTooltip={alt.supersessionChainReferences && alt.supersessionChainReferences.length > 0}
            seePlateState={seePlateState}
            genericPart={alt.genericPart}
          />
          <CrossSellingSection
            mainRef={alt.referenceNumber}
            crossSellingReferences={alt.crossSelling}
            type={'catalog'}
          />
        </>
      ))}
    </>
  );
};
