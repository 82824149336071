/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { TireItem } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { RootState } from 'app/AppStore';
import { ChevronDownIcon, CopyTireIcon, TiresNoReplacementFoundIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { LIFECYCLE_W1 } from 'domains/catalog/Catalog.types';
import { getStocksMap, ReferenceStockStatus } from 'domains/references';
import { sortStocksByWarehouse, stocksMapToArray } from 'domains/references/References.mapper';
import { SparePartsViewType } from 'domains/user';
import { OpennerWrapper } from 'pages/CatalogPage/IAM/FullTextSearchResult/SearchResultSection.styled';
import { TireReferenceCardsInfiniteScroll } from 'pages/TiresPage/TireReferencesSection/TireReferencesContainer/TireReferenceCardsInfiniteScroll';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, PaddingBox, Spin, Text } from 'UI';
import { FOUND, isLoading, LOADING, NOT_FOUND, SEARCH_STATUS, SearchData } from 'utils';
import { ReferenceMap } from 'utils/dataStructure/ReferenceMap';

const TireStocksLoader = ({ referencesCount }: { referencesCount: number }) => {
  const { t } = useTranslation();
  return (
    <Box height={100} align={'center'}>
      <Flex direction={'row'} justify={'center'} align={'center'}>
        <Text type={'h3_dim'} transform={'capitalize'}>
          {t('catalog.references_list.loading', 'Loading data for {{count}} references', {
            count: referencesCount,
          })}
        </Text>
        <MarginBox mr={15} />
        <Spin />
      </Flex>
    </Box>
  );
};

function sortTireReferencesByReferenceNumbers(references: TireItem[], referenceNumbers: string[]) {
  return [...references].sort((a, b) => {
    return referenceNumbers.indexOf(a.partNumber) - referenceNumbers.indexOf(b.partNumber);
  });
}

function sortTireReferencesByLifecycle(a: TireItem, b: TireItem) {
  if (a.lifeCycle === LIFECYCLE_W1 || b.lifeCycle === LIFECYCLE_W1) {
    if (a.lifeCycle === LIFECYCLE_W1 && b.lifeCycle === LIFECYCLE_W1) {
      return 0;
    } else if (a.lifeCycle === LIFECYCLE_W1) {
      return 1;
    } else {
      return -1;
    }
  } else {
    return 0;
  }
}

function sortTireReferencesLocation(
  references: TireItem[],
  stockData: ReferenceMap<SearchData<ReferenceStock> | undefined>,
): TireItem[] {
  const stocksArray: ReferenceStockStatus[] = stocksMapToArray(stockData.items);
  const sortedStocks: ReferenceStockStatus[] = [...stocksArray].sort(sortStocksByWarehouse);

  const sortedByLoc = sortTireReferencesByReferenceNumbers(
    references,
    sortedStocks.map((stock) => stock.data.reference),
  );

  return [...sortedByLoc].sort(sortTireReferencesByLifecycle);
}

interface TireReferencesProps {
  searchParamsBase64: string;
  tires: TireItem[];
  sparePartsView: SparePartsViewType;
  singleSection: boolean;
  title?: string;
  initShowCount: number;
  isReplacementAvailable: boolean;
  compact?: boolean;
}

export const TireReferenceCardsContainer = ({
  searchParamsBase64,
  tires,
  sparePartsView,
  singleSection,
  title,
  initShowCount,
  isReplacementAvailable,
  compact,
}: TireReferencesProps) => {
  const referenceNumbers = tires.map((item) => item.partNumber);
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers ?? []));
  const stocksSearchStatus = useMemo(() => {
    return [...[...stocks.values()].map((s) => s?.searchStatus)];
  }, [stocks]);
  const stocksLoading = stocksSearchStatus.some((sp) => isLoading(sp));

  const [stockDataStatus, setStockDataStatus] = useState<SEARCH_STATUS>(LOADING);
  const [allReferences, setAllReferences] = useState<TireItem[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  useEffect(() => {
    setStockDataStatus(LOADING);
  }, [searchParamsBase64]);

  useEffect(() => {
    if (stockDataStatus !== FOUND && referenceNumbers.length > 0 && !stocksLoading) {
      setStockDataStatus(FOUND);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stocksLoading, stockDataStatus, JSON.stringify(referenceNumbers)]);

  useEffect(() => {
    if (stockDataStatus === FOUND) {
      const tireReferences = sortTireReferencesLocation(tires, stocks);
      if (tireReferences.length === 0) {
        setStockDataStatus(NOT_FOUND);
      }
      setAllReferences(tireReferences);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockDataStatus, JSON.stringify(referenceNumbers)]);

  const displayTitleAndMargin = stockDataStatus !== FOUND || (tires.length > 0 && allReferences.length > 0);
  const tireLoader = () => <TireStocksLoader referencesCount={tires.length} />;

  return (
    <Box background={title && isReplacementAvailable ? theme.color.grey95 : theme.color.white}>
      {title && (
        <MarginBox mx={10}>
          <Flex
            align={'center'}
            background={isReplacementAvailable ? theme.color.grey95 : theme.color.white}
            justify={'space-between'}
          >
            <PaddingBox pl={15} pb={15} pt={30}>
              {isReplacementAvailable && (
                <Icon IconComponent={CopyTireIcon} mr={10} height={20} width={20} noPointer={true} />
              )}
              <Text type={'h4_paragraph_title'}>{title}</Text>
            </PaddingBox>
            {isReplacementAvailable && tires.length > 0 && (
              <PaddingBox pl={15} pb={15} pr={10} pt={30}>
                <OpennerWrapper $isOpen={isOpen}>
                  <Icon
                    IconComponent={ChevronDownIcon}
                    color={theme.color.brand_black}
                    size={25}
                    onClick={() => setIsOpen(!isOpen)}
                  />
                </OpennerWrapper>
              </PaddingBox>
            )}
          </Flex>
        </MarginBox>
      )}
      {isOpen && tires.length > 0 ? (
        <DataContainer data={stockDataStatus} Loading={tireLoader}>
          <TireReferenceCardsInfiniteScroll
            tires={allReferences}
            sparePartsView={sparePartsView}
            singleSection={singleSection}
            initShowCount={initShowCount}
            compact={compact}
          />
        </DataContainer>
      ) : (
        <Flex justify={'center'}>
          <Icon IconComponent={TiresNoReplacementFoundIcon} height={500} width={500} noPointer={true} />
        </Flex>
      )}
      {displayTitleAndMargin}
    </Box>
  );
};
