import { SearchData } from 'utils/dataStatus';

export type ReferenceKey = {
  referenceNumber: string;
  sellerTresorCode: string;
  buyerTresorCode: string;
};

export function createNewReferenceMap<T>(
  existingMap: ReferenceMap<SearchData<T>>,
  newItems: Array<Record<string, any>>,
  mapNewItem: (item: any) => SearchData<T>,
  getReferenceKey: (item: any) => string,
): ReferenceMap<SearchData<T>> {
  const newMap = new ReferenceMap<SearchData<T>>();

  existingMap.items.forEach((value, key) => {
    newMap.set(ReferenceMap.parseHashKey(key), value);
  });

  newItems.forEach((item) => {
    const key = ReferenceMap.parseHashKey(getReferenceKey(item));
    newMap.set(key, mapNewItem(item));
  });

  return newMap;
}
export class ReferenceMap<T> {
  readonly items: Map<string, T> = new Map();

  static readonly parseHashKey = (hashKey: string): ReferenceKey => {
    const [referenceNumber, sellerTresorCode, buyerTresorCode] = hashKey.split('|');
    return {
      referenceNumber,
      ...(sellerTresorCode ? { sellerTresorCode } : { sellerTresorCode: '' }),
      ...(buyerTresorCode ? { buyerTresorCode } : { buyerTresorCode: '' }),
    };
  };

  static createHashKey(key: ReferenceKey): string {
    return `${key.referenceNumber}|${key.sellerTresorCode || ''}|${key.buyerTresorCode || ''}`;
  }

  set(key: ReferenceKey, value: T): void {
    const hashKey = ReferenceMap.createHashKey(key);
    this.items.set(hashKey, value);
  }

  has(key: ReferenceKey): boolean {
    return this.items.has(ReferenceMap.createHashKey(key));
  }

  get(key: ReferenceKey | string): T | undefined {
    if (typeof key === 'string') {
      const hashKeyPattern = `${key}|`;
      const matchingKey = Array.from(this.items.keys()).find((k) => k.startsWith(hashKeyPattern));
      return matchingKey ? this.items.get(matchingKey) : undefined;
    }

    const hashKey = ReferenceMap.createHashKey(key);
    return this.items.get(hashKey);
  }

  delete(key: ReferenceKey | string): boolean {
    if (typeof key === 'string') {
      const hashKeyPattern = `${key}|`;
      const matchingKey = Array.from(this.items.keys()).find((k) => k.startsWith(hashKeyPattern));
      return matchingKey ? this.items.delete(matchingKey) : false;
    }

    const hashKey = ReferenceMap.createHashKey(key);
    return this.items.delete(hashKey);
  }

  values(): Array<T> {
    return Array.from(this.items.values());
  }

  entries(): IterableIterator<[ReferenceKey, T]> {
    function* entriesGenerator(map: Map<string, T>): IterableIterator<[ReferenceKey, T]> {
      for (const [hashKey, value] of map.entries()) {
        yield [ReferenceMap.parseHashKey(hashKey), value];
      }
    }

    return entriesGenerator(this.items);
  }

  clear(): void {
    this.items.clear();
  }

  keys(): ReferenceKey[] {
    return Array.from(this.items.keys()).map(ReferenceMap.parseHashKey);
  }

  referenceNumbers(): string[] {
    return Array.from(this.items.keys()).map((key) => key.split('|')[0]);
  }
}
