import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TechnicalCriteriaType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/TechnicalCriteriaType';
import { useTheme } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { RedoIcon } from 'assets/icons';
import { resetTechnicalCriteria, setVehicleTechnicalCriteriaEnabled } from 'domains/catalog/Catalog.store';
import { TECHNICAL_CRITERIA_MANDATORY_CODES, TechnicalInfoLocal } from 'domains/catalog/Catalog.types';
import { SCriteriaRowBox, SResetButton } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteria.styled';
import { ItemElement } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenuAndContent';
import { Flex, Icon, MarginBox, Text, WithTooltip } from 'UI';
import { Switch } from 'UI/Switch';

function TechnicalCriteriaRow({
  data,
  groupType,
  refreshCategories,
}: {
  data: TechnicalInfoLocal;
  groupType: TechnicalCriteriaType;
  refreshCategories?: () => void;
}) {
  const dispatch = useDispatch();

  const refreshCategoriesDebounced = useDebouncedCallback(() => {
    refreshCategories && refreshCategories();
  }, 700);

  const handleSwitchChecked = useCallback(
    (enabled: boolean) => {
      if (!data.code || !groupType) return;
      dispatch(setVehicleTechnicalCriteriaEnabled({ code: data.code, groupType, enabled }));
      refreshCategoriesDebounced();
    },
    [dispatch, data, groupType, refreshCategoriesDebounced],
  );

  return (
    <Flex size={0} align={'center'}>
      <SCriteriaRowBox>
        <Text type={'text_dim'}>{data.title}</Text>
      </SCriteriaRowBox>
      <MarginBox ml={45} />
      <SCriteriaRowBox>
        <WithTooltip title={data.description} placement={'bottom'}>
          <Text type={data.enabled === false ? 'light_14_black_25' : 'light_14_black_65'} ellipsis>
            {data.description}
          </Text>
        </WithTooltip>
      </SCriteriaRowBox>
      <MarginBox ml={30} />
      {data.code && !TECHNICAL_CRITERIA_MANDATORY_CODES.includes(data.code) ? (
        <Switch checked={data.enabled} onChange={handleSwitchChecked} />
      ) : (
        <MarginBox mr={44} />
      )}
      <MarginBox mr={45} />
    </Flex>
  );
}

export function TechnicalCriteriaContent({
  item,
  refreshCategories,
  enableReset,
}: {
  item: ItemElement;
  refreshCategories?: () => void;
  enableReset: boolean | undefined;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleResetClicked = useCallback(() => {
    dispatch(resetTechnicalCriteria());
    refreshCategories && refreshCategories();
  }, [dispatch, refreshCategories]);

  if (!item.data) return null;

  return (
    <Flex direction={'column'}>
      <Flex size={0} justify={'space-between'} align={'center'}>
        <Text disableGutter type={'h1_banner_light'}>
          {item.label}
        </Text>
        {refreshCategories && (
          <SResetButton>
            {enableReset ? (
              <Icon IconComponent={RedoIcon} size={28} color={theme.color.red} onClick={handleResetClicked} />
            ) : (
              <Icon IconComponent={RedoIcon} size={28} color={theme.color.grey65} noPointer />
            )}
            <Text
              type={'light_14_black_65'}
              cursor={enableReset ? 'pointer' : 'initial'}
              onClick={() => (enableReset ? handleResetClicked() : {})}
            >
              {t('catalog.details.technical_criteria.reset_data', 'Reset data')}
            </Text>
          </SResetButton>
        )}
      </Flex>
      <MarginBox mt={15} />
      <Flex direction={'column'}>
        {item.data.map((d, key) => (
          <TechnicalCriteriaRow
            key={`${key}${d.title}`}
            data={d}
            groupType={item.type}
            refreshCategories={refreshCategories}
          />
        ))}
      </Flex>
    </Flex>
  );
}
