import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserContext } from '@1po/1po-bff-fe-spec/generated/basket/request/CreateOrderFromBasket';
import { ReturnEmailRequest, ReturnReasonType } from '@1po/1po-bff-fe-spec/generated/order/request/ReturnEmailRequest';
import { ReferenceBrandType } from '@1po/1po-bff-fe-spec/generated/order/response/GetExternalOrdersResponse';
import { ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { ReplyIcon } from 'assets/icons';
import { sendEmailReturnRequestRequestSaga } from 'domains/email/Email.store';
import { resetReturnRequest } from 'domains/order/Order.store';
import { OrderReturnItem } from 'domains/order/Order.types';
import { getBrandLabel, getDHReference } from 'domains/references';
import { requestReasonOptions } from 'pages/MyOrdersPage/OrderReturnSection/RequestReasonSelect';
import { STable } from 'pages/MyOrdersPage/OrderReturnSection/ReturnDialog.styled';
import { theme } from 'styles';
import { Flex, Icon, MarginBox, Modal, Text, WhiteButton, YellowButton } from 'UI';
import { hasData } from 'utils';

const mapReturnRequest = (orderId: string, userContext: UserContext, items: OrderReturnItem[]): ReturnEmailRequest => {
  return {
    orderId,
    userContext,
    referencesToReturn: items.map((item) => ({
      referenceNumber: item.referenceNumber,
      returnedQuantity: item.qtyToReturn,
      parentReference: item.parentReferenceNumber,
      returnReason: item.requestReason,
    })),
  };
};

export const ReferenceWithBrand = ({ returnItem }: { returnItem: OrderReturnItem }) => {
  const defaultBrand = returnItem.brand;
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: returnItem?.referenceNumber, vehicleKey: undefined }),
  );

  function getReferenceNumberWithBrand(brand: ReferenceBrandType | undefined) {
    const brandLabel = getBrandLabel(brand, returnItem.supplier);
    if (!brandLabel) {
      return <>{returnItem.referenceNumber}</>;
    }
    return <>{returnItem.referenceNumber + ' - ' + brandLabel}</>;
  }

  if (!hasData(reference) || defaultBrand) {
    return getReferenceNumberWithBrand(defaultBrand);
  }
  return getReferenceNumberWithBrand(reference.brand);
};

export const ReferenceWithName = ({ returnItem }: { returnItem: OrderReturnItem }) => {
  const defaultName = returnItem.name;
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: returnItem?.referenceNumber, vehicleKey: undefined }),
  );

  if (!hasData(reference) || defaultName) {
    return <>{returnItem.name}</>;
  }
  return <>{reference.name}</>;
};

const ReturnDialog = ({
  handleReject,
  isOpen,
  onClose,
  userContext,
  referencesToReturn,
  orderId,
  showResult,
}: {
  handleReject: () => void;
  isOpen: boolean;
  onClose: () => void;
  userContext: UserContext;
  referencesToReturn: OrderReturnItem[];
  orderId: string;
  showResult: () => void;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      title={
        <>
          <Flex justify={'center'}>
            <Text type={'h1'} disableGutter>
              {t('my_orders.details.return_request.confirm_return', 'Confirm return')}
            </Text>
          </Flex>
          <MarginBox mt={15} />
          <Flex justify={'center'}>
            <Icon IconComponent={ReplyIcon} color={theme.color.clear_blue} noPointer size={60} />
          </Flex>
        </>
      }
      onCancel={handleReject}
      open={isOpen}
      closable
      width={800}
      footer={
        <MarginBox mb={30} mx={30}>
          <Flex>
            <WhiteButton stretch onClick={handleReject}>
              {t('common.action.close', 'Close')}
            </WhiteButton>
            <MarginBox ml={30} />
            <YellowButton
              stretch
              onClick={() => {
                history.push(`${ROUTER_IN_PROGRESS_ORDERS}/${orderId}`);
                dispatch(resetReturnRequest(orderId));
                const returnRequest = mapReturnRequest(orderId, userContext, referencesToReturn);
                dispatch(sendEmailReturnRequestRequestSaga(returnRequest));
                showResult();
                onClose();
              }}
            >
              {t('common.action.confirm', 'Confirm')}
            </YellowButton>
          </Flex>
        </MarginBox>
      }
    >
      <Flex justify={'center'}>
        <STable
          bordered
          pagination={false}
          rowKey={(record) => record.referenceNumber + '_' + record.index}
          columns={[
            {
              title: (
                <Flex justify={'center'}>
                  <Text type={'h6'}>{t('catalog.reference_plural', 'References')}</Text>
                </Flex>
              ),
              dataIndex: 'referenceNumber',
              key: 'referenceNumber',
              render: function renderReference(referenceNumber: string, returnItem: OrderReturnItem) {
                return <ReferenceWithBrand returnItem={returnItem} />;
              },
            },
            {
              title: (
                <Flex justify={'center'}>
                  <Text type={'h6'}>{t('common.designation', 'Designation')}</Text>
                </Flex>
              ),
              dataIndex: 'name',
              key: 'name',
              render: function renderName(name: string, returnItem: OrderReturnItem) {
                return <ReferenceWithName returnItem={returnItem} />;
              },
            },
            {
              title: (
                <Flex justify={'center'}>
                  <Text type={'h6'}>{t('common.quantity_short_title', 'Qty')}</Text>
                </Flex>
              ),
              dataIndex: 'qtyToReturn',
              key: 'qtyToReturn',
            },
            {
              title: (
                <Flex justify={'center'}>
                  <Text type={'h6'}>{t('my_orders.details.return_request.reason', 'Reason')}</Text>
                </Flex>
              ),
              dataIndex: 'requestReason',
              key: 'requestReason',
              render: function renderRequestReason(requestReason: ReturnReasonType) {
                return requestReasonOptions(t).find((opts) => opts.value === requestReason)?.title;
              },
            },
          ]}
          dataSource={Array.isArray(referencesToReturn) ? referencesToReturn : []}
        />
      </Flex>
    </Modal>
  );
};

export default ReturnDialog;
