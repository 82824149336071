import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { PrintIcon } from 'assets/icons';
import { Dialog, Disclosure } from 'components/Dialog';
import Loader from 'components/Loader';
import {
  fetchPlateReferencesRequestSaga,
  getLastVehicle,
  getLastVehicleDetail,
  getMultiplePlateReferences,
  getPlatesFullImageKeys,
} from 'domains/catalog/Catalog.store';
import { MultiPlate } from 'domains/catalog/Catalog.types';
import { useFetchUrlImages } from 'domains/pictures/Pictures.requests';
import { DHReferenceLocal, getDHReferences, getStocksMap } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { getSignature } from 'domains/urlSignatures';
import { getDealerType, getTokenUser } from 'domains/user';
import { handlePrintPlate } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/PrintPlate/PrintPlatePdf';
/* eslint-disable max-len */
import { getFilteredAndSortedReferences } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferencesContainer';
import { Box, Checkbox, Flex, MarginBox } from 'UI';
import { getData, hasData, isLoading, NO_DATA } from 'utils';
import { Country } from 'utils/i18n/Country';

export interface IndexReferenceLocal {
  index: number;
  indexName?: string;
  referenceNumber: string;
  alternativeReferenceNumbers?: string[];
}

const MIN_PIC_SIZE = 100;

export const PrintPlateReferencesDialog = ({
  disclosure,
  multiplates,
  currentPlate,
  vehicleKey,
}: {
  disclosure: Disclosure;
  multiplates: NO_DATA | MultiPlate[];
  vehicleKey: string | undefined;
  currentPlate: string;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isHideAllReferences, setHideAllReferences] = useState(false);
  const [isPrintAllPlates, setPrintAllPlates] = useState(false);
  const [isPrintClicked, setIsPrintClicked] = useState(false);
  const multiplatesData = getData(multiplates);
  const multiplatesIsLoading = isLoading(multiplates);
  const plateIdsAll = (multiplates && multiplatesData)?.flatMap((x) => x.plateIds);
  const plateIds = isPrintAllPlates ? plateIdsAll : plateIdsAll?.filter((p) => p === currentPlate);
  const plateImages = useSelector((state: RootState) => getPlatesFullImageKeys(state, { plateIds, vehicleKey }));
  const imageKeys = plateImages.map((p) => p.imageKey ?? '');
  const vehicleBrand = useSelector(getLastVehicleDetail)?.vehicleBrand ?? 'OTHER';
  const plateImagesBase64 = useFetchUrlImages(imageKeys, 'DATAHUB');
  const signature = useSelector((state: RootState) => getSignature(state));
  const multiPlateReferences = useSelector((state: RootState) =>
    getMultiplePlateReferences(state, { plateIds, vehicleKey }),
  );
  const vehicle = useSelector(getLastVehicle);
  const dealerType = useSelector(getDealerType);
  const tokenUserCountry = useSelector(getTokenUser)?.country;
  const allMultiplateReferencesValue = multiPlateReferences?.values()
    ? Array.from(multiPlateReferences?.values()).flat()
    : undefined;
  const referenceNumbers = allMultiplateReferencesValue
    ?.map((r) => [getData(r)?.referenceNumber, ...(getData(r)?.alternativeReferenceNumbers ?? [])])
    .flat()
    .filter((f) => f) as string[] | [];
  const referenceNumbersLoading = allMultiplateReferencesValue
    ?.map((r) => [isLoading(r)])
    .flat()
    .some((f) => f === true);
  const mainReferences = useSelector((state: RootState) => getDHReferences(state, { vehicleKey, referenceNumbers }));

  useEffect(() => {
    if (vehicleKey && multiplatesData) {
      multiPlateReferences?.forEach((plateRefs, plateId) => {
        if (plateId !== currentPlate && !plateRefs) {
          dispatch(
            fetchPlateReferencesRequestSaga({
              plateId,
              vehicleKey,
            }),
          );
        }
      });
    }
  }, [
    disclosure,
    vehicleKey,
    dispatch,
    multiPlateReferences,
    plateIds,
    currentPlate,
    multiplatesData,
    referenceNumbersLoading,
    mainReferences,
  ]);

  const prices = useFetchPrices(referenceNumbers ?? []);
  const pricesLoaded = referenceNumbers
    ?.map((r) => {
      const p = prices.get(r);
      return p !== undefined && !isLoading(p);
    })
    .every((l) => l);
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers ?? [])) ?? [];
  const stocksLoaded = referenceNumbers
    ?.map((r) => {
      const p = stocks.get(r);
      return p !== undefined && !isLoading(p.searchStatus);
    })
    .every((l) => l);

  const allImagesLoaded = plateImages
    .map((i) => (plateImagesBase64.get(i.imageKey ?? '')?.length ?? 0) > MIN_PIC_SIZE)
    .every((l) => l === true);

  const plates = useMemo(
    () => {
      const getReferencesByCountry = (references: DHReferenceLocal[]) =>
        tokenUserCountry === Country.RO.key ? references.filter((r) => r.brand !== 'VALUE_PLUS') : references;

      const referencesToDisplay = (references: DHReferenceLocal[]) => {
        return hasData(vehicle) && hasData(vehicle?.vehicleDetail)
          ? getFilteredAndSortedReferences(
              getReferencesByCountry(references),
              dealerType ?? 'R2',
              vehicle.vehicleDetail,
              prices,
              stocks,
            )
          : references;
      };

      return (
        plateIds?.map((plateId) => {
          const refs = getData(multiPlateReferences?.get(plateId));
          const plateReferences =
            refs
              ?.map((r: IndexReferenceLocal) => {
                const refsFromPlate = [r.referenceNumber, ...(r.alternativeReferenceNumbers ?? [])]
                  .map((p) => mainReferences.find((m) => m.referenceNumber === p))
                  .filter((f) => f) as DHReferenceLocal[];
                return referencesToDisplay(refsFromPlate).map((rx) => {
                  return { ...rx, index: r.index, indexName: r.indexName ?? '' };
                });
              })
              .flat() ?? [];
          return {
            plateId,
            plateReferences,
            signature,
            imageUrl: plateImages.find((i) => i.plateId === plateId)?.imageKey,
            plateImage: plateImagesBase64.get(plateImages.find((i) => i.plateId === plateId)?.imageKey ?? ''),
          };
        }) ?? []
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plateIds, multiPlateReferences, mainReferences, plateImages, plateImagesBase64, signature, prices, stocks],
  );

  const complete =
    allImagesLoaded &&
    !multiplatesIsLoading &&
    referenceNumbersLoading === false &&
    mainReferences.length === referenceNumbers.length &&
    pricesLoaded &&
    stocksLoaded;

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;
    if (isPrintClicked && !complete) {
      timeoutId = setTimeout(() => {
        setIsPrintClicked(false);
      }, 20000);
    } else if (isPrintClicked && complete) {
      handlePrintPlate({
        date: new Date(),
        plates,
        printReferenceNumber: !isHideAllReferences,
        vehicleBrand,
      });
      setIsPrintClicked(false);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isPrintClicked, complete, isHideAllReferences, plates, vehicleBrand]);

  return (
    <Dialog
      disclosure={disclosure}
      title={t('catalog.print_plate', 'Print plate')}
      icon={PrintIcon}
      buttonsText={{ confirm: t('common.action.print', 'Print') }}
      isConfirmButtonDisabled={!complete}
      content={
        <Flex direction={'column'}>
          <MarginBox mt={30} />
          <Flex direction={'column'} align={'flex-start'} minWidth={300}>
            <Checkbox
              checked={isHideAllReferences}
              onChange={() => setHideAllReferences(!isHideAllReferences)}
              label={t('catalog.print_plate.hide_all_references', 'Hide all references')}
            />
            {(multiplatesData?.length ?? 0) > 1 && (
              <Checkbox
                checked={isPrintAllPlates}
                onChange={() => setPrintAllPlates(!isPrintAllPlates)}
                label={t('catalog.print_plate.print_all_plates', 'Print all plates')}
              />
            )}
          </Flex>
          <Box height={30}>{!complete && <Loader height={'10px'} />}</Box>
        </Flex>
      }
      handleConfirm={() => setIsPrintClicked(true)}
    />
  );
};
export default PrintPlateReferencesDialog;
