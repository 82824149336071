/* eslint-disable max-len */

import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { BrandsSearchEnginIcon, ChevronDownIcon, CloneIcon } from 'assets/icons';
import Loader from 'components/Loader';
import { IAMFulltextSearchResult } from 'domains/catalog/Catalog.types';
import { getIAMReferences, getStocksMap } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { SparePartsViewType } from 'domains/user';
import IAMPlateReferenceCard from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard';
import {
  getFilteredMotrioReferences,
  getFilteredOtherBrandsReferences,
  sortIAMReferences,
} from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainerUtils';
import { theme } from 'styles';
import { Box, CenteredSpin, Flex, Icon, MarginBox, Text } from 'UI';
import { getData, isLoading } from 'utils';
import { Header, OpennerWrapper } from './SearchResultSection.styled';

interface SearchResultSectionProps {
  searchResultGroup: IAMFulltextSearchResult;
  sparePartsView: SparePartsViewType;
}

interface EquivalentSectionProps {
  sparePartsView: SparePartsViewType;
  equivalentReferences: string[] | undefined;
  additionalEquivalencesLoading: boolean;
}

const SearchResultSection = ({ searchResultGroup, sparePartsView }: SearchResultSectionProps) => {
  const equivalentReferences = getData(searchResultGroup.equivalentReferences);
  return (
    <MarginBox mt={10}>
      <MainSection searchResultGroup={searchResultGroup} sparePartsView={sparePartsView} />
      <EquivalentSection
        sparePartsView={sparePartsView}
        equivalentReferences={equivalentReferences}
        additionalEquivalencesLoading={searchResultGroup.additionalEquivalencesLoading}
      />
    </MarginBox>
  );
};

const MainSection = ({ searchResultGroup, sparePartsView }: SearchResultSectionProps) => {
  const hasRefs = (searchResultGroup.mainReferences?.length ?? 0) > 0;
  return (
    <>
      <Header type={'main'} $isWide={hasRefs}>
        <Flex direction={'row'}>
          <Icon IconComponent={BrandsSearchEnginIcon} color={theme.color.brand} size={20} mr={15} />
          <Text type={'popover_title'}>{`${searchResultGroup.groupName?.toUpperCase()}`}</Text>
        </Flex>
      </Header>
      {hasRefs ? (
        <MarginBox mt={-25}>
          {searchResultGroup.mainReferences?.map((mainRef, index) => {
            return (
              <div key={index}>
                <IAMPlateReferenceCard
                  referenceNumber={mainRef}
                  sparePartsView={sparePartsView}
                  laborTimesWrapper={undefined}
                  isIamSearchResult
                />
              </div>
            );
          })}
        </MarginBox>
      ) : (
        <>{searchResultGroup.loading && <Loader height={'10vh'} size={'default'} />}</>
      )}
    </>
  );
};

const EquivalentSection = ({
  sparePartsView,
  equivalentReferences,
  additionalEquivalencesLoading,
}: EquivalentSectionProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const references = useSelector((state: RootState) =>
    getIAMReferences(state, { vehicleKey: undefined, referenceNumbers: equivalentReferences }),
  );
  const prices = useFetchPrices(equivalentReferences ?? []);
  const stocks = useSelector((state: RootState) => getStocksMap(state, equivalentReferences ?? []));

  const sortedReferences = useMemo(() => {
    const motrioReferences = sortIAMReferences(getFilteredMotrioReferences(references, prices, stocks), stocks);
    const otherBrandsReferences = sortIAMReferences(getFilteredOtherBrandsReferences(references), stocks);
    return [...motrioReferences, ...otherBrandsReferences].map((r) => r.referenceNumber);
  }, [prices, references, stocks]);

  const isAnyReferenceLoading = references.some((r) => isLoading(r.detailStatus));

  if (isAnyReferenceLoading || additionalEquivalencesLoading) return <CenteredSpin />;
  if (!equivalentReferences || equivalentReferences?.length === 0) return null;

  return (
    <>
      <Header type={'equivalent'} $isWide={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Icon IconComponent={CloneIcon} color={theme.color.brand_black} size={20} mr={15} />
        <Text type={'h5_bold'}>
          {t('catalog.search.iam.equivalent_products', 'Equivalent products ({{count}})', {
            count: equivalentReferences?.length ?? 0,
          })}
        </Text>
        <Flex direction={'row-reverse'}>
          <OpennerWrapper $isOpen={isOpen}>
            <Icon IconComponent={ChevronDownIcon} color={theme.color.brand_black} size={20} />
          </OpennerWrapper>
        </Flex>
      </Header>
      {isOpen && (
        <MarginBox mt={-25}>
          {sortedReferences.map((eqRef, index) => {
            return (
              <div key={index}>
                <IAMPlateReferenceCard referenceNumber={eqRef} sparePartsView={sparePartsView} isIamSearchResult />
              </div>
            );
          })}
        </MarginBox>
      )}
      <Box height={30} />
    </>
  );
};

export default SearchResultSection;
