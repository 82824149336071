import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getIAMTires } from 'domains/tires/Tire.store';
import { Flex, MarginBox, Text } from 'UI';
import { hasData } from 'utils';
import { IAMTiresContainer } from './IAMTires.styled';
import DifferentSizedSection from './TireSizesTables/DifferentSizedSection';
import SameSizedSection from './TireSizesTables/SameSizedSection';
import { useResetQuickAccessCategories } from '../../common/QuickAccess/QuickAccess';

const IAMTires = () => {
  const { t } = useTranslation();
  const iamTires = useSelector(getIAMTires);
  const [selectedTire, setSelectedTire] = useState<string | undefined>(undefined);

  useResetQuickAccessCategories();

  if (!hasData(iamTires)) {
    return <></>;
  }
  return (
    <IAMTiresContainer direction={'column'}>
      {iamTires.sameSizes.length > 0 && (
        <SameSizedSection iamTires={iamTires.sameSizes} selectedTire={selectedTire} setSelectedTire={setSelectedTire} />
      )}
      {iamTires.differentSizes.length > 0 && (
        <Flex direction={'column'}>
          <MarginBox mt={30} />
          <Text type={'h1_banner_light'}>
            {t('catalog.vehicle.tires.different.sizes', 'Different tires sizes FRT/BK')}
          </Text>
          <MarginBox mt={30} />
          <DifferentSizedSection
            iamTires={iamTires.differentSizes}
            selectedTire={selectedTire}
            setSelectedTire={setSelectedTire}
          />
        </Flex>
      )}
      <MarginBox mt={600} />
    </IAMTiresContainer>
  );
};

export default IAMTires;
