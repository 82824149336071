import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { RootState } from 'app/AppStore';
import {
  fetchVehicleCategoryImagesRequestSaga,
  getExplodedTree,
  getLastSearchedVehicleKey,
} from 'domains/catalog/Catalog.store';
import { STORED_CATEGORY_SVG } from 'pages/CatalogPage/DH/CarPartGroupsSection/CarPartGroupItem/CarPartGroupSvg/CarPartGroupSvg';
import { hasData } from 'utils';
import { PlateImage } from './Catalog.types';

export function useDecodePlateImage(
  plateImage: PlateImage | undefined,
  imageBase64: string | undefined,
): string | JSX.Element | undefined {
  const plateKey = plateImage?.imageKey;
  const hasImageBase64 = imageBase64 !== undefined;
  return useMemo(() => {
    if (!hasImageBase64) {
      return imageBase64;
    }
    const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
    if (!base64Regex.test(imageBase64)) {
      return imageBase64;
    }
    const svg = atob(imageBase64);
    const divWithSvg = document.createElement('div');
    divWithSvg.innerHTML = svg;
    divWithSvg.querySelectorAll('polygon')?.forEach((polygon) => polygon.remove());
    // keep only root react element
    const image = divWithSvg && parse(divWithSvg.innerHTML.toString());
    return image && Array.isArray(image) ? image.find((el) => React.isValidElement(el)) : image;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plateKey, hasImageBase64]);
}

export function decodeCategoryImage(imageBase64: string | undefined): string | JSX.Element | undefined {
  if (!hasData(imageBase64)) {
    return imageBase64;
  }
  const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/;
  if (!base64Regex.test(imageBase64)) {
    return imageBase64;
  }
  const svg = atob(imageBase64);
  // keep only root react element
  const image = svg && parse(svg);
  return image && Array.isArray(image) ? image.find((el) => React.isValidElement(el)) : image;
}

export function useDecodeImage(imageBase64: string | undefined): string | JSX.Element | undefined {
  return useMemo(() => decodeCategoryImage(imageBase64), [imageBase64]);
}

export const useFetchCategoryImages = (): void => {
  const dispatch = useDispatch();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleExplodedViewsRawData = useSelector((state: RootState) => getExplodedTree(state, vehicleKey))?.data;

  useEffect(() => {
    if (hasData(vehicleExplodedViewsRawData)) {
      const categories: { imageKey: string; nodeId: string }[] = vehicleExplodedViewsRawData
        ?.filter((item) => !STORED_CATEGORY_SVG.includes(item.nodeId) && item.imageKey)
        .map((it) => {
          return { imageKey: it.imageKey, nodeId: it.nodeId };
        }) as { imageKey: string; nodeId: string }[];
      dispatch(fetchVehicleCategoryImagesRequestSaga({ categories }));
    }
  }, [dispatch, vehicleExplodedViewsRawData]);
};
