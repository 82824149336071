/* eslint-disable max-len */
import { OrderMKTPDeliveryMode } from '@1po/1po-bff-fe-spec/generated/basket/request/CreateOrderFromBasket';
import {
  ADD_ESTIMATE_KNOWN_REFERENCE_RESPONSE,
  ADD_ESTIMATE_TIRE_RESPONSE,
  AUTOCOMPLETE_BUNDLES_SEARCH_RESPONSE,
  ESTIMATE_CREATED_RESPONSE,
  GET_ESTIMATE_BY_ID_RESPONSE,
  GET_ESTIMATE_HISTORY_RESPONSE,
  GET_ESTIMATE_ID_TO_DELETE_RESPONSE,
  GET_ESTIMATE_ID_TO_HIDE_RESPONSE,
  GET_ESTIMATE_ID_TO_SHOW_RESPONSE,
  GET_ESTIMATE_SETTINGS_RESPONSE,
  GET_LATEST_ESTIMATE_RESPONSE,
  SEND_ESTIMATE_TO_DMS_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import {
  AddItemByReferenceNumber,
  VehicleDetail,
} from '@1po/1po-bff-fe-spec/generated/estimate/request/AddItemByReferenceNumber';
import { ItemType } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/ItemType';
import { LaborTime as CatalogLaborTime } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/LaborTime';
import { Reference as CatalogReference } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/Reference';
import { SearchType } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/SearchType';
import { Tire as CatalogTire } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/Tire';
import { ClientField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateClient';
import { FreeBundleField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateFreeBundle';
import { LaborTimeField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateLaborTime';
import { OtherItemField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateOtherItem';
import { ReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateReference';
import { EstimateSettingsFilter, SettingsField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateSettings';
import { ReferenceField as TireReferenceField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateTire';
import { VehicleField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateVehicle';
import { WasteRecyclingField } from '@1po/1po-bff-fe-spec/generated/estimate/request/UpdateWasteRecycling';
import { AutocompleteSearchBundlesResponse } from '@1po/1po-bff-fe-spec/generated/estimate/response/AutocompleteSearchBundlesResponse';
import { GetEstimate } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { GetEstimateHistory } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimateHistory';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { MyStoreBundle } from '@1po/1po-bff-fe-spec/generated/my_store/bundles/response/GetMyStoreBundlesResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { ADD_VEHICLE_REFERENCES_FROM_ESTIMATE } from 'domains/basket/Basket.types';
import { ERROR, FOUND, getData, LOADING, mergeObjectInMap, NO_DATA, NOT_FOUND, SEARCH_STATUS } from 'utils';
import {
  ADD_BUNDLES_FROM_AUTOCOMPLETE,
  ADD_BUNDLES_FROM_MY_STORE,
  ADD_CATALOG_LABOR_TIME,
  ADD_CATALOG_LABOR_TIME_BY_CODE,
  ADD_CATALOG_REFERENCE,
  ADD_CATALOG_REFERENCE_BY_REFERENCE_NUMBER,
  ADD_CATALOG_TIRE,
  ADD_CATALOG_TIRE_BY_REFERENCE_NUMBER,
  ADD_CUSTOM_FREE_BUNDLE,
  ADD_CUSTOM_LABOR_TIME,
  ADD_CUSTOM_REFERENCE,
  ADD_CUSTOM_SETTING,
  ADD_CUSTOM_TIRE,
  ADD_CUSTOM_WASTE_RECYCLING,
  ADD_OTHER_ITEM,
  ADD_REFERENCE_NUMBER,
  ADD_VEHICLE,
  BundleSubsectionSearch,
  CANCEL_ESTIMATE_DELETION,
  CREATE_ESTIMATE_FROM_DMS,
  DELETE_ESTIMATE,
  ESTIMATE_NAMESPACE,
  ESTIMATE_TAB,
  EstimateLocal,
  EstimateState,
  EstimateTabName,
  FILL_FROM_MAINTENANCE_PLAN,
  GET_ESTIMATE_BY_ID,
  GET_HISTORY,
  GET_LATEST_ESTIMATE,
  GET_SETTINGS,
  ORDER_FROM_ESTIMATE_B2B,
  ReferenceDiscountMargins,
  REMOVE_ALL_FREE_BUNDLES,
  REMOVE_ALL_LABOR_TIMES,
  REMOVE_ALL_OTHER_ITEMS,
  REMOVE_ALL_REFERENCES,
  REMOVE_ALL_TIRES,
  REMOVE_ALL_WASTE_RECYCLING,
  REMOVE_CUSTOM_SETTING,
  REMOVE_FREE_BUNDLE,
  REMOVE_KNOWN_REFERENCE_CONFIRMATION,
  REMOVE_LABOR_TIME,
  REMOVE_OTHER_ITEM,
  REMOVE_REFERENCE,
  REMOVE_TIRE,
  REMOVE_WASTE_RECYCLING,
  RequestReference,
  SEND_AUTOCOMPLETE_BUNDLES_SEARCH,
  SEND_ESTIMATE_TO_DMS,
  UPDATE_CLIENT,
  UPDATE_COUNTERPARTIES,
  UPDATE_FREE_BUNDLE,
  UPDATE_LABOR_TIME,
  UPDATE_OBSERVATIONS,
  UPDATE_OTHER_ITEM,
  UPDATE_REFERENCE,
  UPDATE_SETTINGS,
  UPDATE_TIRE,
  UPDATE_VEHICLE,
  UPDATE_WASTE_RECYCLING,
  UPDATE_WASTE_RECYCLING_SETTINGS,
} from './Estimate.types';

const getEstimate = (estimates: Map<string, EstimateLocal | NO_DATA>, estimateId?: string) => {
  if (!estimateId) {
    return undefined;
  }
  return getData(estimates.get(estimateId));
};
const getEstimateReferences = (estimate?: EstimateLocal) => estimate?.referenceSubsection.references ?? [];
const getEstimateLaborTimes = (estimate?: EstimateLocal) => estimate?.laborTimeSubsection.laborTimes ?? [];
const getEstimateFreeBundles = (estimate?: EstimateLocal) => estimate?.freeBundleSubsection.freeBundles ?? [];
const getEstimateWasteRecycling = (estimate?: EstimateLocal) => estimate?.wasteRecyclingSubsection.items ?? [];
const getEstimateTires = (estimate?: EstimateLocal) => estimate?.tireSubsection.references ?? [];
const getEstimateOtherItems = (estimate?: EstimateLocal) => estimate?.otherItemSubsection.otherItems ?? [];

const getNewEstimateData = (vehicle?: VehicleDetail): EstimateLocal => {
  const newEstimateId = uuidv4();
  // 5cc09cfd-8992-44d3-80cb-c1f7d7cf8eb5
  // 90126508_5cc09cfd-8992-44d3-80cb-c1f7d7cf8eb5
  const estimateVehicle =
    vehicle !== undefined
      ? {
          catalogSource: vehicle.catalogSource,
          vrn: vehicle.vrn,
          vin: vehicle.vin,
          vehicleKey: vehicle.vehicleKey,
          imageUrl: vehicle.imageUrl,
          vehicleBrand: vehicle.vehicleBrand,
          country: vehicle.country ?? '',
          model: vehicle.model ?? '',
          manufacturingDate: vehicle.manufacturingDate ?? '',
          mileage: '',
          parkNumber: '',
          administrationNumber: '',
          iamVehicle: vehicle?.iamVehicle,
          dataHubVehicle: vehicle?.dataHubVehicle,
          dialogAddToEstimateWasShown: true,
        }
      : undefined;
  return {
    ...initEstimate,
    estimateId: newEstimateId,
    vehicle: estimateVehicle,
  };
};

const initEstimate: EstimateLocal = {
  totalPrice: {
    priceVatExcluded: '0',
    vat: '0',
    priceVatIncluded: '0',
    discount: '0',
  },
  observations: undefined,
  dmsOrderNumber: undefined,
  vehicle: undefined,
  referenceSubsection: {
    totalPriceVatExcluded: '0',
    totalValidItems: 0,
    vat: '0',
    references: [],
  },
  laborTimeSubsection: {
    totalPriceVatExcluded: '0',
    vat: '0',
    totalValidItems: 0,
    laborTimes: [],
  },
  wasteRecyclingSubsection: {
    totalPriceVatExcluded: '0',
    vat: '0',
    totalValidItems: 0,
    items: [],
  },
  freeBundleSubsection: {
    totalPriceVatExcluded: '0',
    vat: '0',
    totalValidItems: 0,
    freeBundles: [],
  },
  tireSubsection: {
    totalPriceVatExcluded: '0',
    totalValidItems: 0,
    vat: '0',
    references: [],
  },
  otherItemSubsection: {
    totalPriceVatExcluded: '0',
    totalValidItems: 0,
    vat: '0',
    otherItems: [],
  },
  totalMargin: '0',
  totalMarginPercentage: '0',
  referenceDiscountMarginMap: new Map<string, ReferenceDiscountMargins>(),
  estimateId: '',
  creationDate: '',
  clientContact: {
    rrfCode: '',
    tresorCode: '',
    name: '',
    email: '',
    phoneNumber: '',
  },
  isHidden: false,
  dmsExportTime: '',
};

const initialBundleSearch: BundleSubsectionSearch = {
  fulltextAutocomplete: undefined,
  lastAutocompleteKey: undefined,
  fulltextSearchResult: undefined,
};

const initialState = (): EstimateState => {
  const newEstimateData = getNewEstimateData();

  return {
    currentEstimateId: newEstimateData.estimateId,
    estimates: new Map<string, EstimateLocal>([[newEstimateData.estimateId, newEstimateData]]),
    nonApplicableReferencesConfirmations: [],
    history: {
      estimates: [],
      cursor: undefined,
      hasMore: true,
      searchStatus: undefined,
    },
    settings: undefined,
    selectedTab: EstimateTabName,
    estimateLogo: 'motrio',
    freeBundleSubsectionSearch: initialBundleSearch,
    estimateOrderItemDetails: {
      orderMark: '',
      mktpDeliveryMode: 'STANDARD',
      referenceMarks: new Map<string, string>(),
      isUrgentDelivery: new Map<string, boolean>(),
    },
    estimateOrderStatus: undefined,
  };
};

// Saga actions
export const fetchEstimateByIdRequest = createAction<string>(GET_ESTIMATE_BY_ID);
export const fetchEstimateByIdResponse = createAction(GET_ESTIMATE_BY_ID_RESPONSE);
export const fetchDeleteEstimateByIdResponse = createAction(GET_ESTIMATE_ID_TO_DELETE_RESPONSE);
export const fetchHideEstimateByIdResponse = createAction(GET_ESTIMATE_ID_TO_HIDE_RESPONSE);
export const fetchShowEstimateByIdResponse = createAction(GET_ESTIMATE_ID_TO_SHOW_RESPONSE);
export const fetchLatestEstimateRequest = createAction(GET_LATEST_ESTIMATE);
export const fetchEstimateResponse = createAction(GET_LATEST_ESTIMATE_RESPONSE);
export const fetchEstimateCreatedResponse = createAction(ESTIMATE_CREATED_RESPONSE);
export const fetchEstimateHistoryRequest = createAction(GET_HISTORY);
export const fetchEstimateHistoryResponse = createAction(GET_ESTIMATE_HISTORY_RESPONSE);
export const addKnowReferenceByRefNumberResponse = createAction(ADD_ESTIMATE_KNOWN_REFERENCE_RESPONSE);
export const addTireByRefNumberResponse = createAction(ADD_ESTIMATE_TIRE_RESPONSE);

export const sendEstimateToDMSResponse = createAction(SEND_ESTIMATE_TO_DMS_RESPONSE);
export const addCatalogLaborTime = createAction<{
  laborTime: CatalogLaborTime;
  testLaborTimes?: CatalogLaborTime[];
}>(ADD_CATALOG_LABOR_TIME);
export const addCatalogReference = createAction<{
  reference: RequestReference;
}>(ADD_CATALOG_REFERENCE);
export const addCatalogTire = createAction<{
  reference: CatalogTire;
}>(ADD_CATALOG_TIRE);
export const addReferenceNumber = createAction<{
  referenceNumber: string;
  itemId?: string;
}>(ADD_REFERENCE_NUMBER);
export const addCatalogReferenceByReferenceNumber = createAction<{
  referenceNumber: string;
  itemId?: string;
  itemType: ItemType;
  searchType: SearchType;
}>(ADD_CATALOG_REFERENCE_BY_REFERENCE_NUMBER);
export const addCatalogTireByReferenceNumber = createAction<{
  referenceNumber: string;
  itemId?: string;
  itemType: ItemType;
  searchType: SearchType;
}>(ADD_CATALOG_TIRE_BY_REFERENCE_NUMBER);

export const addBundleFromMyStore = createAction<MyStoreBundle[]>(ADD_BUNDLES_FROM_MY_STORE);
export const addBundleFromAutocomplete = createAction<{ bundle: MyStoreBundle; itemId: string }>(
  ADD_BUNDLES_FROM_AUTOCOMPLETE,
);
export const addCatalogReferencesToBasket = createAction(ADD_VEHICLE_REFERENCES_FROM_ESTIMATE);
export const addCatalogLaborTimeByCode = createAction<string>(ADD_CATALOG_LABOR_TIME_BY_CODE);
export const addCustomReference = createAction(ADD_CUSTOM_REFERENCE);
export const addCustomLaborTime = createAction(ADD_CUSTOM_LABOR_TIME);
export const addCustomWasteRecycling = createAction(ADD_CUSTOM_WASTE_RECYCLING);
export const addCustomFreeBundle = createAction(ADD_CUSTOM_FREE_BUNDLE);
export const addCustomTire = createAction(ADD_CUSTOM_TIRE);
export const addOtherItem = createAction(ADD_OTHER_ITEM);
export const addCustomSetting = createAction(ADD_CUSTOM_SETTING);
export const removeCustomSetting = createAction<{ itemId: string }>(REMOVE_CUSTOM_SETTING);

export const updateReference = createAction<{
  itemId: string;
  parentItemId?: string;
  newValue: string;
  field: ReferenceField;
}>(UPDATE_REFERENCE);
export const updateLaborTime = createAction<{
  itemId: string;
  newValue: string;
  field: LaborTimeField;
}>(UPDATE_LABOR_TIME);
export const updateTire = createAction<{
  itemId: string;
  newValue: string;
  field: TireReferenceField;
}>(UPDATE_TIRE);
export const updateOtherItem = createAction<{
  itemId: string;
  newValue: string;
  field: OtherItemField;
}>(UPDATE_OTHER_ITEM);
export const updateFreeBundle = createAction<{
  itemId: string;
  newValue: string;
  field: FreeBundleField;
}>(UPDATE_FREE_BUNDLE);
export const updateWasteRecycling = createAction<{
  itemId: string;
  newValue: string;
  field: WasteRecyclingField;
}>(UPDATE_WASTE_RECYCLING);

export const removeReference = createAction<{ itemId: string; parentItemId?: string }>(REMOVE_REFERENCE);
export const removeLaborTime = createAction<string>(REMOVE_LABOR_TIME);
export const removeTire = createAction<string>(REMOVE_TIRE);
export const removeOtherItem = createAction<string>(REMOVE_OTHER_ITEM);
export const removeFreeBundle = createAction<string>(REMOVE_FREE_BUNDLE);

export const removeWasteRecycling = createAction<string>(REMOVE_WASTE_RECYCLING);

export const removeAllReferences = createAction(REMOVE_ALL_REFERENCES);
export const removeAllLaborTimes = createAction(REMOVE_ALL_LABOR_TIMES);
export const removeAllWasteRecycling = createAction(REMOVE_ALL_WASTE_RECYCLING);
export const removeAllFreeBundles = createAction(REMOVE_ALL_FREE_BUNDLES);
export const removeAllTires = createAction(REMOVE_ALL_TIRES);
export const removeAllOtherItems = createAction(REMOVE_ALL_OTHER_ITEMS);
export const removeKnownReferenceValidation = createAction<string>(REMOVE_KNOWN_REFERENCE_CONFIRMATION);
export const deleteEstimateRequest = createAction<{
  estimateId: string;
}>(DELETE_ESTIMATE);
export const cancelEstimateDeletion = createAction<{
  estimateId: string;
}>(CANCEL_ESTIMATE_DELETION);
export const addVehicle = createAction(ADD_VEHICLE);
export const updateVehicle = createAction<{
  newValue: string;
  field: VehicleField;
}>(UPDATE_VEHICLE);
export const updateClient = createAction<{
  newValue: string;
  field: ClientField;
}>(UPDATE_CLIENT);
export const updateCounterparties = createAction<{
  sellerTresorCode: string;
  buyerTresorCode: string;
}>(UPDATE_COUNTERPARTIES);
export const updateObservations = createAction<string>(UPDATE_OBSERVATIONS);
export const fetchSettingsRequest = createAction(GET_SETTINGS);
export const fetchSettingsResponse = createAction(GET_ESTIMATE_SETTINGS_RESPONSE);
export const updateSettings = createAction<{
  newValue: string;
  field: SettingsField;
  filters?: EstimateSettingsFilter;
}>(UPDATE_SETTINGS);
export const updateWasteRecyclingSettings = createAction<{
  itemId: string;
  newValue: string;
  field: WasteRecyclingField;
}>(UPDATE_WASTE_RECYCLING_SETTINGS);
export const createEstimateFromDMSRequest = createAction<{
  orderNumber: string;
}>(CREATE_ESTIMATE_FROM_DMS);
export const sendEstimateToDMSRequest = createAction(SEND_ESTIMATE_TO_DMS);

export const fillEstimateFromMaintenancePlan = createAction<{
  vehicleDetail: VehicleDetail;
  laborTimes?: CatalogLaborTime[];
  references?: CatalogReference[];
}>(FILL_FROM_MAINTENANCE_PLAN);

export const sendAutocompleteBundlesRequest = createAction<{
  queryString: string;
}>(SEND_AUTOCOMPLETE_BUNDLES_SEARCH);

export const sendAutocompleteBundlesResponse = createAction(AUTOCOMPLETE_BUNDLES_SEARCH_RESPONSE);
export const orderFromEstimateB2BRequest = createAction<{ estimateId: string }>(ORDER_FROM_ESTIMATE_B2B);

// Slice
const slice = createSlice({
  name: ESTIMATE_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: initialState,
    setEstimateHistoryResponse: (state, action: PayloadAction<GetEstimateHistory>) => {
      const { estimates, cursor, hasMore } = action.payload;

      const estimateIds = state.history.estimates.map((estimate) => estimate.estimateId);

      const newEstimates = estimates
        .filter((e) => !estimateIds.includes(e.estimateId))
        .map((e) => {
          return { ...e, isHidden: false };
        });

      state.history.cursor = cursor;
      state.history.searchStatus = FOUND;
      state.history.estimates = state.history.estimates.concat(newEstimates);
      state.history.hasMore = hasMore;
    },
    setHistoryEstimate: (state, action: PayloadAction<GetEstimate>) => {
      const estimate = action.payload.estimate;

      const historyIndex = state.history.estimates.findIndex(
        (historyEstimate) => historyEstimate.estimateId === estimate.estimateId,
      );
      if (historyIndex !== -1) {
        state.history.estimates[historyIndex] = {
          ...state.history.estimates[historyIndex],
          clientName: estimate.clientContact.name,
          clientRrfCode: estimate.clientContact.rrfCode,
          vehicle: estimate.vehicle,
        };
      }
    },
    setEstimateResponse: (state, action: PayloadAction<GetEstimate>) => {
      const estimate = action.payload.estimate;

      if (state.currentEstimateId === estimate.estimateId.substring(estimate.estimateId.length - 36)) {
        state.estimates.delete(state.currentEstimateId);
        state.currentEstimateId = estimate.estimateId;
      }
      mergeObjectInMap(state.estimates, estimate.estimateId, {
        ...estimate,
        isHidden: false,
        dmsExportTime: estimate.dmsExportTime,
        referenceDiscountMarginMap: new Map<string, ReferenceDiscountMargins>(),
      });
    },
    resetSellerBuyerInfo: (state, action: PayloadAction<string>) => {
      const estimate = getData(state.estimates.get(action.payload));
      if (!estimate) {
        return;
      }

      mergeObjectInMap(state.estimates, action.payload, {
        ...estimate,
        garageId: undefined,
        clientContact: { name: '', phoneNumber: '', email: '' },
      });
    },
    setCurrentEstimateId: (state, action: PayloadAction<string>) => {
      state.currentEstimateId = action.payload;
    },
    setOrderEstimateStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.estimateOrderStatus = action.payload;
    },
    setCurrentEstimateIdLoading: (state, action: PayloadAction<string>) => {
      state.estimates.set(action.payload, LOADING);
      state.currentEstimateId = action.payload;
    },
    setEstimateDataStatus: (state, action: PayloadAction<{ estimateId: string; status: NO_DATA }>) => {
      const { estimateId, status } = action.payload;
      state.estimates.set(estimateId, status);
    },
    setEstimateHistorySearchStatus: (state, { payload }: PayloadAction<SEARCH_STATUS>) => {
      state.history.searchStatus = payload;
      if (payload === NOT_FOUND || payload === ERROR) {
        state.history.hasMore = false;
      }
    },
    setEstimateHistorySearch: (state, { payload }: PayloadAction<string | undefined>) => {
      state.history.search = payload;
    },
    resetEstimateHistory: (state) => {
      state.history.cursor = undefined;
      state.history.estimates = [];
      state.history.hasMore = false;
    },
    createNewEstimate: (state, action: PayloadAction<{ vehicle: VehicleDetail | undefined }>) => {
      const { vehicle } = action.payload;
      const newEstimateData = getNewEstimateData(vehicle);

      state.currentEstimateId = newEstimateData.estimateId;
      state.estimates.set(newEstimateData.estimateId, newEstimateData);
      state.nonApplicableReferencesConfirmations = [];
    },
    addReferenceForValidation: (state, action: PayloadAction<AddItemByReferenceNumber>) => {
      state.nonApplicableReferencesConfirmations.push(action.payload.referenceNumber);
    },
    removeReferenceForValidation: (state, action: PayloadAction<string>) => {
      state.nonApplicableReferencesConfirmations = state.nonApplicableReferencesConfirmations.filter(
        (x) => x !== action.payload,
      );
    },
    setEstimateOrderMark: (state, action: PayloadAction<string>) => {
      state.estimateOrderItemDetails.orderMark = action.payload;
    },
    setEstimateOrderMktpDeliveryMode: (state, action: PayloadAction<OrderMKTPDeliveryMode>) => {
      state.estimateOrderItemDetails.mktpDeliveryMode = action.payload;
    },
    setEstimateOrderIsUrgent: (
      state,
      action: PayloadAction<{ referenceNumber: string; isUrgentDelivery: boolean }>,
    ) => {
      const { referenceNumber, isUrgentDelivery } = action.payload;
      state.estimateOrderItemDetails.isUrgentDelivery.set(referenceNumber, isUrgentDelivery);
    },
    setEstimateReferenceMark: (state, action: PayloadAction<{ referenceNumber: string; referenceMark: string }>) => {
      const { referenceNumber, referenceMark } = action.payload;
      state.estimateOrderItemDetails.referenceMarks.set(referenceNumber, referenceMark);
    },
    setEstimateReferenceisUrgentDelivery: (
      state,
      action: PayloadAction<{ referenceNumber: string; isUrgentDelivery: boolean }>,
    ) => {
      const { referenceNumber, isUrgentDelivery } = action.payload;
      state.estimateOrderItemDetails.isUrgentDelivery.set(referenceNumber, isUrgentDelivery);
    },
    setEstimateHidden: (state, action: PayloadAction<{ estimateId: string; isHidden: boolean }>) => {
      const { estimateId, isHidden } = action.payload;
      const historyIndex = state.history.estimates.findIndex(
        (historyEstimate) => historyEstimate.estimateId === estimateId,
      );
      if (historyIndex !== -1) {
        state.history.estimates[historyIndex] = {
          ...state.history.estimates[historyIndex],
          isHidden,
        };
      }
      const estimate = getData(state.estimates.get(estimateId));
      if (!estimate) {
        return;
      }
      mergeObjectInMap(state.estimates, estimate.estimateId, {
        ...estimate,
        isHidden,
      });
    },
    setEstimateRemoved: (state, action: PayloadAction<{ estimateId: string }>) => {
      const { estimateId } = action.payload;

      state.history.estimates = state.history.estimates.filter((estimate) => estimate.estimateId !== estimateId);
      state.estimates.delete(estimateId);
    },
    setEstimateLogo: (state, action: PayloadAction<string>) => {
      const logoId = action.payload;
      state.estimateLogo = state.estimateLogo === logoId ? undefined : logoId;
    },
    setSettings: (state, action: PayloadAction<Settings | NO_DATA>) => {
      state.settings = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<ESTIMATE_TAB>) => {
      state.selectedTab = action.payload;
    },
    setDialogAddToEstimateWasShown: (state, action: PayloadAction<boolean>) => {
      const estimateId = state.currentEstimateId;
      if (!estimateId) {
        return;
      }
      const vehicle = getData(state.estimates.get(estimateId))?.vehicle;
      if (vehicle !== undefined) {
        vehicle.dialogAddToEstimateWasShown = action.payload;
      }
    },
    setEstimateReferenceDiscountMargins: (
      state,
      action: PayloadAction<{
        estimateId: string;
        referenceNumber: string;
        marginDiscount: number;
      }>,
    ) => {
      const { estimateId, referenceNumber, marginDiscount } = action.payload;
      const estimate = getData(state.estimates.get(estimateId));
      if (!estimate) {
        return;
      }
      estimate.referenceDiscountMarginMap.set(referenceNumber, { marginDiscount });
      state.estimates.set(estimateId, estimate);
    },
    setBundleFulltextAutocomplete: (state, action: PayloadAction<AutocompleteSearchBundlesResponse>) => {
      if (action.payload.searchText !== state.freeBundleSubsectionSearch.lastAutocompleteKey) {
        return;
      }
      state.freeBundleSubsectionSearch = {
        ...state.freeBundleSubsectionSearch,
        fulltextAutocomplete: action.payload.bundles,
      };
    },
    setBundleLastAutocompleteKey: (state, action: PayloadAction<{ searchText: string }>) => {
      state.freeBundleSubsectionSearch = {
        //...state.freeBundleSubsectionSearch,
        fulltextAutocomplete: undefined,
        fulltextSearchResult: undefined,
        lastAutocompleteKey: action.payload.searchText,
      };
    },
    setBundleFulltextAutocompleteNoData: (
      state,
      action: PayloadAction<{
        searchText: string;
        status: NO_DATA;
      }>,
    ) => {
      state.freeBundleSubsectionSearch = {
        fulltextAutocomplete: undefined,
        fulltextSearchResult: undefined,
        lastAutocompleteKey: action.payload.searchText,
      };
    },
  },
});

// Actions
export const {
  setInitialState,
  setEstimateResponse,
  resetSellerBuyerInfo,
  setEstimateHistoryResponse,
  setHistoryEstimate,
  createNewEstimate,
  setEstimateHistorySearchStatus,
  setEstimateHistorySearch,
  resetEstimateHistory,
  setOrderEstimateStatus,
  setCurrentEstimateId,
  setCurrentEstimateIdLoading,
  setEstimateDataStatus,
  setEstimateLogo,
  setSettings,
  setEstimateOrderMktpDeliveryMode,
  removeReferenceForValidation,
  setEstimateHidden,
  setEstimateRemoved,
  addReferenceForValidation,
  setSelectedTab,
  setDialogAddToEstimateWasShown,
  setEstimateReferenceDiscountMargins,
  setBundleLastAutocompleteKey,
  setBundleFulltextAutocomplete,
  setBundleFulltextAutocompleteNoData,
  setEstimateReferenceMark,
  setEstimateOrderMark,
  setEstimateOrderIsUrgent,
} = slice.actions;

// Getters/Selectors
export const getCurrentEstimateId = createSelector(
  (state: RootState) => state.estimate.currentEstimateId,
  (currentEstimateId) => currentEstimateId,
);

export const getAllEstimates = createSelector(
  (state: RootState) => state.estimate.estimates,
  (estimates) => estimates,
);

export const getEstimateById = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => {
    if (!estimateId) {
      return undefined;
    }
    return estimates.get(estimateId);
  },
);

export const getEstimateSequenceNumber = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.sequenceNumber,
);

const getCurrentEstimate = createSelector(
  getCurrentEstimateId,
  (state: RootState) => state.estimate.estimates,
  (currentId, estimates) => getEstimate(estimates, currentId),
);

export const getEstimateVehicle = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.vehicle,
);

export const getEstimateVehicleKey = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.vehicle?.vehicleKey,
);

export const getEstimateVehicleDialogAddToEstimateWasShown = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.vehicle?.dialogAddToEstimateWasShown,
);

export const getCurrentEstimateVehicle = createSelector(getCurrentEstimate, (estimate) => estimate?.vehicle);

export const getEstimateClient = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.clientContact ?? initEstimate.clientContact,
);

export const getReferences = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateReferences(getEstimate(estimates, estimateId)),
);

export const getReferencesWithValidPrices = createSelector(
  (state: RootState, estimateId: string) => getReferences(state, estimateId),
  (references) => references.filter((row) => row.unitPrice && row.vatPercentage),
);

export const getReferencesLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateReferences(getEstimate(estimates, estimateId)).length,
);

export const getPendingReferenceValidations = createSelector(
  (state: RootState) => state.estimate.nonApplicableReferencesConfirmations,
  (validations) => validations,
);

export const getCatalogReferences = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateReferences(getEstimate(estimates, estimateId)).filter((ref) => !ref.isCustom),
);

export const getCatalogReferencesLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) =>
    getEstimateReferences(getEstimate(estimates, estimateId)).filter((ref) => !ref.isCustom).length,
);

export const getLaborTimes = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateLaborTimes(getEstimate(estimates, estimateId)),
);
export const getEstimateCreatedBy = createSelector(
  (state: RootState) => state.estimate.history.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => estimates.find((e) => e.estimateId === estimateId),
);

export const getLaborTimesWithValidPrices = createSelector(
  (state: RootState, estimateId: string) => getLaborTimes(state, estimateId),
  (laborTimes) => laborTimes.filter((row) => row.pricePerHour && row.vatPercentage && row.numberOfHours),
);
export const getLaborTimesLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateLaborTimes(getEstimate(estimates, estimateId)).length,
);

export const getWasteRecycling = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateWasteRecycling(getEstimate(estimates, estimateId)),
);

export const getWasteRecyclingWithValidPrices = createSelector(
  (state: RootState, estimateId: string) => getWasteRecycling(state, estimateId),
  (wts) => wts.filter((row) => row.priceVatExcluded && row.vatPercentage),
);

export const getFreeBundlesWithValidPrices = createSelector(
  (state: RootState, estimateId: string) => getFreeBundles(state, estimateId),
  (wts) => wts.filter((row) => row.unitPrice),
);

export const getWasteRecyclingLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateWasteRecycling(getEstimate(estimates, estimateId)).length,
);

export const getFreeBundlesLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateFreeBundles(getEstimate(estimates, estimateId)).length,
);

export const getCatalogLaborTimes = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => getEstimateLaborTimes(getEstimate(estimates, estimateId)).filter((lt) => !lt.isCustom),
);

export const getTires = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateTires(getEstimate(estimates, estimateId)),
);

export const getTiresWithValidPrices = createSelector(
  (state: RootState, estimateId: string) => getTires(state, estimateId),
  (tires) => tires.filter((row) => row.unitPrice && row.vatPercentage),
);

export const getTiresLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateTires(getEstimate(estimates, estimateId)).length,
);

export const getCatalogTires = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateTires(getEstimate(estimates, estimateId)).filter((ref) => !ref.isCustom),
);

export const getCatalogTiresLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => getEstimateTires(getEstimate(estimates, estimateId)).filter((ref) => !ref.isCustom).length,
);

export const getOtherItems = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateOtherItems(getEstimate(estimates, estimateId)),
);
export const getFreeBundles = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateFreeBundles(getEstimate(estimates, estimateId)),
);
export const getOtherItemsWithValidPrices = createSelector(
  (state: RootState, estimateId: string) => getOtherItems(state, estimateId),
  (otherData) => otherData.filter((row) => row.unitPrice && row.vatPercentage),
);
export const getOtherItemsLength = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimateOtherItems(getEstimate(estimates, estimateId)).length,
);

export const getRowTablesCount = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => {
    const estimate = getEstimate(estimates, estimateId);
    return (
      getEstimateLaborTimes(estimate).length +
      getEstimateReferences(estimate).length +
      getEstimateFreeBundles(estimate).length +
      getEstimateTires(estimate).length +
      getEstimateOtherItems(estimate).length +
      getEstimateWasteRecycling(estimate).length
    );
  },
);

export const getValidRowTablesCount = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => {
    const estimate = getEstimate(estimates, estimateId);
    if (!estimate) return 0;
    return (
      (estimate.referenceSubsection.totalValidItems ?? 0) +
      (estimate.laborTimeSubsection.totalValidItems ?? 0) +
      (estimate.tireSubsection.totalValidItems ?? 0) +
      (estimate.freeBundleSubsection.totalValidItems ?? 0) +
      (estimate.otherItemSubsection.totalValidItems ?? 0) +
      (estimate.wasteRecyclingSubsection.totalValidItems ?? 0)
    );
  },
);

export const getRealEstimateReferencesCount = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => {
    const estimate = getEstimate(estimates, estimateId);
    if (!estimate) return 0;
    const realReferences = estimate.referenceSubsection.references.filter((ref) => !ref.isCustom);
    const realTires = estimate.tireSubsection.references.filter((ref) => !ref.isCustom);
    return realReferences.length + realTires.length;
  },
);

export const getEstimateTotalPrice = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.totalPrice ?? initEstimate.totalPrice,
);

export const getEstimateObservations = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.observations,
);

export const getEstimateReferenceSectionPriceVatExcl = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.referenceSubsection.totalPriceVatExcluded,
);

export const getEstimateReferenceSectionVat = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.referenceSubsection.vat,
);

export const getEstimateFreeBundlesSectionPriceVatExcl = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.freeBundleSubsection.totalPriceVatExcluded,
);

export const getEstimateFreeBundlesSectionVat = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.freeBundleSubsection.vat,
);

export const getTotalMargin = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.totalMargin,
);

export const getTotalMarginPercentage = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.totalMarginPercentage,
);

export const getEstimateLaborTimeSectionPriceVatExcl = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.laborTimeSubsection.totalPriceVatExcluded,
);

export const getEstimateLogo = createSelector(
  (state: RootState) => state.estimate.estimateLogo,
  (estimateLogo) => estimateLogo,
);

export const getEstimateLaborTimeSectionVat = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.laborTimeSubsection.vat,
);
export const getEstimateTireSectionPriceVatExcl = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.tireSubsection.totalPriceVatExcluded,
);

export const getEstimateTireSectionVat = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.tireSubsection.vat,
);

export const getEstimateOtherItemSectionPriceVatExcl = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.otherItemSubsection.totalPriceVatExcluded,
);

export const getEstimateOtherItemSectionVat = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.otherItemSubsection.vat,
);

export const getEstimateWasteRecyclingSectionPriceVatExcl = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.wasteRecyclingSubsection.totalPriceVatExcluded,
);

export const getEstimateWasteRecyclingSectionVat = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId: string) => estimateId,
  (estimates, estimateId) => getEstimate(estimates, estimateId)?.wasteRecyclingSubsection.vat,
);

export const getEstimateHistory = createSelector(
  (state: RootState) => state.estimate.history.estimates,
  (historyEstimates) => historyEstimates.filter((e) => !e.isHidden),
);

export const getEstimateHistoryCursor = createSelector(
  (state: RootState) => state.estimate.history.cursor,
  (cursor) => cursor,
);

export const getEstimateHistoryHasMore = createSelector(
  (state: RootState) => state.estimate.history.hasMore,
  (hasMore) => hasMore,
);

export const getEstimateHistorySearch = createSelector(
  (state: RootState) => state.estimate.history.search,
  (search) => search,
);

export const getEstimateHistorySearchStatus = createSelector(
  (state: RootState) => state.estimate.history.searchStatus,
  (searchStatus) => searchStatus,
);

export const getEstimateSettings = createSelector(
  (state: RootState) => state.estimate.settings,
  (settings) => settings,
);

export const getSelectedTab = createSelector(
  (state: RootState) => state.estimate.selectedTab,
  (tab) => tab,
);
export const getEstimateReferenceDiscountSumMargin = createSelector(
  (state: RootState) => state.estimate.estimates,
  (_state: RootState, estimateId?: string) => estimateId,
  (estimates, estimateId) => {
    const estimate = getEstimate(estimates, estimateId);
    if (!estimate) return undefined;

    const margins = Array.from(estimate.referenceDiscountMarginMap.values());
    return margins.reduce((acc, curr) => {
      return acc + curr.marginDiscount;
    }, 0);
  },
);

export const getEstimateOrderItemDetails = createSelector(
  (state: RootState) => state.estimate.estimateOrderItemDetails,
  (estimateOrderItemDetails) => estimateOrderItemDetails,
);
export const getEstimateOrderStatus = createSelector(
  (state: RootState) => state.estimate.estimateOrderStatus,
  (estimateOrderStatus) => estimateOrderStatus,
);

export const getBundleFulltextAutocomplete = createSelector(
  (state: RootState) => state.estimate.freeBundleSubsectionSearch,
  (query) => query?.fulltextAutocomplete,
);

export const getBundleFulltextSearchResult = createSelector(
  (state: RootState) => state.estimate.freeBundleSubsectionSearch,
  (query) => query?.fulltextSearchResult,
);

// Export reducer
export default slice.reducer;
