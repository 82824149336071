import React from 'react';
import { useTranslation } from 'react-i18next';
import { TechnicalCriteria } from 'pages/CatalogPage/IAM/VehicleTechnicalDetails/TechnicalCriteria';
import { MarginBox, Tab, Tabs, Text } from 'UI';

export function VehicleTechnicalDetails() {
  const { t } = useTranslation();
  return (
    <>
      <MarginBox mt={15} />
      <Text type={'h1_banner_light'}>
        {t('catalog.details.vehicle_technical_details', 'Vehicle technical details')}
      </Text>
      <Tabs type={'card'}>
        <Tab key={'1'} tab={t('catalog.details.technical_criteria', 'Technical criteria')}>
          <TechnicalCriteria />
        </Tab>
      </Tabs>
    </>
  );
}
