import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { ROUTER_COOKIES, ROUTER_PERSONAL_DATA, ROUTER_TERMS_AND_CONDITIONS } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { InfoIcon } from 'assets/icons';
import { InfoDialog, useDisclosure } from 'components/Dialog';
import { get750ChunkReferences, getChunkReferences, getOilLitersPerRef } from 'domains/appContext/AppContext.store';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from 'domains/basket/Basket.types';
import { getStocksMap } from 'domains/references';
import { refWithoutDepositRef } from 'domains/references/DepositReference';
import { getUserCountry } from 'domains/user';
import { Box, CenteredSpin, Flex, Link, SPACE_BETWEEN, Text, YellowButton } from 'UI';
import { FOUND, hasData, SearchData } from 'utils';
import { ReferenceMap } from 'utils/dataStructure/ReferenceMap';
import { textFormatter } from 'utils/format';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { Card, CheckoutCardWrapper, Divider } from './CheckoutCardSection.styled';

const MINIMUM_CHECKOUT_OIL_QUANTITY = 200;

const LinkText = ({ children, linkTo }: { children?: ReactNode; linkTo: string }) => (
  <Link to={linkTo} external newTab>
    <Text type={'light_14_black_65'} decoration={'underline'}>
      {children}
    </Text>
  </Link>
);

interface CheckoutCardProps {
  totalPriceVatIncluded: number | string;
  totalPriceVatExcluded: number | string;
  totalDiscount: number;
  totalVat: number | string;
  vehicles: VehicleLocal[];
  externalBaskets: ExternalBasketSectionLocal[];
  otherReferences?: ReferenceLocal[];
  currency: string;
  onCheckout: () => void;
  width?: number;
  loading?: boolean;
  disabledCheckout?: boolean;
  validationStep?: boolean;
  estimateTotalReferences?: number;
}

function allReferencesAreInR1Stock(
  refs: ReferenceLocal[],
  stocks: ReferenceMap<SearchData<ReferenceStock> | undefined>,
) {
  return refs.every((ref) => {
    const referenceStock = stocks.get(ref.referenceNumber);
    if (!hasData(referenceStock) || referenceStock.searchStatus !== FOUND || !referenceStock.data) {
      return false;
    }
    return referenceStock.data.warehouses.some((wh) => {
      return wh.type === 'LOCAL' && wh.confirmedQuantity >= ref.quantity;
    });
  });
}

const CheckoutCardSection = ({
  totalPriceVatIncluded,
  totalPriceVatExcluded,
  totalVat,
  vehicles,
  externalBaskets,
  otherReferences,
  currency,
  onCheckout,
  loading = false,
  disabledCheckout = false,
  validationStep,
  totalDiscount,
  estimateTotalReferences,
}: CheckoutCardProps) => {
  const { t } = useTranslation();
  const disclosure = useDisclosure();
  const chunkReferences = useSelector(getChunkReferences);
  const chunk750References = useSelector(get750ChunkReferences);

  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const country = userCountry?.toLowerCase() ?? browserCountry?.toLowerCase();

  const oilLiters = useSelector(getOilLitersPerRef);
  const { onOpen, onClose } = disclosure;
  const oilReferences =
    otherReferences?.filter(
      (ref) => chunkReferences.get(ref.referenceNumber) || chunk750References.includes(ref.referenceNumber),
    ) ?? [];
  const oilReferenceNumbers = oilReferences.map((ref) => ref.referenceNumber);

  const oilStocks = useSelector((state: RootState) => getStocksMap(state, oilReferenceNumbers ?? []));

  const itemsCount =
    vehicles?.reduce(
      (acc: number, next: VehicleLocal) => acc + refWithoutDepositRef(next?.references ?? []).length,
      0,
    ) +
    externalBaskets?.reduce(
      (acc: number, next: ExternalBasketSectionLocal) => acc + refWithoutDepositRef(next?.references ?? []).length,
      0,
    ) +
    (refWithoutDepositRef(otherReferences)?.length || 0);

  const isCheckoutForOilsAllowed = () => {
    if (oilReferences.length === 0 || allReferencesAreInR1Stock(oilReferences, oilStocks)) {
      return true;
    }

    const oilRefQtyTotal =
      oilReferences.reduce((total, ref) => {
        const literPerRef = oilLiters.get(ref.referenceNumber) ?? 1;
        return total + ref.quantity * literPerRef;
      }, 0) ?? 0;

    return oilRefQtyTotal >= MINIMUM_CHECKOUT_OIL_QUANTITY;
  };

  const handleCheckOut = () => {
    if (isCheckoutForOilsAllowed()) {
      onCheckout();
    } else {
      onOpen();
    }
  };

  return (
    <CheckoutCardWrapper>
      <InfoDialog
        title={'Action required'}
        handleConfirm={() => undefined}
        disclosure={disclosure}
        icon={InfoIcon}
        description={[
          t(
            'catalog.universal_products.basket.minimum_quantity.notification.first_line',
            'Minimum order quantity of 200L is not reached for Castrol Oil reference(s).',
          ),
          t(
            'catalog.universal_products.basket.minimum_quantity.notification.second_line',
            'Please adjust quantities and try again.',
          ),
        ]}
        content={
          <Flex minWidth={400} direction={'row-reverse'}>
            <YellowButton onClick={onClose}>
              <Flex minWidth={80} justify={'center'}>
                {t('common.action.confirm', 'Confirm')}
              </Flex>
            </YellowButton>
          </Flex>
        }
      />
      <Card dataCy={'table-total'}>
        <Flex direction={'row'} justify={SPACE_BETWEEN} align={'center'} padding={'19px 24px 19px 24px'}>
          <Text type={'h1_black'} disableGutter>
            {t('common.price.total_order', 'Total order')}
          </Text>
          <Text type={'light_14_black_45'}>
            {t('catalog.checkout_card.total.item2', '{{count}} items', {
              count: estimateTotalReferences ?? itemsCount,
            })}
          </Text>
        </Flex>
        <Divider />
        <Flex direction={'column'} gap={16} padding={24}>
          <Flex direction={'row'} justify={SPACE_BETWEEN}>
            <Text type={'lead'} dataCy={'total-vat-excl'}>{`${t(
              'common.price.total_VAT_excl',
              'Total VAT. Excl',
            )}:`}</Text>
            <Text type={'lead'}>{textFormatter.formatCurrency(totalPriceVatExcluded, currency)}</Text>
          </Flex>
          {totalDiscount > 0 && (
            <Flex direction={'row'} justify={SPACE_BETWEEN}>
              <Text type={'h6_dark_dim'} displayStyle={'error'}>
                {`${t('common.price.total_discount_VAT_excl', 'Total discount VAT. Excl')}:`}
              </Text>
              <Text type={'h6_dark_dim'} displayStyle={'error'}>
                {textFormatter.formatCurrency(totalDiscount, currency)}
              </Text>
            </Flex>
          )}
          <Flex direction={'row'} justify={SPACE_BETWEEN}>
            <Text type={'lead'} dataCy={'vat'}>
              {t('common.price.vat', 'VAT:')}
            </Text>
            <Text type={'lead'}>{textFormatter.formatCurrency(totalVat, currency)}</Text>
          </Flex>
        </Flex>
        <Divider />
        <Flex direction={'column'} gap={9} padding={'17px 24px 24px 24px'}>
          <Flex direction={'row'} justify={SPACE_BETWEEN} dataCy={'total-vat-incl'}>
            <Text type={'h2'} disableGutter displayStyle={'default'}>{`${t(
              'common.price.total_vat_incl',
              'Total VAT. Incl',
            )}:`}</Text>
            <Text type={'h2'} disableGutter displayStyle={'default'}>
              {textFormatter.formatCurrency(totalPriceVatIncluded, currency)}
            </Text>
          </Flex>
          <Flex direction={'row'} justify={'center'}>
            {loading ? (
              <CenteredSpin />
            ) : (
              <YellowButton
                onClick={handleCheckOut}
                disabled={disabledCheckout}
                dataCy={validationStep ? 'button-buy' : 'button-checkout'}
                shape={'semiRound'}
                size={'xlarge'}
                stretch
              >
                {!validationStep ? t('cart.action.checkout', 'Check-out') : t('cart.action.buy', 'Buy')}
              </YellowButton>
            )}
          </Flex>
        </Flex>
      </Card>
      <Text type={'light_14_black_65'}>
        <Trans i18nKey={'cart.legal_notice'}>
          {'By placing your order, you agree to the Rpartstore '}
          <LinkText linkTo={ROUTER_TERMS_AND_CONDITIONS(country)}>{'Terms and Conditions'}</LinkText>
          {'. Please see our '}
          <LinkText linkTo={ROUTER_PERSONAL_DATA(country)}>{'Privacy Notice'}</LinkText>
          {' and '}
          <LinkText linkTo={ROUTER_COOKIES(country)}>{'Cookies'}</LinkText>.
        </Trans>
      </Text>
      <Box height={50} />
    </CheckoutCardWrapper>
  );
};

export default CheckoutCardSection;
