/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { DHReferenceLocal } from 'domains/references';
import { usePrice } from 'domains/references/References.requests';
import { getCatalogReferenceView, SparePartsViewType, StandardView } from 'domains/user';
import CrossSellingSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/CrossSellingSection/CrossSellingSection';
import ReferenceCardWrapper from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardWrapper';
import { getData, getSearchData } from 'utils';

export interface MarketingReferenceCardProps {
  refLocal: DHReferenceLocal | undefined;
  vehicleBrand: VehicleBrandType;
  sparePartsView: SparePartsViewType;
  plateId?: string;
}

const MarketingReferenceCard = ({ refLocal, vehicleBrand, sparePartsView, plateId }: MarketingReferenceCardProps) => {
  const reference = getData(refLocal);
  const price = getSearchData(usePrice(reference?.referenceNumber));
  const catalogRefView = useSelector(getCatalogReferenceView);
  if (!reference) return null;

  return (
    <>
      <ReferenceCardWrapper
        referenceNumber={reference.referenceNumber}
        name={reference.name}
        referenceBrand={reference.brand}
        vehicleBrand={vehicleBrand}
        price={price}
        sparePartsView={sparePartsView}
        plateId={plateId}
        isApplicableToCurrentVehicle={reference.isApplicableToCurrentVehicle}
        linkedReferences={reference.linkedReferences ?? []}
        useCompactView
      />
      {catalogRefView === StandardView && (
        <CrossSellingSection
          mainRef={reference.referenceNumber}
          crossSellingReferences={reference.crossSelling}
          type={'catalog'}
        />
      )}
    </>
  );
};

export default MarketingReferenceCard;
