import React, { Dispatch, SetStateAction } from 'react';
import { TechnicalCriteriaType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/TechnicalCriteriaType';
import { AngleRightIcon } from 'assets/icons';
import {
  SCriteriaItem,
  SCriteriaItemArrow,
  SCriteriaItemLabel,
} from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteria.styled';
import { ItemElement } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenuAndContent';
import { Flex, Icon, MarginBox, Text } from 'UI';

function TechnicalCriteriaItem({
  item,
  setCurrentSelected,
  active,
}: {
  item: ItemElement;
  setCurrentSelected: Dispatch<SetStateAction<TechnicalCriteriaType | undefined>>;
  active: TechnicalCriteriaType;
}) {
  return (
    <SCriteriaItem
      onClick={() => {
        setCurrentSelected(item.type);
      }}
      isActive={item.type === active}
    >
      <MarginBox ml={15} />
      <Icon IconComponent={item.icon} color={'black'} />
      <MarginBox ml={15} />
      <SCriteriaItemLabel>
        <Text cursor={'pointer'} type={'lead'}>
          {item.label}
        </Text>
        <SCriteriaItemArrow isActive={item.type === active}>
          <Icon IconComponent={AngleRightIcon} size={20} color={'black'} />
        </SCriteriaItemArrow>
      </SCriteriaItemLabel>
    </SCriteriaItem>
  );
}

export function TechnicalCriteriaMenu({
  items,
  setCurrentSelected,
  active,
}: {
  items: ItemElement[];
  setCurrentSelected: Dispatch<SetStateAction<TechnicalCriteriaType | undefined>>;
  active: TechnicalCriteriaType;
}) {
  return (
    <Flex direction={'column'} minWidth={300} maxWidth={300}>
      {items.map((i, key) => (
        <TechnicalCriteriaItem key={key} item={i} setCurrentSelected={setCurrentSelected} active={active} />
      ))}
    </Flex>
  );
}
