import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ReferenceStock,
  WarehouseStockDetail,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { SpinSize } from 'antd-v5/lib/spin';
import { TFunction } from 'i18next';
import { RootState } from 'app/AppStore';
import {
  Ban,
  BanSmall,
  ExclamationTriangleIcon,
  PackageIcon,
  PackagesInStockIcon,
  TruckWithStopwatchIcon,
} from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { isDelayedStock } from 'components/StockInfo/DelayedStock';
import { isEmptyStock } from 'components/StockInfo/EmptyStock';
import PrivateComponent from 'composers/PrivateComponent';
import { getBasketReference } from 'domains/basket/Basket.store';
import { getStockInfo, MAX_QUANTITY_WITH_STOCKS } from 'domains/references';
import { UserRole } from 'domains/user';
import { theme, ThemeColorKeyType } from 'styles';
import { Box, CenterFlex, Icon, Spin, WithTooltip } from 'UI';
import { getData, SearchData } from 'utils';

const getIcon = (warehouses: WarehouseStockDetail[], t: TFunction, size?: number | undefined) => {
  const stockTypes = [...new Set(warehouses.map((w) => w.type))];
  const stockTypesWithoutOther = stockTypes.filter((stockType) => stockType !== 'OTHER');

  if (stockTypesWithoutOther.includes('LOCAL') && stockTypesWithoutOther.includes('MANUFACTURER')) {
    return (
      <WithTooltip title={t('catalog.tire.stock.multiple_stocks.icon_description', 'Multiple stock')}>
        <Icon IconComponent={PackagesInStockIcon} size={size ?? 20} background={theme.color['blue']} round noPointer />
      </WithTooltip>
    );
  }
  const type = stockTypes[0];

  const getIconComponent = () => {
    switch (type) {
      case 'LOCAL':
        return PackageIcon;
      case 'MANUFACTURER':
        return TruckWithStopwatchIcon;
      default:
        return BanSmall;
    }
  };

  const getIconDescription = () => {
    switch (type) {
      case 'LOCAL':
        return t('catalog.tire.stock.r1.icon_description', 'R1 stock');
      case 'MANUFACTURER':
        return t('catalog.tire.stock.manufacturer.icon_description', 'Manufacturer');
      default:
        return t('catalog.tire.stock.other.icon_description', 'Other');
    }
  };

  const getIconColor = (): ThemeColorKeyType => {
    switch (type) {
      case 'LOCAL':
        return 'selected';
      case 'MANUFACTURER':
        return 'cyan';
      case 'OTHER':
      case 'DELAYED':
      default:
        return 'warning';
    }
  };

  return (
    <WithTooltip title={getIconDescription()}>
      <Icon
        IconComponent={getIconComponent()}
        size={size ?? 20}
        background={theme.color[getIconColor()]}
        color={theme.color['white']}
        round
        noPointer
      />
    </WithTooltip>
  );
};

const ResolveMini = ({
  component,
  stock,
  size,
  spinSize,
}: {
  component: React.ReactNode;
  stock: SearchData<ReferenceStock> | undefined;
  size: number;
  spinSize?: SpinSize;
}) => {
  const { t } = useTranslation();

  return (
    <PrivateComponent
      render={() => (
        <DataContainer
          data={stock?.searchStatus}
          ErrorState={() => (
            <WithTooltip
              title={t('common.stock.backend_error', 'Stocks temporarily unavailable, please try again later.')}
            >
              <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.warning} size={size} noPointer />
            </WithTooltip>
          )}
          Loading={() => (
            <Box width={size} height={size}>
              <CenterFlex>
                <Spin size={spinSize || 'large'} />
              </CenterFlex>
            </Box>
          )}
          NotFound={() => <></>}
        >
          <Box width={size} height={size}>
            <CenterFlex>{component}</CenterFlex>
          </Box>
        </DataContainer>
      )}
      requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
    />
  );
};

interface TireStockInfoComponentProps {
  referenceNumber: string;
  size?: number;
  spinSize?: SpinSize;
}

const TireStockDisplay = ({ referenceNumber, size = 40, spinSize }: TireStockInfoComponentProps) => {
  const { t } = useTranslation();
  const stock = getData(useSelector((state: RootState) => getStockInfo(state, referenceNumber)));
  const basketReference = useSelector((state: RootState) =>
    getBasketReference(state, {
      referenceNumber,
      vehicleKey: undefined,
    }),
  );
  const warehouses = getData(stock?.data)?.warehouses;

  if (isEmptyStock(stock)) {
    return ResolveMini({
      component: (
        <WithTooltip title={t('catalog.reference.stock.out_of_stock.part_unavailable', 'Part actually unavailable')}>
          <Icon IconComponent={Ban} color={theme.color.error} size={size} noPointer />
        </WithTooltip>
      ),
      stock,
      size,
      spinSize,
    });
  }

  const moreThen999 = basketReference !== undefined && basketReference.quantity > MAX_QUANTITY_WITH_STOCKS;
  if (isDelayedStock(stock?.data) || moreThen999) {
    return ResolveMini({
      component: (
        <WithTooltip title={t('common.restocking', 'Restocking')}>
          <Icon IconComponent={Ban} color={theme.color.error} size={size} noPointer />
        </WithTooltip>
      ),
      stock,
      size,
      spinSize,
    });
  }

  const StockDisplay = () => (
    <>
      {warehouses && warehouses?.length > 0 ? (
        <>{getIcon(warehouses, t, size)}</>
      ) : (
        <Icon IconComponent={Ban} color={theme.color.error} size={size} noPointer />
      )}
    </>
  );

  return ResolveMini({
    component: <StockDisplay />,
    stock,
    size,
    spinSize,
  });
};

export default TireStockDisplay;
