import React, { useEffect, useState } from 'react';
import { TechnicalCriteriaType } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/model/TechnicalCriteriaType';
import { TechnicalInfoLocal } from 'domains/catalog/Catalog.types';
import { TechnicalCriteriaContent } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaContent';
import { TechnicalCriteriaMenu } from 'pages/CatalogPage/common/TechnicalCriteria/TechnicalCriteriaMenu';
import { Flex, IconType, MarginBox } from 'UI';

export interface ItemElement {
  label: string;
  icon: IconType;
  type: TechnicalCriteriaType;
  data: TechnicalInfoLocal[] | undefined;
}

export interface TechnicalCriteriaMenuAndContentI {
  items: ItemElement[];
  refreshCategories?: () => void;
}

export function TechnicalCriteriaMenuAndContent({ items, refreshCategories }: TechnicalCriteriaMenuAndContentI) {
  const [currentSelected, setCurrentSelected] = useState<TechnicalCriteriaType>();
  const item = items.find((itemElement) => itemElement.type === currentSelected);
  const enableReset = items
    .map((itemElement) => itemElement.data)
    .flat()
    .some((x) => !x?.enabled);

  useEffect(() => {
    if (items.length > 0 && !currentSelected) {
      setCurrentSelected(items[0].type);
    }
  }, [items, currentSelected]);

  if (item === undefined) {
    return null;
  }

  return (
    <Flex>
      <TechnicalCriteriaMenu items={items} setCurrentSelected={setCurrentSelected} active={item.type} />
      <MarginBox ml={45} />
      <TechnicalCriteriaContent item={item} refreshCategories={refreshCategories} enableReset={enableReset} />
    </Flex>
  );
}
