import { TargetPage } from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/InformationBanner';
import {
  Information,
  SaveInformation,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/SaveInformation';
import {
  InformationTemplate,
  SaveInformationTemplate,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/request/SaveInformationTemplate';
import {
  ROUTER_CART,
  ROUTER_CATALOG_DH,
  ROUTER_CATALOG_IAM,
  ROUTER_HOME,
  ROUTER_TIRES,
  ROUTER_UNIVERSAL_PRODUCTS,
} from 'app/AppRouter';
import { CreateInformationModel, CreateInformationTemplateModel } from 'domains/information/Information.types';
import { hasData, NO_DATA } from 'utils';

function getCorrectEndDate(publicationEnd: string | undefined) {
  let correctPublicationEnd = undefined;
  if (publicationEnd !== undefined) {
    const publicationEndLocal = new Date(publicationEnd);
    correctPublicationEnd = new Date(publicationEndLocal.setDate(publicationEndLocal.getDate() + 1)).toISOString();
  }
  return correctPublicationEnd;
}

export const getRoutesFromTargetPages = (targetPages: TargetPage[]): string[] => {
  return targetPages.map((targetPage) => {
    switch (targetPage) {
      case 'LANDING_PAGE':
      case 'HOME_PAGE':
        return ROUTER_HOME;
      case 'RENAULT_CATALOG':
        return ROUTER_CATALOG_DH;
      case 'OTHER_BRANDS_CATALOG':
        return ROUTER_CATALOG_IAM;
      case 'CART':
        return ROUTER_CART;
      // case 'ACCESSORIES':
      // case 'MERCHANDISING':
      //   todo: add order list?
      case 'UNIVERSAL_PRODUCTS':
        return ROUTER_UNIVERSAL_PRODUCTS;
      case 'TIRES':
        return ROUTER_TIRES;
      default:
        return '';
    }
  });
};

export const toInformationMessage = (informationModel: CreateInformationModel): SaveInformation => {
  const information: Information = {
    informationId: informationModel.informationId,
    textSections: informationModel.textSection,
    createdBy: informationModel.createdBy,
    created: new Date().toISOString(),
    targetAudience: informationModel.targetAudience ?? [],
    targetCountries: informationModel.targetCountries,
    targetPages: informationModel.targetPages,
    publicationStart: informationModel.publicationStart
      ? new Date(informationModel.publicationStart).toISOString()
      : '',
    publicationEnd: getCorrectEndDate(informationModel.publicationEnd) ?? '',
  };
  return {
    information,
  };
};

export const toCreateInformationModel = ({
  information,
}: {
  information: Information | NO_DATA;
}): CreateInformationModel | undefined => {
  if (!hasData(information)) {
    return undefined;
  }

  return {
    informationId: information.informationId,
    textSection: information.textSections,
    createdBy: information.createdBy,
    created: information.created,
    status: information.status,
    targetAudience: information.targetAudience,
    targetCountries: information.targetCountries,
    targetPages: information.targetPages,
    publicationStart: information.publicationStart,
    publicationEnd: information.publicationEnd,
  };
};

export const toInformationTemplateMessage = (
  informationModel: CreateInformationTemplateModel,
): SaveInformationTemplate => {
  const informationTemplate: InformationTemplate = {
    informationTemplateId: informationModel.informationId,
    textSections: informationModel.textSection,
    createdBy: informationModel.createdBy,
    created: new Date().toISOString(),
    targetAudience: informationModel.targetAudience ?? [],
    targetCountries: informationModel.targetCountries ?? [],
    targetPages: informationModel.targetPages,
  };
  return {
    informationTemplate,
  };
};
