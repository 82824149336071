import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import Filter from 'components/Filter';
import {
  FILTER_PRICES,
  getFiltersMap,
  getStockFilterOptions,
  isFilteredStocks,
  isNotInRange,
  RANGE_FILTER_TYPE,
  RangeFacetLocal,
} from 'components/Filter/Filter.types';
import FilterQuantity from 'components/Filter/FilterQuantity';
import FilterTags from 'components/Filter/FilterTags';
import { useFetchUniversalProducts } from 'domains/catalog/Catalog.requests';
import {
  getUniversalProductsFilter,
  resetUniversalProductsFilters,
  setUniversalProductsRangeFilter,
  setUniversalProductsTextFilter,
} from 'domains/catalog/Catalog.store';
import { getLoadedPrices, getStocksMap, ReferencePriceType } from 'domains/references';
import { useFetchPrices, useFetchReferencesStocks } from 'domains/references/References.requests';
import { GarageView, getSparePartsView } from 'domains/user';
import { isConnected } from 'domains/webSockets/WebSocket.store';
import ProductsSearch from 'pages/UniversalProductsPage/ProductsPage/ProductsSearch';
import UniversalProduct from 'pages/UniversalProductsPage/ProductsPage/UniversalProduct';
import { ErrorAlert, Flex, InfiniteScroll, MarginBox, useInfiniteScroll } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { getData, hasData, isLoading, NO_DATA } from 'utils';

const PAGING_SIZE = 15;

const ProductsContainer = ({ references }: { references: string[] }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isWsConnected = useSelector(isConnected);
  const [showPriceless, setShowPriceless] = useState<boolean>(true);
  const [isSearchFilterActive, setIsSearchFilterActive] = useState<boolean>(false);
  const productsMap = useFetchUniversalProducts(references);
  const productDetails = Array.from(productsMap.values());
  const isAnyReferenceLoading = Array.from(productDetails).some((r) => isLoading(r));
  const [filteredReferences, setFilteredReferences] = useState<string[]>(references);
  const prices = useSelector((state: RootState) => getLoadedPrices(state, filteredReferences));
  const loadedStocks = useSelector((state: RootState) => getStocksMap(state, filteredReferences));
  const sparePartsView = useSelector(getSparePartsView);
  const pricesRange: [number, number] | undefined = useMemo(() => {
    const pricesData = prices.map((p) => {
      const price = getData(p);
      return sparePartsView === GarageView
        ? Number(getData(price?.prices)?.garageView?.vatExcludedPrice ?? 0)
        : Number(getData(price?.prices)?.clientView?.recommendedPriceVatIncluded ?? 0);
    });
    const productPrices = pricesData.filter((p) => p > 0);
    return productPrices.length > 0 ? [Math.min(...productPrices), Math.max(...productPrices)] : undefined;
  }, [prices, sparePartsView]);
  const pricesFacetDefault: RangeFacetLocal = {
    id: FILTER_PRICES,
    type: RANGE_FILTER_TYPE,
    min: pricesRange ? pricesRange[0] : 0,
    max: pricesRange ? pricesRange[1] : 0,
    numberOfItems: filteredReferences.length ?? 0,
    active: true,
  };
  const [pricesFacet, setPricesFacet] = useState(pricesFacetDefault);
  const filters = useSelector(getUniversalProductsFilter);
  const [displayReferences, setDisplayReferences] = useState<string[]>(filteredReferences.slice(0, PAGING_SIZE));
  const { hasMore, loadMore, currentData } = useInfiniteScroll<string>({
    data: displayReferences,
    chunkSize: PAGING_SIZE,
  });
  const somePriceMissing = prices.some((p) => isLoading(p.prices));
  const [someStockMissingFirstLoad, setSomeStockMissingFirstLoad] = useState(true);
  const stocks = getData(useSelector((state: RootState) => getStocksMap(state, references)));
  const stockStatuses = useMemo(() => stocks && [...stocks.values()].map((s) => s?.searchStatus), [stocks]);
  const someStockMissing = stockStatuses?.some((sp) => isLoading(sp));
  const [stockFilterOptions, setStockFilterOptions] = useState(
    getStockFilterOptions('UNIVERSAL_PRODUCTS', !someStockMissing),
  );

  const handleSearch = useCallback(
    (searchQuery: string) => {
      if (isAnyReferenceLoading || searchQuery === '') {
        return;
      }
      const searchRegex = new RegExp(searchQuery, 'i');
      const filteredRefs = productDetails
        .filter((ref) => ref && hasData(ref) && ref.productName && searchRegex.test(ref.productName))
        .map((ref) => getData(ref)?.referenceNumber) as string[];
      setFilteredReferences(filteredRefs);
      setIsSearchFilterActive(true);
    },
    [isAnyReferenceLoading, productDetails],
  );

  const cancelSearch = useCallback(() => {
    setFilteredReferences(references);
    setIsSearchFilterActive(false);
  }, [references]);

  useFetchPrices(references);
  useFetchReferencesStocks(references);

  useEffect(() => {
    if (someStockMissingFirstLoad && stockStatuses && stockStatuses.length > 0) {
      setSomeStockMissingFirstLoad(stockStatuses && stockStatuses.some((sp) => !sp || isLoading(sp)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockStatuses]);

  useEffect(() => {
    setPricesFacet(pricesFacetDefault);
    // eslint-disable-next-line
  }, [somePriceMissing, filteredReferences]);

  useEffect(() => {
    if (!isWsConnected) {
      setSomeStockMissingFirstLoad(true);
    }
  }, [isWsConnected]);

  useEffect(() => {
    dispatch(resetUniversalProductsFilters());
    // eslint-disable-next-line
  }, [references]);

  useEffect(() => {
    const numberFilter = (item: { reference: string; prices?: ReferencePriceType | NO_DATA }) => {
      return Number(
        sparePartsView === GarageView
          ? getData(getData(item)?.prices)?.garageView?.vatExcludedPrice
          : getData(getData(item)?.prices)?.clientView?.recommendedPriceVatIncluded,
      );
    };
    const priceFilterRange = filters.rangeFilters.get('prices');
    const pricesInRange = prices
      .filter((price) => {
        const viewPrice = numberFilter(price);
        if (!priceFilterRange) {
          return true;
        }
        return !(
          isNotInRange([String(viewPrice)], { min: priceFilterRange.min, max: priceFilterRange.max }) ||
          (!hasData(price) && showPriceless)
        );
      })
      .map((p) => p.reference);
    const filteredItems = pricesInRange.filter((ref) => {
      const stock = loadedStocks.get(ref);
      const filterStocks = filters.textFilters.get('delivery');
      return filterStocks ? isFilteredStocks(stock, filters, t) : true;
    });
    setDisplayReferences(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filteredReferences, prices]);

  useEffect(() => {
    const updStockOptions = {
      ...stockFilterOptions,
      items: stockFilterOptions.items.map((sfo) => {
        const resultItems = references.filter((reference) => {
          const stock = stocks?.get(reference);
          const tfiTextFilter = new Map();
          tfiTextFilter.set('delivery', [sfo.label]);
          return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
        });
        return { ...sfo, numberOfItems: resultItems.length };
      }),
    };
    const updPricesFacet = {
      ...pricesFacet,
      numberOfItems: displayReferences.filter((ref) => stocks?.get(ref)).length,
    };

    setStockFilterOptions(updStockOptions);
    setPricesFacet(updPricesFacet);
    // eslint-disable-next-line
  }, [references, displayReferences]);

  useEffect(() => {
    const stocksFilterOptions = getStockFilterOptions('UNIVERSAL_PRODUCTS', true);
    const getItems = () => {
      return stocksFilterOptions.items.filter((soi) => {
        return (
          stocks &&
          [...stocks.values()].some((s) => {
            const soiTextFilter = new Map();
            soiTextFilter.set('delivery', [soi.label]);
            return isFilteredStocks(s, { textFilters: soiTextFilter, rangeFilters: new Map() }, t);
          })
        );
      });
    };
    const options = {
      ...stocksFilterOptions,
      items: getItems().map((sfo) => {
        const resultItems = displayReferences.filter((reference) => {
          const stock = stocks?.get(reference);
          const tfiTextFilter = new Map();
          tfiTextFilter.set('delivery', [sfo.label]);
          return isFilteredStocks(stock, { textFilters: tfiTextFilter, rangeFilters: new Map() }, t);
        });
        return { ...sfo, numberOfItems: resultItems.length };
      }),
    };
    setStockFilterOptions(options);
    // eslint-disable-next-line
  }, [someStockMissingFirstLoad, isWsConnected, someStockMissing]);

  return (
    <Flex direction={'column'}>
      <Flex>
        <Flex direction={'column'}>
          <FilterTags
            filters={filters}
            setTextFilters={(id, item) => dispatch(setUniversalProductsTextFilter({ id, item }))}
            setRangeFilters={(id, range) => dispatch(setUniversalProductsRangeFilter({ id, range }))}
          />
          <MarginBox mb={10} />
          {references.length !== 0 && (
            <ProductsSearch
              isSearchFilterActive={isSearchFilterActive}
              isAnyReferenceLoading={isAnyReferenceLoading}
              handleSearch={handleSearch}
              cancelSearch={cancelSearch}
            />
          )}
          <MarginBox mb={60} />
        </Flex>
        <Flex justify={'flex-end'}>
          <Filter
            filters={filters}
            textFilters={[stockFilterOptions]}
            rangeFilters={pricesRange ? [pricesFacet] : []}
            setTextFilters={(id, item) => dispatch(setUniversalProductsTextFilter({ id, item }))}
            setRangeFilters={(id, range) => dispatch(setUniversalProductsRangeFilter({ id, range }))}
            resetFilters={() => dispatch(resetUniversalProductsFilters())}
            loading={!pricesRange}
            facetsMap={getFiltersMap(t)}
            usePrice={{
              showPriceless,
              setShowPriceless,
            }}
            inline
            reversed
          />
        </Flex>
      </Flex>
      <MarginBox mb={-30}>
        <FilterQuantity numberOfProducts={displayReferences.length} />
      </MarginBox>
      <MarginBox mb={30} />
      <ViewTabs />
      <MarginBox mt={60}>
        {currentData.length === 0 && (
          <ErrorAlert
            message={t(
              'catalog.universal_products.filter.notfound',
              'Sorry, no match found for your research, please adjust your search criteria.',
            )}
          />
        )}
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
          <Flex gap={30} wrap={'wrap'} justify={'space-around'}>
            {currentData.map((ref) => (
              <UniversalProduct key={`universal-product-${ref}`} referenceNumber={ref} />
            ))}
          </Flex>
        </InfiniteScroll>
      </MarginBox>
    </Flex>
  );
};

export default ProductsContainer;
