import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import {
  fetchIAMTiresRequestSaga,
  fetchUniqueTireDimensionsRequestSaga,
  getTireBrandCategoriesMap,
  getTireSearchDimensions,
  tireBrandCategoriesRequestSaga,
} from 'domains/tires/Tire.store';
import { TireBrandDetailLocal, TireSearchDimensions } from 'domains/tires/Tire.types';
import { getUserRights, UserRole } from 'domains/user';
import { getData, hasData, hasUserAnyRight, SearchData } from 'utils';

export const useFetchIAMTires = (versionCode: string | undefined) => {
  const dispatch = useDispatch();
  const userRights = getData(useSelector(getUserRights));

  useEffect(() => {
    if (
      versionCode &&
      hasUserAnyRight(
        [UserRole.IAM_ACCESS, UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
        userRights,
      )
    ) {
      dispatch(fetchIAMTiresRequestSaga({ versionCode }));
    }
  }, [dispatch, versionCode, userRights]);
};

export const useFetchUniqueTireDimensions = (): TireSearchDimensions | undefined => {
  const dispatch = useDispatch();
  const tireSearchDimensions = useSelector(getTireSearchDimensions);
  const hasTireSearchDimensions = hasData(tireSearchDimensions);
  useEffect(() => {
    if (!hasTireSearchDimensions) {
      dispatch(fetchUniqueTireDimensionsRequestSaga());
    }
  }, [dispatch, hasTireSearchDimensions]);
  return tireSearchDimensions;
};

export const useFetchTireBrandCategories = (): SearchData<Map<string, TireBrandDetailLocal>> => {
  const dispatch = useDispatch();
  const brandCategoriesMap = useSelector((state: RootState) => getTireBrandCategoriesMap(state));

  useEffect(() => {
    if (!brandCategoriesMap.searchStatus) {
      dispatch(tireBrandCategoriesRequestSaga());
    }
  }, [dispatch, brandCategoriesMap.searchStatus]);
  return brandCategoriesMap;
};
