import React from 'react';
import { useSelector } from 'react-redux';
import { getUserCountry } from 'domains/user';
import { assistanceContactByUserCountry } from 'pages/AssistancePage/AssistanceLayout/AssistancePageFooter';
import { Image, Text } from 'UI';
import { getBrowserLanguage } from 'utils/i18n/localeDetector';
import { Content, Footer } from '../Assistance.styled';

const ConnectionIssuesFooter = () => {
  const userCountry = useSelector(getUserCountry);
  const browserCountry = getBrowserLanguage()?.getCountryCode();
  const country = userCountry ?? browserCountry;
  const assistanceContact = assistanceContactByUserCountry[country ?? ''];

  return (
    <Footer justify={'center'}>
      <Image alt={'assistance/assistance-footer.webp'} src={'assistance/assistance-footer.webp'} width={'100%'} />
      <Content direction={'column'} justify={'center'}>
        <Text type={'h1_banner_dark'}>Get in touch with our Techline</Text>
        <Text type={'card_title_dark'}>{assistanceContact?.phone ?? ''}</Text>
        <Text type={'card_title_dark'}>{assistanceContact?.email ?? ''}</Text>
      </Content>
    </Footer>
  );
};

export default ConnectionIssuesFooter;
