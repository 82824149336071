import { OrderDeliveryType } from '@1po/1po-bff-fe-spec/generated/basket/request/CreateOrderFromBasket';
import { ExpiredReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/basket/response/BasketResponse';
import { ExternalBasketSection } from '@1po/1po-bff-fe-spec/generated/basket/response/model/ExternalBasketSection';
import { OtherSection } from '@1po/1po-bff-fe-spec/generated/basket/response/model/OtherSection';
import { ReferenceErrorRow } from '@1po/1po-bff-fe-spec/generated/basket/response/model/ReferenceErrorRow';
import { Reference, VehicleSection } from '@1po/1po-bff-fe-spec/generated/basket/response/model/VehicleSection';
import { APP_NAMESPACE } from 'app/App.constants';
import { ModalStatusType } from 'components/FileUpload/paramsByState';
import { LOADING, SearchData } from 'utils';
import { ReferenceTradingDataDetail } from '../references';

export const BASKET_NAMESPACE = `${APP_NAMESPACE}/BASKET`;

export const FETCH_BASKET = `${BASKET_NAMESPACE}/FETCH_BASKET`;
export const ADD_VEHICLE_REFERENCES = `${BASKET_NAMESPACE}/ADD_VEHICLE_REFERENCES`;
export const ADD_TIRE_REFERENCE = `${BASKET_NAMESPACE}/ADD_TIRE_REFERENCE`;
export const ADD_OTHER_SECTION_REFERENCE = `${BASKET_NAMESPACE}/ADD_OTHER_SECTION_REFERENCE`;
export const ADD_OTHER_SECTION_REFERENCES = `${BASKET_NAMESPACE}/ADD_OTHER_SECTION_REFERENCES`;
export const ADD_VEHICLE_REFERENCES_FROM_ESTIMATE = `${BASKET_NAMESPACE}/ADD_VEHICLE_REFERENCES_FROM_ESTIMATE`;
export const ADD_REFERENCE_BY_REF_NUMBER = `${BASKET_NAMESPACE}/ADD_REFERENCE_BY_REF_NUMBER`;
export const UPDATE_REFERENCE_QUANTITY = `${BASKET_NAMESPACE}/UPDATE_REFERENCE_QUANTITY`;
export const REMOVE_SINGLE_REFERENCE = `${BASKET_NAMESPACE}/REMOVE_SINGLE_REFERENCE`;
export const REMOVE_REFERENCES = `${BASKET_NAMESPACE}/REMOVE_REFERENCES`;
export const SET_ORDER_MARK = `${BASKET_NAMESPACE}/SET_ORDER_MARK`;
export const SET_REFERENCE_MARK = `${BASKET_NAMESPACE}/SET_REFERENCE_MARK`;
export const SET_REFERENCE_URGENCY_FLAG = `${BASKET_NAMESPACE}/SET_REFERENCE_URGENCY_FLAG`;
export const SET_ALL_REFERENCE_URGENCY_FLAGS = `${BASKET_NAMESPACE}/SET_ALL_REFERENCE_URGENCY_FLAGS`;
export const REVERT_LAST_REMOVAL = `${BASKET_NAMESPACE}/REVERT_LAST_REMOVAL`;
export const UPLOAD_FILE_REFERENCES = `${BASKET_NAMESPACE}/UPLOAD_FILE_REFERENCES`;
export const ATTACH_OTHER_REFERENCE = `${BASKET_NAMESPACE}/ATTACH_OTHER_REFERENCE_TO_VEHICLE`;
export const ATTACH_OTHER_NON_APPLICABLE_REFERENCE = `${BASKET_NAMESPACE}/ATTACH_OTHER_NON_APPLICABLE_REFERENCE`;
export const REVERT_ATTACH_OTHER_REFERENCE = `${BASKET_NAMESPACE}/REVERT_ATTACH_OTHER_REFERENCE_TO_VEHICLE`;
export const NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS = `${BASKET_NAMESPACE}/NOTIFY_USER_ABOUT_EXPIRED_DISCOUNTS`;

export const STATUS_SUCCESS = 'success';
export const STATUS_MISSING_PRICE = 'missing_price';
export const STATUS_UNKNOWN_REFERENCE = 'unknown?price';
export const STATUS_NOT_IN_STOCK = 'not_in_stock';

export const REF_ATTACH_START = 'reference_other_attach_start';
export const REF_ATTACH_CHECKING = 'reference_compatibility_checking';
export const REF_ATTACH_NON_COMPATIBLE = 'reference_not_compatible';
export const REF_ATTACH_UNKNOWN = 'reference_compatibility_unknown';
export const REF_ATTACH_OK = 'reference_moved';

export const REFERENCE_QUANTITY_LIMIT = 999;

export interface BasketState {
  basketId: SearchData<string>;
  totalPriceVatIncluded?: number;
  totalPriceVatExcluded?: number;
  totalDiscount?: number;
  vehicles?: VehicleLocal[];
  otherSection: OtherSectionLocal;
  externalBaskets: ExternalBasketSectionLocal[];
  referenceTradingData: Map<string, ReferenceTradingDataDetail>;
  actionStatuses: Map<string, BasketActionStatus>;
  fileReferencesUploadStatus: FileReferencesUploadStatus;
  otherReferenceAttachStatus: Map<string, OtherReferenceAttachStatus>;
  expiredDiscountsReferences: ExpiredReferenceDiscount[];
}

export interface BasketActionStatus {
  reference: string;
  status:
    | typeof LOADING
    | typeof STATUS_SUCCESS
    | typeof STATUS_MISSING_PRICE
    | typeof STATUS_UNKNOWN_REFERENCE
    | typeof STATUS_NOT_IN_STOCK;
}

export type AttachStatusType =
  | typeof REF_ATTACH_NON_COMPATIBLE
  | typeof REF_ATTACH_OK
  | typeof REF_ATTACH_UNKNOWN
  | typeof REF_ATTACH_CHECKING
  | typeof REF_ATTACH_START;

export interface OtherReferenceAttachStatus {
  vehicleKey: string;
  reference: string;
  type: AttachStatusType;
}

export interface FileReferencesUploadStatus {
  modalStatus: ModalStatusType;
  isModalOpen: boolean;
  errorRows: ReferenceErrorRow[];
}

export interface ReferenceLocal extends Reference {
  isSelected?: boolean;
}

export interface VehicleLocal extends VehicleSection {
  references: ReferenceLocal[];
  isSelected?: boolean;
  paymentMethod?: string;
  deliveryAddress?: OrderDeliveryType;
  instructions?: string;
  note?: string;
}

export interface OtherSectionLocal extends OtherSection {
  references: ReferenceLocal[];
  selected?: boolean;
  paymentMethod?: string;
  deliveryAddress?: OrderDeliveryType;
  instructions?: string;
  note?: string;
}

export interface ExternalBasketSectionLocal extends ExternalBasketSection {
  references: ReferenceLocal[];
  isSelected?: boolean;
  paymentMethod?: string;
  deliveryAddress?: OrderDeliveryType;
  instructions?: string;
  note?: string;
}

export interface CartReferenceErrorRow extends Omit<ReferenceErrorRow, 'rowNumber'> {
  rowNumber: number | undefined;
}
