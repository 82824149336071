/* eslint-disable max-len */
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReferencePlateLine } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { ReferenceStockTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { RootState } from 'app/AppStore';
import { getBasketOtherSection } from 'domains/basket/Basket.store';
import { TIRE } from 'domains/catalog/Catalog.types';
import {
  fetchCrossSellingReferencesRequestSaga,
  fetchPlatesForReferenceRequestSaga,
  fetchReferencesDiscountsRequestSaga,
  fetchReferencesPriceRequestSaga,
  fetchReferencesRepairPriceRequestSaga,
  fetchReferencesStocksRequestSaga,
  fetchReuseStocksRequestSaga,
  getDHReference,
  getDHReferences,
  getDHReferencesMap,
  getDiscountStatuses,
  getIAMReferencesMap,
  getPricesMap,
  getReferenceSources,
  getReferenceTypes,
  getRepairPricesMap,
  getReuseStocksMap,
  getStocksMap,
} from 'domains/references/References.store';
import { ReferencePriceType, REUSE_NODE_IDS } from 'domains/references/References.types';
import { getTireSetParam } from 'domains/tires/Tire.store';
import { getData, hasData, SearchData } from 'utils';
import { ReferenceMap } from 'utils/dataStructure/ReferenceMap';
import { Country } from 'utils/i18n/Country';
import { getTradingProfile, getUserContext, getUserCountry } from '../user';
/* eslint-enable max-len */

export const useFetchCrossSellingReferences = (references: string[], vehicleKey: string | undefined): void => {
  const dispatch = useDispatch();
  const allLoadedReferences = useSelector((state: RootState) =>
    getDHReferences(state, { referenceNumbers: references, vehicleKey }),
  );
  const relevantLoadedReferences = allLoadedReferences.filter((r) => references.includes(r.referenceNumber));
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const requestReferences = references.filter((ref) => {
    const loadedCrossSells = relevantLoadedReferences.find((loadedRef) => loadedRef.referenceNumber === ref)
      ?.crossSelling;
    return !hasData(loadedCrossSells) || loadedCrossSells.length === 0;
  });

  const referencesJson = JSON.stringify(references);
  useEffect(() => {
    if (
      isTradingProfileComplete &&
      requestReferences.length > 0 &&
      relevantLoadedReferences.length === references.length
    ) {
      dispatch(fetchCrossSellingReferencesRequestSaga({ references: requestReferences, vehicleKey }));
    }
    // eslint-disable-next-line
  }, [dispatch, referencesJson, isTradingProfileComplete, relevantLoadedReferences.length, vehicleKey]);
};

export const usePrice = (
  referenceNumber?: string,
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): SearchData<ReferencePriceType> => {
  const tradingProfile = useSelector(getTradingProfile);
  const prices = useFetchPrices([`${referenceNumber}`], undefined, sellerTresorCode, buyerTresorCode);

  const price = prices.get({
    referenceNumber: referenceNumber ?? '',
    sellerTresorCode: sellerTresorCode ?? tradingProfile?.sellerId ?? '',
    buyerTresorCode: buyerTresorCode ?? tradingProfile?.buyerId ?? '',
  });

  // eslint-disable-next-line
  return useMemo(() => price ?? { searchStatus: undefined }, [price?.data, price?.searchStatus]);
};

export const useFetchPrices = (
  referenceNumbers: string[],
  vehicleKey?: string,
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): ReferenceMap<SearchData<ReferencePriceType>> => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const userContext = useSelector(getUserContext);

  const btc = buyerTresorCode ?? tradingProfile?.buyerId ?? '';
  const stc = sellerTresorCode ?? tradingProfile?.sellerId ?? '';

  const prices = useSelector((state: RootState) => getPricesMap(state, referenceNumbers, stc, btc));
  const references = useSelector((state: RootState) => getDHReferencesMap(state, { referenceNumbers, vehicleKey }));
  const iamReferences = useSelector((state: RootState) => getIAMReferencesMap(state, { referenceNumbers, vehicleKey }));

  useEffect(() => {
    const missingReferenceNumbers = Array.from(prices.entries())
      .filter(([_, value]) => value == undefined || value.data === undefined || value.searchStatus == undefined)
      .map(([key, _]) => key.referenceNumber);

    const referencesToFetch = missingReferenceNumbers.map((r) => {
      return {
        referenceNumber: r,
        commercialFamily: getData(references.get(r))?.familyCode ?? '',
        supplierCode: getData(iamReferences.get(r))?.supplierCode,
        origin: getData(iamReferences.get(r))?.origin,
        referenceSource: getData(iamReferences.get(r))?.referenceSource,
        sellerTresorCode: stc,
        buyerTresorCode: btc,
      };
    });

    if (isTradingProfileComplete && referencesToFetch.length > 0 && stc && btc) {
      dispatch(
        fetchReferencesPriceRequestSaga({
          sellerTresorCode: stc,
          buyerTresorCode: btc,
          references: referencesToFetch,
          userContext: userContext,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referenceNumbers, stc, btc]);

  return prices;
};

export const useFetchDiscounts = (references: ReferenceTradingDataRequestDetail[]): void => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const referenceNumbers = references.map((r) => r.referenceNumber);
  const discounts = useSelector((state: RootState) => getDiscountStatuses(state, referenceNumbers));

  useEffect(() => {
    const missingReferenceNumbers = Array.from(discounts.entries())
      .filter(([key, value]) => key !== undefined && value === undefined)
      .map(([key, _]) => key);

    const referencesToFetch = missingReferenceNumbers.map((r) => {
      return {
        referenceNumber: r,
        commercialFamily: getData(references.find((ref) => ref.referenceNumber === r))?.commercialFamily ?? '',
      };
    });

    if (isTradingProfileComplete && referencesToFetch.length > 0) {
      dispatch(
        fetchReferencesDiscountsRequestSaga({
          references: referencesToFetch,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referenceNumbers]);
};

export const useFetchReferenceStocks = (
  referenceNumber: string,
  stockRequestDetails?: ReferenceStockTradingDataRequestDetail,
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): ReferenceMap<SearchData<ReferenceStock>> => {
  return useFetchReferencesStocks(
    [referenceNumber],
    stockRequestDetails ? [stockRequestDetails] : [],
    sellerTresorCode,
    buyerTresorCode,
  );
};

export const useFetchReferencesStocks = (
  referenceNumbers: string[],
  stockRequestDetails?: ReferenceStockTradingDataRequestDetail[],
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): ReferenceMap<SearchData<ReferenceStock>> => {
  const typesMap = useSelector((state: RootState) => getReferenceTypes(state, referenceNumbers));
  const sourceMap = useSelector((state: RootState) => getReferenceSources(state, referenceNumbers));
  const referencesToFetchStocks = referenceNumbers.map((r) => {
    return {
      referenceNumber: r,
      type: typesMap.get(r) ?? 'STANDARD',
      quantity: 1,
      origin: stockRequestDetails?.find((ref) => ref.referenceNumber === r)?.origin,
      supplierCode: stockRequestDetails?.find((ref) => ref.referenceNumber === r)?.supplierCode,
      referenceSource: sourceMap.get(r) ?? 'STANDARD',
    };
  });
  return useFetchStocks(referencesToFetchStocks, false, sellerTresorCode, buyerTresorCode);
};

export const useFetchTireStocks = (
  referenceNumbers: string[],
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): ReferenceMap<SearchData<ReferenceStock>> => {
  const setParam = useSelector(getTireSetParam);
  const otherSection = useSelector(getBasketOtherSection);
  const currentBasketReferencesMap = otherSection?.references?.reduce(
    (acc, next) => acc.set(next.referenceNumber, next.quantity),
    new Map<string, number>(),
  );
  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers));

  const referencesToFetchStocks = referenceNumbers
    .map((r) => {
      const currQuantity = currentBasketReferencesMap.get(r);
      return {
        referenceNumber: r,
        type: 'TIRE',
        quantity: currQuantity ?? setParam ?? 1,
        origin: undefined,
        supplierCode: undefined,
        referenceSource: 'STANDARD',
      } as ReferenceStockTradingDataRequestDetail;
    })
    .filter((ref) => {
      const stock = stocks.get(ref.referenceNumber);
      return stock === undefined || (stock?.data !== undefined && stock?.data?.confirmedQuantity !== ref.quantity);
    });
  return useFetchStocks(referencesToFetchStocks, true, sellerTresorCode, buyerTresorCode);
};

export const useFetchReuseStocks = (referenceNumbers: string[], nodeId?: string): void => {
  const dispatch = useDispatch();
  const userCountry = useSelector(getUserCountry);
  const referenceTypesMap = useSelector((state: RootState) => getReferenceTypes(state, referenceNumbers));
  const reuseStocks = useSelector((state: RootState) => getReuseStocksMap(state, referenceNumbers));

  useEffect(() => {
    if (userCountry !== Country.FR.key || referenceNumbers.length < 1 || (nodeId && !REUSE_NODE_IDS.includes(nodeId))) {
      return;
    }

    const diff = referenceNumbers.filter((ref) => {
      const curr = reuseStocks.get(ref);
      const refType = referenceTypesMap.get(ref);
      return refType !== TIRE && curr === undefined;
    });

    if (diff.length < 1) return;
    dispatch(
      fetchReuseStocksRequestSaga({
        referenceNumbers: diff,
        nodeId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, referenceNumbers, referenceTypesMap, nodeId]);
};

export const useFetchStocks = (
  requestStockDetails: ReferenceStockTradingDataRequestDetail[],
  fetchEvenIfExists = false,
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): ReferenceMap<SearchData<ReferenceStock>> => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const userContext = useSelector(getUserContext);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const referenceNumbers = requestStockDetails.map((r) => r.referenceNumber);

  const btc = buyerTresorCode ?? tradingProfile?.buyerId ?? '';
  const stc = sellerTresorCode ?? tradingProfile?.sellerId ?? '';

  const stocks = useSelector((state: RootState) => getStocksMap(state, referenceNumbers, stc, btc));

  useEffect(() => {
    const referenceStocksToFetch = requestStockDetails.filter((detail) => {
      if (!detail?.referenceNumber) {
        return true;
      }
      const curr = stocks.get(detail.referenceNumber);
      return curr?.searchStatus === undefined || curr?.data?.confirmedQuantity !== detail.quantity;
    });

    if (isTradingProfileComplete && referenceStocksToFetch.length > 0) {
      dispatch(
        fetchReferencesStocksRequestSaga({
          data: { sellerTresorCode: stc, buyerTresorCode: btc, references: referenceStocksToFetch, userContext },
          fetchEvenIfExists,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, JSON.stringify(requestStockDetails), stc, btc, isTradingProfileComplete]);
  return stocks;
};

export const useFetchRepairPrices = (referenceNumbers: string[], vehicleKey?: string): void => {
  const dispatch = useDispatch();
  const tradingProfile = useSelector(getTradingProfile);
  const isTradingProfileComplete = tradingProfile?.isComplete();
  const prices = useSelector((state: RootState) => getRepairPricesMap(state, referenceNumbers));
  const references = useSelector((state: RootState) => getDHReferencesMap(state, { referenceNumbers, vehicleKey }));

  useEffect(() => {
    const missingReferenceNumbers = Array.from(prices.entries())
      .filter(([_, value]) => value === undefined)
      .map(([key, _]) => key);

    const referencesToFetch = missingReferenceNumbers.map((r) => {
      return { referenceNumber: r, commercialFamily: getData(references.get(r))?.familyCode ?? '' };
    });

    if (isTradingProfileComplete && missingReferenceNumbers.length > 0) {
      dispatch(fetchReferencesRepairPriceRequestSaga({ references: referencesToFetch }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isTradingProfileComplete, referenceNumbers]);
};

export const useFetchFullTradingData = (
  referenceNumber: string,
  vehicleKey?: string,
  sellerTresorCode?: string,
  buyerTresorCode?: string,
): void => {
  useFetchPrices([referenceNumber], vehicleKey, sellerTresorCode, buyerTresorCode);
  useFetchReferencesStocks([referenceNumber], undefined, sellerTresorCode, buyerTresorCode);
};

export const useFetchFullTradingDatas = (
  referenceNumbers: string[],
  vehicleKey?: string,
  stockRequestDetails?: ReferenceStockTradingDataRequestDetail[],
): void => {
  useFetchPrices(referenceNumbers, vehicleKey);
  useFetchReferencesStocks(referenceNumbers, stockRequestDetails);
};

export const useFetchPlatesForReference = (
  referenceNumber: string,
  vehicleKey: string | undefined,
): SearchData<ReferencePlateLine[]> => {
  const dispatch = useDispatch();
  const reference = useSelector((state: RootState) => getDHReference(state, { vehicleKey, referenceNumber }));
  const ref = getData(reference);

  useEffect(() => {
    if (vehicleKey && ref && ref?.referencePlateLinesStatus === undefined) {
      dispatch(
        fetchPlatesForReferenceRequestSaga({
          referenceNumber,
          vehicleKey,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceNumber, ref?.referencePlateLinesStatus, vehicleKey]);
  return {
    searchStatus: ref?.referencePlateLinesStatus,
    data: ref?.referencePlateLines,
  };
};
