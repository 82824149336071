/* eslint-disable max-len */
import {
  CheckoutExternalBasketReference,
  CheckoutOtherSection,
  CheckoutVehicleReference,
  OrderMKTPDeliveryMode,
} from '@1po/1po-bff-fe-spec/generated/basket/request/CreateOrderFromBasket';
import { isRefMKTP } from 'domains/basket/Basket.store';
import {
  ValidationItemsExternal,
  ValidationItemsOthers,
  ValidationItemsVehicles,
} from 'domains/orderValidation/OrderValidation.types';
import { ExternalBasketSectionLocal, ReferenceLocal, VehicleLocal } from '../basket/Basket.types';

export const collectCheckoutVehicleReference = (
  vehicles: VehicleLocal[],
  vehiclesValidation: ValidationItemsVehicles[] | undefined,
  mktpDeliveryMode?: OrderMKTPDeliveryMode,
): CheckoutVehicleReference[] => {
  return vehicles.reduce((acc: CheckoutVehicleReference[], vehicle: VehicleLocal) => {
    const vehicleValidation = vehiclesValidation?.find((veh) => veh.vehicleKey === vehicle.vehicleDetail.vehicleKey);
    const enableMKTPDeliveryMode = vehicle.references.find((r) => isRefMKTP(r)) !== undefined;
    return [
      ...acc,
      {
        vehicleKey: vehicle.vehicleDetail.vehicleKey,
        references: collectReferenceNumbers(vehicle.references ? vehicle.references : []),
        paymentClientCode: vehicleValidation?.paymentMethod,
        deliveryType: vehicleValidation?.deliveryAddress,
        instruction: vehicleValidation?.instructions,
        mktpDeliveryMode: enableMKTPDeliveryMode ? mktpDeliveryMode : undefined,
      },
    ];
  }, []);
};

export const collectCheckoutExternalReference = (
  externalBaskets: ExternalBasketSectionLocal[],
  externalValidation: ValidationItemsExternal[] | undefined,
  mktpDeliveryMode?: OrderMKTPDeliveryMode,
): CheckoutExternalBasketReference[] => {
  return externalBaskets.reduce((acc: CheckoutExternalBasketReference[], ext: ExternalBasketSectionLocal) => {
    const externalBasketValidation = externalValidation?.find(
      (external) => external.externalBasketId === ext.externalBasketId,
    );
    const enableMKTPDeliveryMode = ext.references.find((r) => isRefMKTP(r)) !== undefined;
    return [
      ...acc,
      {
        externalBasketId: ext.externalBasketId,
        references: collectReferenceNumbers(ext.references),
        deliveryType: externalBasketValidation?.deliveryAddress,
        paymentClientCode: externalBasketValidation?.paymentMethod,
        instruction: externalBasketValidation?.instructions,
        mktpDeliveryMode: enableMKTPDeliveryMode ? mktpDeliveryMode : undefined,
      },
    ];
  }, []);
};

export const collectCheckoutOtherSection = (
  references: ReferenceLocal[],
  orderValidation: ValidationItemsOthers | undefined,
  mktpDeliveryMode?: OrderMKTPDeliveryMode,
): CheckoutOtherSection => {
  const enableMKTPDeliveryMode = references.find((r) => isRefMKTP(r)) !== undefined;
  return {
    references: collectReferenceNumbers(references),
    paymentClientCode: orderValidation?.paymentMethod,
    deliveryType: orderValidation?.deliveryAddress,
    instruction: orderValidation?.instructions,
    mktpDeliveryMode: enableMKTPDeliveryMode ? mktpDeliveryMode : undefined,
  };
};

export const collectReferenceNumbers = (references: ReferenceLocal[]): string[] => {
  return references.reduce((acc: string[], ref: ReferenceLocal) => {
    if (ref.isSelected) {
      return [...acc, ref.referenceNumber];
    } else {
      return acc;
    }
  }, []);
};

export const collectAllReferenceNumbers = (
  references: ReferenceLocal[] | undefined,
  disabledRefs?: string[],
): string[] => {
  if (!references) {
    return [];
  }

  const filteredReferences = references.filter((ref) => !disabledRefs?.includes(ref.referenceNumber));
  return filteredReferences.reduce((acc: string[], ref: ReferenceLocal) => [...acc, ref.referenceNumber], []);
};

export const countIsSelectedReferences = (references: ReferenceLocal[] | undefined): number => {
  if (!references) {
    return 0;
  }
  return references.reduce((num: number, ref: ReferenceLocal) => (ref.isSelected ? num + 1 : num), 0);
};
