import {
  UPLOAD_ORDER_LIST_REFERENCES_FILE_RESPONSE,
  UPLOAD_ORDER_LIST_REFERENCES_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { OrderListReferenceRowError } from '@1po/1po-bff-fe-spec/generated/order_list/model/OrderListReferenceRowError';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import { ModalStatusType, NO_FILE } from 'components/FileUpload/paramsByState';
import { CartReferenceErrorRow } from 'domains/basket/Basket.types';
import {
  ADD_ORDER_LIST_REFERENCES_TO_CART,
  CreateOrderList,
  ORDER_LIST_NAMESPACE,
  OrderListFileStatus,
  OrderListReferenceLocal,
  OrderListStore,
  UPLOAD_FILE_ORDER_LIST_REFERENCES,
} from 'domains/orderList/OrderList.types';
import { FOUND, SEARCH_STATUS, SearchData } from 'utils';

export const addReferenceListToCartRequest = createAction(ADD_ORDER_LIST_REFERENCES_TO_CART);
export const addReferenceListToCartResponse = createAction(UPLOAD_ORDER_LIST_REFERENCES_RESPONSE);

export const uploadFileOrderList = createAction<{
  fileName: string;
  fileBase64: string;
}>(UPLOAD_FILE_ORDER_LIST_REFERENCES);

export const uploadFileOrderListReferencesResponse = createAction(UPLOAD_ORDER_LIST_REFERENCES_FILE_RESPONSE);

// Init state
const emptyReference = {
  index: undefined,
  referenceNumber: undefined,
  quantity: 0,
};

const initialCreateOrderListState: CreateOrderList = {
  id: uuidv4(),
  name: undefined,
  references: Array.from(Array(20))
    .fill(emptyReference)
    .map((item, index) => {
      return { ...item, index } as OrderListReferenceLocal;
    }),
  searchStatus: { searchStatus: FOUND },
};

const initialState: OrderListStore = {
  orderListMap: new Map<string, CreateOrderList>(),
  createNewOrderList: initialCreateOrderListState,
  orderListResponseMap: new Map<string, SearchData<CartReferenceErrorRow[]>>(),
  fileUploadStatus: {
    fileStatus: NO_FILE,
    correctRows: [],
    errorRows: [],
    isModalOpen: false,
    modalStatus: NO_FILE,
    hasFile: false,
  },
  fileInformation: { fileName: '', fileSize: 0, uploadTime: undefined },
  fileInformationForRequest: { fileName: '', fileSize: 0, uploadTime: undefined },
  fileRows: [],
  fileSearchStatus: undefined,
};

// Slice
const slice = createSlice({
  name: ORDER_LIST_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setOrderListName: (state, { payload }: PayloadAction<string>) => {
      state.createNewOrderList.name = payload;
    },
    setOrderListReference: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
        reference: OrderListReferenceLocal;
      }>,
    ) => {
      const { index, reference } = payload;
      state.createNewOrderList.references[index] = reference;
    },
    setOrderListReferencesFromFile: (
      state,
      {
        payload,
      }: PayloadAction<{
        references: {
          referenceNumber: string;
          quantity: number;
        }[];
      }>,
    ) => {
      const { references } = payload;
      const storeReferences = state.createNewOrderList.references;
      const useReferences = [...references];

      storeReferences.forEach((s) => {
        if (!s.referenceNumber) {
          const currentRef = useReferences.shift();
          state.createNewOrderList.references[s.index] = {
            index: s.index,
            referenceNumber: currentRef?.referenceNumber,
            quantity: currentRef?.quantity ?? 0,
          };
        }
      });

      const itemsCount = storeReferences.length;

      useReferences.forEach((r, i) => {
        state.createNewOrderList.references.push({
          index: itemsCount + i,
          referenceNumber: r?.referenceNumber,
          quantity: r?.quantity ?? 1,
        });
      });
    },
    resetOrderListReference: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number;
      }>,
    ) => {
      const { index } = payload;
      const reference = state.createNewOrderList.references[index];
      state.createNewOrderList.references[index] = {
        ...reference,
        referenceNumber: undefined,
        quantity: 0,
      };
    },
    addEmptyOrderListRow: (state) => {
      const itemsCount = state.createNewOrderList.references.length;
      const newItems: OrderListReferenceLocal[] = [];
      for (let index = 0; index < (itemsCount % 2 ? 3 : 2); index++) {
        newItems.push({
          ...emptyReference,
          index: itemsCount + index,
        });
      }
      state.createNewOrderList.references = [...state.createNewOrderList.references, ...newItems];
    },
    setOrderListResponseMapSearchStatus: (
      state,
      { payload }: PayloadAction<{ id: string; searchStatus: SEARCH_STATUS }>,
    ) => {
      const { id, searchStatus } = payload;
      state.orderListResponseMap.set(id, { searchStatus });
    },
    setOrderListResponseMapData: (state, { payload }: PayloadAction<{ id: string; data: CartReferenceErrorRow[] }>) => {
      const { id, data } = payload;
      state.orderListResponseMap.set(id, { searchStatus: FOUND, data });
    },
    setCreateOrderListSearchStatus: (state, { payload }: PayloadAction<{ searchStatus: SEARCH_STATUS }>) => {
      const { searchStatus } = payload;
      state.createNewOrderList.searchStatus = { searchStatus };
    },
    setCreateOrderListResponseData: (state, { payload }: PayloadAction<{ data: CartReferenceErrorRow[] }>) => {
      const { data } = payload;
      state.createNewOrderList.searchStatus = { searchStatus: FOUND, data };
    },
    setOrderListFileUploadStatus: (state, { payload }: PayloadAction<OrderListFileStatus>) => {
      state.fileUploadStatus.fileStatus = payload;
    },
    setOrderListFileModalStatus: (state, { payload }: PayloadAction<ModalStatusType>) => {
      state.fileUploadStatus.modalStatus = payload;
    },
    setOrderListFileErrorRows: (state, { payload }: PayloadAction<OrderListReferenceRowError[]>) => {
      state.fileUploadStatus.errorRows = payload;
    },
    setOrderListFileReferencesIsModalOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.fileUploadStatus.isModalOpen = payload;
    },
  },
});

// Actions
export const {
  setInitialState,
  setOrderListName,
  setOrderListReference,
  resetOrderListReference,
  addEmptyOrderListRow,
  setOrderListResponseMapSearchStatus,
  setOrderListResponseMapData,
  setCreateOrderListSearchStatus,
  setCreateOrderListResponseData,
  setOrderListFileUploadStatus,
  setOrderListFileModalStatus,
  setOrderListFileErrorRows,
  setOrderListFileReferencesIsModalOpen,
  setOrderListReferencesFromFile,
} = slice.actions;

// Getters/Selectors
export const getCreateOrderList = createSelector(
  (state: RootState) => state.orderList.createNewOrderList,
  (createNewOrderList) => createNewOrderList,
);
export const getCreateOrderListErrorRows = createSelector(
  (state: RootState) => state.orderList.createNewOrderList,
  (createNewOrderList) => createNewOrderList.searchStatus,
);

export const getOrderListFileUploadStatus = createSelector(
  (state: RootState) => state.orderList.fileUploadStatus.fileStatus,
  (fileStatus) => fileStatus,
);

export const getOrderListFileUploadModalStatus = createSelector(
  (state: RootState) => state.orderList.fileUploadStatus.modalStatus,
  (modalStatus) => modalStatus,
);

export const getOrderListFileUploadIsModalOpen = createSelector(
  (state: RootState) => state.orderList.fileUploadStatus.isModalOpen,
  (isModalOpen) => isModalOpen,
);

export const getOrderListFileUploadErrorRows = createSelector(
  (state: RootState) => state.orderList.fileUploadStatus.errorRows,
  (errorRows) => errorRows,
);

export default slice.reducer;
