import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { ExclamationTriangleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import { ReferenceUnavailable } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { getDHReference, getIsStockAvailable } from 'domains/references';
import { usePrice } from 'domains/references/References.requests';
import { SImageBox, SListItem } from 'pages/CatalogPage/DH/Product/Product.styled';
import {
  getProductLink,
  getReferenceNumberText,
  hasPrice,
  PriceField,
  ProductMenuItemProps,
  renderPromoCorner,
} from 'pages/CatalogPage/DH/Product/ProductMenu/ProductMenuList';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { theme } from 'styles';
import { Box, Flex, Icon, Link, MarginBox, Text, WithTooltip } from 'UI';
import { getSearchData, hasData } from 'utils';

const KitReferenceItem = ({ parentRef, referenceNumber, sparePartsView }: ProductMenuItemProps) => {
  const { t } = useTranslation();
  const { query } = useParams<{
    query: string;
  }>();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleBrand = useSelector(getLastVehicleDetail)?.vehicleBrand ?? 'OTHER';
  const price = getSearchData(usePrice(referenceNumber));
  const reference = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));

  const kitReference = parentRef?.kitReferences?.find((ref) => ref.referenceNumber === referenceNumber);
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));

  return (
    <DataContainer data={reference}>
      {hasData(reference) && (
        <Link to={getProductLink(query, referenceNumber)}>
          <SListItem>
            {renderPromoCorner(reference.referenceNumber, sparePartsView)}
            <Flex align={'center'} basis={2}>
              <SImageBox>
                {availableInUserCountry ? (
                  <BrandImage referenceBrand={reference.brand} vehicleBrand={vehicleBrand} size={60} />
                ) : (
                  <Box width={60} height={60} background={theme.color.shadow_10}>
                    <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.shadow_6} />
                  </Box>
                )}
              </SImageBox>
              <Flex minWidth={0} maxWidth={200} size={2}>
                <MarginBox ml={15} />
                <WithTooltip
                  title={
                    <Text type={'text_white'} transform={'sentence-case'}>
                      {reference.name}
                    </Text>
                  }
                  placement={'top'}
                >
                  <Text cursor={'pointer'} transform={'sentence-case'} disableGutter type={'section_bold'} ellipsis>
                    {reference.name}
                  </Text>
                </WithTooltip>
                <MarginBox ml={15} />
              </Flex>
              {availableInUserCountry ? (
                <>
                  <Flex size={1}>
                    <Text cursor={'pointer'} disableGutter type={'light_14_black_65'}>
                      {kitReference &&
                        `(${kitReference.quantity} ${t('catalog.kit_reference_card.units', 'Units', {
                          count: kitReference.quantity,
                        })})`}
                    </Text>
                    <MarginBox ml={15} />
                  </Flex>
                  <Flex>
                    <Text cursor={'pointer'} disableGutter type={'section'}>
                      {getReferenceNumberText(t, reference.referenceNumber)}
                    </Text>
                    <MarginBox ml={15} />
                  </Flex>
                  {hasPrice(price, sparePartsView) ? (
                    <PriceField
                      price={price}
                      sparePartsView={sparePartsView}
                      referenceNumber={reference.referenceNumber}
                    />
                  ) : (
                    <Box width={130} />
                  )}
                  <MarginBox ml={15} />
                </>
              ) : (
                <Flex size={2} justify={'flex-end'}>
                  <ReferenceUnavailable />
                  <MarginBox ml={15} />
                </Flex>
              )}
            </Flex>
          </SListItem>
        </Link>
      )}
    </DataContainer>
  );
};

export default KitReferenceItem;
