/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Loader from 'components/Loader';
import { getBasketVehicles } from 'domains/basket/Basket.store';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { MarketingReferencesWrapper, PlateReferencesDetail } from 'domains/catalog/Catalog.types';
import { getDHReferences } from 'domains/references';
import {
  useFetchCrossSellingReferences,
  useFetchFullTradingDatas,
  useFetchPrices,
  useFetchReuseStocks,
} from 'domains/references/References.requests';
import { SparePartsViewType } from 'domains/user';
import { marketingProductsIndex } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/MarketingProductsBanner/MarketingProductsBanner';
import { IndexHeader } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/IndexHeader/IndexHeader';
import MarketingReferenceCard from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/MarketingReferenceCard';
import PlateReferencesContainer from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferencesContainer';
import { SelectedIndexType } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/SparePartsSection';
import { Empty, Flex, Lowercase, MarginBox, Text } from 'UI';
import { FOUND, getData, getSearchData, hasData, LOADING, NO_DATA, NOT_FOUND } from 'utils';
import { useOffsetTop } from 'utils/hooks/useOffsetTop';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';
import { IndexType } from 'utils/svg/common';

export interface ReferencesCardsProps {
  references: PlateReferencesDetail[] | NO_DATA;
  vehicleBrand: VehicleBrandType;
  sparePartsView: SparePartsViewType;
  marketingReferencesWrapper?: MarketingReferencesWrapper;
  selectedIndex: SelectedIndexType;
  setSelectedIndex: (value: ((prevState: SelectedIndexType) => SelectedIndexType) | SelectedIndexType) => void;
  setHighlightedIndex: (value: ((prevState: IndexType) => IndexType) | IndexType) => void;
  plateId?: string;
  nodeId?: string;
}

const ReferenceCardsContainer = ({
  references,
  vehicleBrand,
  sparePartsView,
  marketingReferencesWrapper,
  selectedIndex,
  setSelectedIndex,
  setHighlightedIndex,
  plateId,
  nodeId,
}: ReferencesCardsProps) => {
  const { t } = useTranslation();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const marketingReferences = useSelector((state: RootState) =>
    getDHReferences(state, { vehicleKey, referenceNumbers: marketingReferencesWrapper?.referenceNumbers }),
  );
  const basketVehicleRefs = useSelector(getBasketVehicles)
    .find((bv) => bv.vehicleDetail.vehicleKey === vehicleKey)
    ?.references.map((r) => r.referenceNumber);

  useFetchCrossSellingReferences(marketingReferencesWrapper?.referenceNumbers ?? [], vehicleKey);

  const refNumbers = useMemo(() => getData(references)?.map((r) => r.referenceNumber) ?? [], [references]);
  const prices = useFetchPrices(refNumbers);

  useFetchFullTradingDatas([...refNumbers, ...(marketingReferencesWrapper?.referenceNumbers ?? [])], vehicleKey);
  useFetchCrossSellingReferences(refNumbers, vehicleKey);
  useFetchReuseStocks(refNumbers, nodeId);

  const isMarketingReferencesEmpty = marketingReferencesWrapper?.status === FOUND && marketingReferences.length === 0;
  const isPlateReferencesEmpty = !references || references === NOT_FOUND;

  // everything empty, display empty component
  if (isMarketingReferencesEmpty && isPlateReferencesEmpty) {
    return <Empty />;
  }

  const renderPlateReferences = () => {
    const firstRefWithPriceForFirstHelp = prices.referenceNumbers().find((refNumber) => {
      const refPrices = getSearchData(prices.get(refNumber));
      return refPrices?.garageView?.vatExcludedPrice;
    });

    const firstRefWithPriceNotInCartForFirstHelp = prices.keys().find((key) => {
      const refPrices = getSearchData(prices.get(key));
      return refPrices?.garageView?.vatExcludedPrice && !basketVehicleRefs?.includes(key.referenceNumber);
    })?.referenceNumber;
    return (
      <DataContainer
        data={references}
        Loading={() => <Loader height={'10vh'} />}
        NotFound={() => <></>}
        ErrorState={() => (
          <ErrorWithLabel
            label={t(
              'catalog.plate.references.backend_error',
              'References are temporarily unavailable, please try again later.',
            )}
          />
        )}
      >
        <MarginBox key={`index_marketing_products`} mt={15} mb={30}>
          {hasData(references) &&
            references.map((detail, index) => (
              <PlateReferencesContainer
                key={`plate-reference-index-${detail.index}`}
                detail={detail}
                isSelected={detail.index.toString() === selectedIndex}
                setSelectedIndex={setSelectedIndex}
                setHighlightedIndex={setHighlightedIndex}
                vehicleBrand={vehicleBrand}
                sparePartsView={sparePartsView}
                plateId={plateId}
                firstRefWithPrice={firstRefWithPriceForFirstHelp}
                firstRefWithPriceNotInCart={firstRefWithPriceNotInCartForFirstHelp}
                refIndex={index}
                index={detail.index}
              />
            ))}
        </MarginBox>
      </DataContainer>
    );
  };

  const renderMarketingReferences = () => {
    if (marketingReferences.length > 0) {
      const sectionIsHighLighted = selectedIndex === marketingProductsIndex;

      return (
        <DataContainer
          data={marketingReferences}
          ErrorState={() => (
            <ErrorWithLabel
              label={t(
                'catalog.plate.marketing_references.backend_error',
                'Marketing references are temporarily unavailable, please try again later.',
              )}
            />
          )}
        >
          <MarginBox key={`index_marketing_products`} mt={15} mb={30}>
            <IndexHeader id={`marketing-reference-index-header`} isSelected={sectionIsHighLighted}>
              <Text
                type={sectionIsHighLighted ? 'h6_white' : 'h6'}
                transform={'capitalize'}
                id={`marketing-reference-index-title`}
                onClick={() => {
                  const marketingProductsBannerWrapper = document.getElementById('marketing-reference-banner-wrapper');
                  if (marketingProductsBannerWrapper) {
                    setSelectedIndex(marketingProductsIndex);
                    scrollToTopSmooth(marketingProductsBannerWrapper.offsetTop - offsetTop);
                  }
                }}
                disableGutter
              >
                <Lowercase>{t('catalog.plate.associated_products', 'Associated products')}</Lowercase>
              </Text>
            </IndexHeader>
            <MarginBox key={`index_marketing_products`} mt={15}>
              {marketingReferences.map((ref) => (
                <MarketingReferenceCard
                  key={`marketing-ref-${ref.referenceNumber}`}
                  refLocal={ref}
                  vehicleBrand={vehicleBrand}
                  sparePartsView={sparePartsView}
                  plateId={plateId}
                />
              ))}
            </MarginBox>
          </MarginBox>
          {marketingReferencesWrapper?.status === LOADING && <Loader height={'10vh'} />}
        </DataContainer>
      );
    }

    if (marketingReferences.length === 0 && marketingReferencesWrapper?.status === LOADING) {
      return <Loader height={'10vh'} />;
    }

    return null;
  };

  return (
    <Flex direction={'column'}>
      {renderPlateReferences()}
      {renderMarketingReferences()}
    </Flex>
  );
};

export default ReferenceCardsContainer;
