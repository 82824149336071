/* sonar-disable typescript:S4204 */
class CacheMap<K, V> implements Map<K, V> {
  private readonly map: Map<string, { value: V; timeoutId: NodeJS.Timeout }>;
  private readonly expirationTime: number;

  constructor(expirationTime: number) {
    if (expirationTime <= 0) {
      throw new Error('Expiration time must be positive');
    }
    this.map = new Map();
    this.expirationTime = expirationTime;
  }

  private serializeKey(key: K): string {
    if (key === null) return 'null';
    if (key === undefined) return 'undefined';
    if (typeof key !== 'object') return String(key);

    try {
      const sortedObj = this.createSortedObject(key);
      return JSON.stringify(sortedObj);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to serialize key: ${error.message}`);
      }
      throw new Error('Failed to serialize key: Unknown error');
    }
  }

  private createSortedObject(obj: any): any {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.createSortedObject(item));
    }

    if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj)
        .sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }))
        .reduce((sorted, key) => {
          sorted[key] = this.createSortedObject(obj[key]);
          return sorted;
        }, {} as Record<string, any>);
    }

    return obj;
  }

  private deserializeKey(serializedKey: string): K {
    try {
      if (serializedKey === 'null') return null as K;
      if (serializedKey === 'undefined') return undefined as K;
      if (this.isPrimitiveString(serializedKey)) return (serializedKey as unknown) as K;

      return JSON.parse(serializedKey);
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Failed to deserialize key: ${error.message}`);
      }
      throw new Error('Failed to deserialize key: Unknown error');
    }
  }

  private isPrimitiveString(str: string): boolean {
    return !str.startsWith('{') && !str.startsWith('[');
  }

  set(key: K, value: V): this {
    const serializedKey = this.serializeKey(key);

    if (this.map.has(serializedKey)) {
      clearTimeout(this.map.get(serializedKey)?.timeoutId);
    }

    const timeoutId = setTimeout(() => {
      this.map.delete(serializedKey);
    }, this.expirationTime);

    this.map.set(serializedKey, { value, timeoutId });
    return this;
  }

  get(key: K): V | undefined {
    const serializedKey = this.serializeKey(key);
    const entry = this.map.get(serializedKey);

    if (entry) {
      clearTimeout(entry.timeoutId);
      entry.timeoutId = setTimeout(() => {
        this.map.delete(serializedKey);
      }, this.expirationTime);

      return entry.value;
    }

    return undefined;
  }

  has(key: K): boolean {
    return this.map.has(this.serializeKey(key));
  }

  delete(key: K): boolean {
    const serializedKey = this.serializeKey(key);
    const entry = this.map.get(serializedKey);

    if (entry) {
      clearTimeout(entry.timeoutId);
      this.map.delete(serializedKey);
      return true;
    }

    return false;
  }

  clear(): void {
    this.map.forEach((entry) => clearTimeout(entry.timeoutId));
    this.map.clear();
  }

  get size(): number {
    return this.map.size;
  }

  entries(): IterableIterator<[K, V]> {
    const entriesIterator = this.map.entries();
    return {
      [Symbol.iterator](): IterableIterator<[K, V]> {
        return this;
      },
      next: (): IteratorResult<[K, V]> => {
        const next = entriesIterator.next();
        if (next.done) return { done: true, value: undefined };

        const [serializedKey, entry] = next.value;
        return {
          done: false,
          value: [this.deserializeKey(serializedKey), entry.value],
        };
      },
    };
  }

  keys(): IterableIterator<K> {
    const keysIterator = this.map.keys();
    return {
      [Symbol.iterator](): IterableIterator<K> {
        return this;
      },
      next: (): IteratorResult<K> => {
        const next = keysIterator.next();
        if (next.done) return { done: true, value: undefined };

        return {
          done: false,
          value: this.deserializeKey(next.value),
        };
      },
    };
  }

  values(): IterableIterator<V> {
    const valuesIterator = this.map.values();
    return {
      [Symbol.iterator](): IterableIterator<V> {
        return this;
      },
      next(): IteratorResult<V> {
        const next = valuesIterator.next();
        if (next.done) return { done: true, value: undefined };
        return {
          done: false,
          value: next.value.value,
        };
      },
    };
  }

  forEach(callbackFn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
    this.map.forEach((entry, serializedKey) => {
      const key = this.deserializeKey(serializedKey);
      callbackFn.call(thisArg, entry.value, key, this);
    });
  }

  [Symbol.iterator](): IterableIterator<[K, V]> {
    return this.entries();
  }

  get [Symbol.toStringTag](): string {
    return 'CacheMap';
  }

  dispose(): void {
    this.clear();
  }
}

export default CacheMap;
