import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { matchRoute, ROUTER_CART, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import QuantityModule from 'components/QuantityModule';
import { QuantityModuleUpdateComponentType } from 'components/QuantityModule/QuantityModule';
import StockDisplay from 'components/StockInfo';
import { getBasketOtherSection, getBasketVehicles, updateReferenceQuantityRequest } from 'domains/basket/Basket.store';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { addReferenceFromCatalogToCart, removeCartReferenceInCatalog } from 'domains/catalog/Catalog.store';
import { STANDARD } from 'domains/catalog/Catalog.types';
import { getDHReference } from 'domains/references';
import { useFetchFullTradingData, usePrice } from 'domains/references/References.requests';
import { GarageView, getSparePartsView } from 'domains/user';
import { BlackButton, Box, Flex, MarginBox, Spin, Text } from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { getSearchData, hasData, hasPrice, textFormatter } from 'utils';
import {
  TRACKING_EVENT_ADD_TO_CART,
  TRACKING_EVENT_CART_PART_QUANTITY_ZERO,
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION,
  TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
} from 'utils/eventTracker/EventTracker.types';

interface LinkedReferenceProps {
  referenceNumber: string;
  vehicleDetail: VehicleDetail | undefined;
  isApplicableToCurrentVehicle?: boolean;
}

export const LinkedReference = ({
  referenceNumber,
  vehicleDetail,
  isApplicableToCurrentVehicle,
}: LinkedReferenceProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey: vehicleDetail?.vehicleKey, referenceNumber }),
  );
  useFetchSingleReference(referenceNumber, null, vehicleDetail?.vehicleKey);
  useFetchFullTradingData(referenceNumber, vehicleDetail?.vehicleKey);
  const price = getSearchData(usePrice(referenceNumber));
  const sparePartsView = useSelector(getSparePartsView);
  const vehicles = useSelector(getBasketVehicles);
  const otherSection = useSelector(getBasketOtherSection);
  const basketReferences = vehicleDetail?.vehicleKey
    ? vehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleDetail.vehicleKey)?.references
    : otherSection?.references;
  const refQuantity = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber)?.quantity as number;

  const quantityCallback = (quantity: number | string, type?: QuantityModuleUpdateComponentType) => {
    if (quantity === 0) {
      dispatch(
        removeCartReferenceInCatalog({
          basketReferenceType: vehicleDetail?.vehicleKey ? 'VEHICLE' : 'OTHER',
          vehicleKey: vehicleDetail?.vehicleKey ? vehicleDetail?.vehicleKey : undefined,
          referenceType: STANDARD,
          referenceNumber,
          origin: undefined,
          supplierCode: undefined,
          referenceSource: 'STANDARD',
        }),
      );
      if (type === 'INPUT') {
        trackAppEvent(TRACKING_EVENT_CART_PART_QUANTITY_ZERO);
      } else {
        trackAppEvent(
          matchRoute(location.pathname, ROUTER_PRODUCT)
            ? TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION
            : TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
        );
      }
    } else {
      dispatch(
        updateReferenceQuantityRequest({
          basketReferenceType: vehicleDetail?.vehicleKey ? 'VEHICLE' : 'OTHER',
          vehicleKey: vehicleDetail?.vehicleKey ? vehicleDetail?.vehicleKey : undefined,
          externalBasketId: undefined,
          referenceNumber,
          newQuantity: quantity as number,
          referenceType: STANDARD,
        }),
      );
    }
  };

  const handleAddToCartClick = () => {
    if (
      ref &&
      hasData(ref) &&
      vehicleDetail &&
      price?.garageView?.vatExcludedPrice &&
      price?.garageView?.vatIncludedPrice
    ) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.reference_card.added_to_basket.description'}>
          {'Reference has been added to your cart'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
            history.push(ROUTER_CART);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_TO_CART);
      dispatch(addReferenceFromCatalogToCart({ referenceNumber }));
    }
  };

  if (!hasData(ref)) return <Spin />;

  const renderPriceView = () => {
    if (!price) return null;

    return sparePartsView === GarageView ? (
      <Text type={'h5_bold'}>
        {t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
          vat_exclude_price: textFormatter.formatCurrency(
            Number(price.clientView?.recommendedPriceVatExcluded),
            price.currency,
          ),
        })}
      </Text>
    ) : (
      <Text type={'h5_bold'}>
        {t('common.price.vat_include_price', '{{vat_include_price}} VAT. Incl', {
          vat_include_price: textFormatter.formatCurrency(
            Number(price.clientView?.recommendedPriceVatIncluded),
            price.currency,
          ),
        })}
      </Text>
    );
  };

  const renderPriceSubView = () => {
    if (!price) return null;
    return sparePartsView === GarageView ? (
      <Text type={'light_12_black_65'}>
        {t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
          vat_include_price: textFormatter.formatCurrency(Number(price.garageView?.vatIncludedPrice), price.currency),
        })}
        {' / '}
        {t('common.discount.discount_percentage', 'Discount {{discount_in_percentage}}', {
          discount_in_percentage: textFormatter.formatPercentDecimal(Number(price.garageView?.discountRate)),
        })}
      </Text>
    ) : (
      <Text type={'light_12_black_65'}>
        {t('common.price.or_vat_exclude_price', 'or {{vat_exclude_price}} VAT. Excl', {
          vat_exclude_price: textFormatter.formatCurrency(
            Number(price.clientView?.recommendedPriceVatExcluded),
            price.currency,
          ),
        })}
        {' / '}
        {t('common.discount.discount_code', 'DiscountCode: {{discount_code}}', {
          discount_code: price.clientView?.discountCode,
        })}
      </Text>
    );
  };

  const renderAddToCart = () => {
    return basketReferences?.find((ref) => ref.referenceNumber === referenceNumber) ? (
      <QuantityModule value={refQuantity} onChange={quantityCallback} showDelete={true} />
    ) : (
      <BlackButton size={'middle'} onClick={handleAddToCartClick} stretch>
        {t('cart.action.add_to_cart', 'Add to cart')}
      </BlackButton>
    );
  };

  return (
    <>
      <Flex direction={'row'}>
        <Flex direction={'column'}>
          <Text type={'h6'} disableGutter>
            {ref?.name}
          </Text>
          <Flex direction={'row'} align={'baseline'}>
            <Text type={'light_14_black_65'}>
              {t('catalog.reference_card.reference_number', 'Ref:')} {ref?.referenceNumber}
            </Text>
            <MarginBox mr={10} />
            <CopyToClipboardButton
              textToCopy={referenceNumber}
              message={t(
                'catalog.reference_card.reference_number.copied_to_clipboard',
                'Reference number {{referenceNumber}} copied to clipboard',
                { referenceNumber },
              )}
            />
          </Flex>
        </Flex>
        <Flex size={2}>
          <StockDisplay
            isApplicableToCurrentVehicle={isApplicableToCurrentVehicle}
            vehicleKey={vehicleDetail?.vehicleKey}
            referenceNumber={referenceNumber}
          />
        </Flex>
        <Flex direction={'column'}>
          {renderPriceView()}
          {renderPriceSubView()}
        </Flex>
        <Flex direction={'row-reverse'} minWidth={180} maxWidth={180} align={'center'}>
          {hasPrice(sparePartsView, price) && renderAddToCart()}
        </Flex>
      </Flex>
      <Box height={10} />
    </>
  );
};
