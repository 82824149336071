import React, { MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { matchRoute, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { ExclamationTriangleIcon } from 'assets/icons';
import { DataContainer } from 'components/DataContainer';
import QuantityModule from 'components/QuantityModule';
import { QuantityModuleUpdateComponentType } from 'components/QuantityModule/QuantityModule';
import { ReferenceUnavailable } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import { getReferenceQuantity, updateReferenceQuantityRequest } from 'domains/basket/Basket.store';
import {
  addReferenceFromCatalogToCart,
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  removeCartReferenceInCatalog,
} from 'domains/catalog/Catalog.store';
import { STANDARD } from 'domains/catalog/Catalog.types';
import { getDHReference, getIsStockAvailable } from 'domains/references';
import { usePrice } from 'domains/references/References.requests';
import { SImageBox, SListItem } from 'pages/CatalogPage/DH/Product/Product.styled';
import {
  getProductLink,
  getReferenceNumberText,
  hasPrice,
  PriceField,
  ProductMenuItemProps,
  renderPromoCorner,
} from 'pages/CatalogPage/DH/Product/ProductMenu/ProductMenuList';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { theme } from 'styles';
import { BlackButton, Box, Flex, Icon, Link, MarginBox, Text, WithTooltip } from 'UI';
import { getData, getSearchData, hasData } from 'utils';
import {
  TRACKING_EVENT_ADD_TO_CART,
  TRACKING_EVENT_CART_PART_QUANTITY_ZERO,
  TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION,
  TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
} from 'utils/eventTracker/EventTracker.types';

const LinkedProductItem = ({ referenceNumber, sparePartsView, parentRef }: ProductMenuItemProps) => {
  const { t } = useTranslation();
  const { query } = useParams<{
    query: string;
  }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleBrand = useSelector(getLastVehicleDetail)?.vehicleBrand ?? 'OTHER';
  const price = getSearchData(usePrice(referenceNumber));
  const reference = useSelector((state: RootState) => getDHReference(state, { referenceNumber, vehicleKey }));
  const quantity = useSelector((state: RootState) =>
    getReferenceQuantity(state, {
      refNumber: referenceNumber,
      isApplicableToCurrentVehicle: parentRef?.isApplicableToCurrentVehicle,
    }),
  );
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));

  function preventDefaultClick(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  function addToCart(e: MouseEvent<HTMLButtonElement>) {
    preventDefaultClick(e);
    if (!reference) return;
    trackAppEvent(TRACKING_EVENT_ADD_TO_CART);
    dispatch(addReferenceFromCatalogToCart({ referenceNumber }));
  }

  function onChangeQuantity(quantity: number | string, type?: QuantityModuleUpdateComponentType) {
    if (!reference) return;
    if (quantity === 0) {
      dispatch(
        removeCartReferenceInCatalog({
          basketReferenceType: parentRef.isApplicableToCurrentVehicle ? 'VEHICLE' : 'OTHER',
          vehicleKey: parentRef.isApplicableToCurrentVehicle ? vehicleKey : undefined,
          referenceType: getData(reference)?.type ?? STANDARD,
          referenceNumber,
          origin: undefined,
          supplierCode: undefined,
          referenceSource: 'STANDARD',
        }),
      );
      if (type === 'INPUT') {
        trackAppEvent(TRACKING_EVENT_CART_PART_QUANTITY_ZERO);
      } else {
        trackAppEvent(
          matchRoute(location.pathname, ROUTER_PRODUCT)
            ? TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION
            : TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
        );
      }
    } else {
      dispatch(
        updateReferenceQuantityRequest({
          basketReferenceType: parentRef.isApplicableToCurrentVehicle ? 'VEHICLE' : 'OTHER',
          vehicleKey: parentRef.isApplicableToCurrentVehicle ? vehicleKey : undefined,
          externalBasketId: undefined,
          referenceNumber: referenceNumber,
          newQuantity: quantity as number,
          referenceType: getData(reference)?.type ?? STANDARD,
        }),
      );
    }
  }

  return (
    <DataContainer data={reference}>
      {hasData(reference) && (
        <Link to={getProductLink(query, referenceNumber)}>
          <SListItem>
            {renderPromoCorner(reference.referenceNumber, sparePartsView)}
            <Flex align={'center'} justify={'space-between'}>
              <SImageBox>
                {availableInUserCountry ? (
                  <BrandImage referenceBrand={reference.brand} vehicleBrand={vehicleBrand} size={60} />
                ) : (
                  <Box width={60} height={60} background={theme.color.shadow_10}>
                    <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.shadow_6} />
                  </Box>
                )}
              </SImageBox>
              <Flex>
                <MarginBox ml={15} />
                <Flex direction={'column'} minWidth={0}>
                  <WithTooltip
                    title={
                      <Text type={'text_white'} transform={'sentence-case'}>
                        {reference.name}
                      </Text>
                    }
                    placement={'top'}
                  >
                    <Text cursor={'pointer'} transform={'sentence-case'} disableGutter type={'section_bold'} ellipsis>
                      {reference.name}
                    </Text>
                  </WithTooltip>
                  <Text cursor={'pointer'} disableGutter type={'section'}>
                    {getReferenceNumberText(t, reference.referenceNumber)}
                  </Text>
                </Flex>
                <MarginBox ml={15} />
              </Flex>
              {availableInUserCountry ? (
                <>
                  {hasPrice(price, sparePartsView) && (
                    <>
                      <Flex>
                        <PriceField
                          price={price}
                          sparePartsView={sparePartsView}
                          referenceNumber={reference.referenceNumber}
                        />
                      </Flex>
                      <MarginBox ml={15} />
                      {quantity === 0 ? (
                        <Flex maxWidth={150}>
                          <BlackButton onClick={addToCart}>
                            <Trans i18nKey={'cart.action.add_to_cart'}>{'Add to cart'}</Trans>
                          </BlackButton>
                        </Flex>
                      ) : (
                        <div onClick={preventDefaultClick}>
                          <QuantityModule value={quantity} onChange={onChangeQuantity} showDelete />
                        </div>
                      )}
                    </>
                  )}
                  <MarginBox ml={15} />
                </>
              ) : (
                <Flex justify={'flex-end'}>
                  <ReferenceUnavailable />
                  <MarginBox ml={15} />
                </Flex>
              )}
            </Flex>
          </SListItem>
        </Link>
      )}
    </DataContainer>
  );
};

export default LinkedProductItem;
