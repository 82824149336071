/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_CART } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { DataContainer } from 'components/DataContainer';
import { addReferenceFromCatalogToCart, getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { IAMLaborTimesWrapper, OEM_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { mapIamOEMPrice } from 'domains/catalog/Catalog.utils';
import { ADDITIONAL_INFORMATION_ID_P100, getIAMReference, IAMReferenceLocal as Reference } from 'domains/references';
import { useFetchPrices, usePrice } from 'domains/references/References.requests';
import { getIamCatalogBrandsView, SparePartsViewType } from 'domains/user';
import IAMReferenceCardCompact from 'pages/CatalogPage/IAM/MaintenancePlan/References';
import { mapAdditionalInformation } from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/IAMReferenceCardUtils';
import IAMReferenceCard from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard';
import { MarginBox, NotificationLink, notifyTop } from 'UI';
import { getData, getSearchData, NO_DATA } from 'utils';
import { TRACKING_EVENT_ADD_TO_CART, TRACKING_EVENT_GO_TO_CART_SHORTCUT } from 'utils/eventTracker/EventTracker.types';

export function getFirstImageUrl(reference: Reference | undefined): string | undefined {
  return reference?.documents?.find((d) => d.url && d.order !== undefined && d.documentType === 'IMAGE')?.url;
}

export interface PlateReferenceCardProps {
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  laborTimesWrapper?: NO_DATA | IAMLaborTimesWrapper;
  isIamSearchResult?: boolean;
  selected?: boolean;
  isMaintenance?: boolean;
  onSelect?: (referenceNumber: string) => void;
}

const IAMPlateReferenceCard = ({
  referenceNumber,
  sparePartsView,
  laborTimesWrapper,
  isIamSearchResult,
  selected,
  isMaintenance,
  onSelect,
}: PlateReferenceCardProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referenceRaw = useSelector((state: RootState) =>
    getIAMReference(state, {
      referenceNumber,
      vehicleKey: isIamSearchResult ? undefined : vehicleKey,
    }),
  );
  const reference = getData(referenceRaw);
  const price = getSearchData(usePrice(referenceNumber));
  const imageUrl = getFirstImageUrl(reference);
  const iamCatalogView = useSelector(getIamCatalogBrandsView);
  const isOEM = iamCatalogView === OEM_BRAND_KEY && !isIamSearchResult;

  useFetchPrices([referenceNumber], isIamSearchResult ? undefined : vehicleKey);

  if (!reference) {
    return null;
  }

  const refPosition = reference.additionalInformation?.find((i) => i.id === ADDITIONAL_INFORMATION_ID_P100)
    ?.description;

  const rawSupplier = reference.supplierReferences?.filter((ref) => ref.type === 'RAW');
  const rawLabels =
    rawSupplier && rawSupplier.length > 0 ? rawSupplier?.map((supplier) => (supplier ? supplier?.id : '')) : [];
  const tradeNumberSupplier = reference.supplierReferences?.filter((ref) => ref.type === 'TRADE_NUMBER');
  const tradeNumberLabels =
    tradeNumberSupplier && tradeNumberSupplier.length > 0
      ? tradeNumberSupplier?.map((supplier) => (supplier ? supplier?.id : ''))
      : [];
  const gtinSupplier = reference.supplierReferences?.filter((ref) => ref.type === 'GTIN');
  const gtinLabels =
    gtinSupplier && gtinSupplier.length > 0 ? gtinSupplier?.map((supplier) => (supplier ? supplier?.id : '')) : [];

  const referencePrice = isOEM ? mapIamOEMPrice(reference?.price, reference?.currencyCode) : price;
  const displayName = reference.name ?? reference.designation;

  const additionalInformationItems = mapAdditionalInformation(
    t,
    reference.additionalInformation,
    reference.replacingReferences,
    reference.substitutedReferences,
    reference.kitContent,
    reference.kitsContainingThisPart,
    reference.quantityPerUnit,
  );

  const handleAddToCartClick = () => {
    notifyTop(
      'success',
      <Trans i18nKey={'catalog.reference_card.added_to_basket.description'}>
        {'Reference has been added to your cart'}
      </Trans>,
      undefined,
      <NotificationLink
        onClick={() => {
          trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
          history.push(ROUTER_CART);
        }}
      >
        <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
      </NotificationLink>,
    );
    trackAppEvent(TRACKING_EVENT_ADD_TO_CART);
    dispatch(addReferenceFromCatalogToCart({ referenceNumber, addOtherReference: isIamSearchResult }));
  };

  return (
    <DataContainer data={referenceRaw}>
      <MarginBox mb={15}>
        {isMaintenance ? (
          <IAMReferenceCardCompact
            reference={reference}
            rawLabels={rawLabels}
            additionalInformationItems={additionalInformationItems}
            sparePartsView={sparePartsView}
            selected={selected}
            onSelect={onSelect}
            handleAddToCart={handleAddToCartClick}
          />
        ) : (
          <IAMReferenceCard
            referenceNumber={reference.referenceNumber}
            name={displayName}
            supplier={reference.supplier}
            rawLabels={rawLabels}
            additionalInformationItems={additionalInformationItems}
            secondaryName={[...tradeNumberLabels, ...gtinLabels].filter(Boolean).join(' / ')}
            brand={reference.brand}
            price={referencePrice}
            sparePartsView={sparePartsView}
            vehicleKey={vehicleKey}
            brandImage={reference?.brandImageUrl}
            referenceImage={imageUrl}
            laborTimesWrapper={laborTimesWrapper}
            position={refPosition}
            documents={reference.documents ?? []}
            isIamSearchResult={isIamSearchResult}
            selected={selected}
            isMaintenance={isMaintenance}
            onSelect={onSelect}
            replacingReferences={reference.replacingReferences ?? []}
            substitutedReferences={reference.substitutedReferences ?? []}
            origin={reference.origin}
            supplierCode={reference.supplierCode}
            referenceSource={reference.referenceSource}
            handleAddToCart={handleAddToCartClick}
            linkedReferences={reference.linkedReferences?.map((l) => l.referenceNumber)}
          />
        )}
      </MarginBox>
    </DataContainer>
  );
};

export default IAMPlateReferenceCard;
