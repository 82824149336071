/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchRoute, ROUTER_CATALOG_DH, ROUTER_CATALOG_VEHICLE, ROUTER_PRODUCT } from 'app/AppRouter';
import { trackAppEvent } from 'app/AppTracker';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import QuantityBox from 'components/QuantityBox';
import QuantityModule from 'components/QuantityModule';
import { QuantityModuleUpdateComponentType } from 'components/QuantityModule/QuantityModule';
import StockDisplay from 'components/StockInfo';
import {
  addOtherSectionReferenceRequest,
  getBasketOtherSection,
  getBasketVehicles,
  updateReferenceQuantityRequest,
} from 'domains/basket/Basket.store';
import {
  addReferenceFromCatalogToCart,
  getLastVehicleDetail,
  removeCartReferenceInCatalog,
} from 'domains/catalog/Catalog.store';
import { DHReferenceLocal } from 'domains/references';
import { useFetchFullTradingData, usePrice } from 'domains/references/References.requests';
import { GarageView, getSparePartsView } from 'domains/user';
import { BrandImage } from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/BrandImage';
import { BlackButton, Box, Flex, Link, MarginBox, Text, WithTooltip, YellowCartArrowDownButton } from 'UI';
import { getSearchData, textFormatter } from 'utils';
import {
  TRACKING_EVENT_ADD_CROSS_SELLING_TO_CART,
  TRACKING_EVENT_CART_PART_QUANTITY_ZERO,
  TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION,
  TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
} from 'utils/eventTracker/EventTracker.types';
import { ReferenceCardWrapper, SFlex } from './CrossSellingSection.styled';

const CrossReferenceCard = ({ reference, type }: { reference: DHReferenceLocal; type: 'catalog' | 'product' }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { referenceNumber } = reference;
  const price = getSearchData(usePrice(referenceNumber));
  const sparePartsView = useSelector(getSparePartsView);
  const vehicle = useSelector(getLastVehicleDetail);
  const vehicleKey = vehicle?.vehicleKey;
  const basketVehicles = useSelector(getBasketVehicles);
  const basketOtherSection = useSelector(getBasketOtherSection);
  const referencesOfCurrentVeh = basketVehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)
    ?.references;

  useFetchFullTradingData(referenceNumber, vehicleKey);

  const isApplicableOrInCurrVehCart = () => {
    if (vehicleKey) {
      return (
        reference.isApplicableToCurrentVehicle ||
        referencesOfCurrentVeh?.find((ref) => ref.referenceNumber === referenceNumber)
      );
    } else {
      return false;
    }
  };
  const getBasketReferences = () =>
    isApplicableOrInCurrVehCart() ? referencesOfCurrentVeh : basketOtherSection?.references;
  const basketReferences = getBasketReferences();
  const refQuantity = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber)?.quantity as number;

  const handleAddToCart = () => {
    if (price?.garageView?.vatExcludedPrice && price.garageView?.vatIncludedPrice) {
      trackAppEvent(TRACKING_EVENT_ADD_CROSS_SELLING_TO_CART);
      if (vehicleKey && vehicle && reference.isApplicableToCurrentVehicle) {
        dispatch(addReferenceFromCatalogToCart({ referenceNumber }));
      } else {
        dispatch(
          addOtherSectionReferenceRequest({
            reference,
          }),
        );
      }
    }
  };

  function quantityCallback(quantity: number | string, componentType?: QuantityModuleUpdateComponentType) {
    if (quantity === 0) {
      dispatch(
        removeCartReferenceInCatalog({
          basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
          vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
          referenceNumber,
          referenceType: reference.type,
          origin: undefined,
          supplierCode: undefined,
          referenceSource: 'STANDARD',
        }),
      );
      if (componentType === 'INPUT') {
        trackAppEvent(TRACKING_EVENT_CART_PART_QUANTITY_ZERO);
      } else {
        trackAppEvent(
          matchRoute(location.pathname, ROUTER_PRODUCT)
            ? TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION
            : TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
        );
      }
    } else {
      dispatch(
        updateReferenceQuantityRequest({
          basketReferenceType: isApplicableOrInCurrVehCart() ? 'VEHICLE' : 'OTHER',
          vehicleKey: isApplicableOrInCurrVehCart() ? vehicleKey : undefined,
          externalBasketId: undefined,
          referenceNumber,
          newQuantity: quantity as number,
          referenceType: reference.type,
        }),
      );
    }
  }

  const renderImage = () => (
    <BrandImage
      referenceBrand={reference.brand}
      vehicleBrand={vehicle?.vehicleBrand ?? 'OTHER'}
      size={type === 'product' ? 210 : 82}
    />
  );

  const renderRefNumber = () => (
    <Flex>
      <Link to={`${ROUTER_CATALOG_DH}/${vehicleKey}${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/${referenceNumber}`}>
        <Text type={'mobile_h3'} hoverUnderLine>
          <Trans i18nKey={'catalog.reference.referenceNumber.short'} tOptions={{ referenceNumber }}>
            Ref: {{ referenceNumber }}
          </Trans>
        </Text>
      </Link>
      <MarginBox mr={5} />
      <CopyToClipboardButton
        textToCopy={referenceNumber}
        message={t(
          'catalog.reference_card.reference_number.copied_to_clipboard',
          'Reference number {{referenceNumber}} copied to clipboard',
          { referenceNumber },
        )}
      />
    </Flex>
  );

  const renderProductName = () => (
    <WithTooltip
      placement={'topLeft'}
      title={
        <Text type={'dark_10_white'} transform={'sentence-case'} noWrap ellipsis>
          {reference.name}
        </Text>
      }
    >
      <Text type={'light_10_black_65'} transform={'sentence-case'} noWrap ellipsis>
        {reference.name}
      </Text>
    </WithTooltip>
  );

  const renderStockDisplay = () => (
    <StockDisplay
      small={type === 'catalog'}
      isApplicableToCurrentVehicle={reference.isApplicableToCurrentVehicle ?? false}
      vehicleKey={vehicleKey}
      referenceNumber={referenceNumber}
      narrow={type === 'product'}
    />
  );

  const renderPrices = () => {
    const priceFirstLine =
      sparePartsView === GarageView
        ? t('common.price.vat_exclude_price', '{{vat_exclude_price}} VAT. Excl', {
            vat_exclude_price: textFormatter.formatCurrency(
              Number(price?.garageView?.vatExcludedPrice),
              price?.currency ?? '',
            ),
          })
        : t('common.price.vat_include_price', '{{vat_include_price}} VAT. Incl', {
            vat_include_price: textFormatter.formatCurrency(
              Number(price?.clientView?.recommendedPriceVatIncluded),
              price?.currency ?? '',
            ),
          });
    const priceSecondLine =
      sparePartsView === GarageView
        ? t('common.price.or_vat_include_price', 'or {{vat_include_price}} VAT. Incl', {
            vat_include_price: textFormatter.formatCurrency(
              Number(price?.garageView?.vatIncludedPrice),
              price?.currency ?? '',
            ),
          })
        : t('common.price.or_vat_exclude_price', 'or {{vat_exclude_price}} VAT. Excl', {
            vat_exclude_price: textFormatter.formatCurrency(
              Number(price?.clientView?.recommendedPriceVatExcluded),
              price?.currency ?? '',
            ),
          });
    const priceBasic =
      sparePartsView === GarageView
        ? price?.garageView?.vatExcludedPrice
        : price?.clientView?.recommendedPriceVatIncluded;
    switch (type) {
      case 'catalog':
        return (
          <Text type={'text_bold'}>{textFormatter.formatCurrency(Number(priceBasic), price?.currency ?? '')}</Text>
        );
      case 'product':
      default:
        return (
          <>
            <Text type={'text_bold'}>{priceFirstLine}</Text>
            <Text type={'light_dimmer'}>{priceSecondLine}</Text>
          </>
        );
    }
  };

  const renderCartActions = () => {
    const isInBasket = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber);
    switch (type) {
      case 'catalog':
        return (
          <>
            {isInBasket ? (
              <QuantityBox mini value={refQuantity} onChange={(quantity) => quantityCallback(quantity, 'INPUT')} />
            ) : (
              <YellowCartArrowDownButton onClick={handleAddToCart} />
            )}
          </>
        );
      case 'product':
      default:
        return (
          <>
            {isInBasket ? (
              <QuantityModule value={refQuantity} onChange={quantityCallback} showDelete />
            ) : (
              <BlackButton onClick={handleAddToCart}>{t('cart.action.add_to_cart', 'Add to cart')}</BlackButton>
            )}
          </>
        );
    }
  };

  const renderReferenceCard = () => {
    switch (type) {
      case 'catalog':
        return renderCatalogCrossSelling();
      case 'product':
        return renderProductCrossSelling();
    }
  };

  const renderCatalogCrossSelling = () => {
    return (
      <ReferenceCardWrapper type={'catalog'}>
        {renderImage()}
        <SFlex direction={'column'}>
          {renderRefNumber()}
          {renderProductName()}
          <Flex />
          <Flex direction={'row'} align={'center'}>
            {renderStockDisplay()}
            <Box width={5} />
            {renderPrices()}
            <Flex direction={'row-reverse'}>{renderCartActions()}</Flex>
          </Flex>
        </SFlex>
      </ReferenceCardWrapper>
    );
  };

  const renderProductCrossSelling = () => {
    return (
      <ReferenceCardWrapper type={'product'}>
        <Flex direction={'column'} maxWidth={210}>
          {renderImage()}
          <Box height={15} />
          {renderRefNumber()}
          {renderProductName()}
          {renderStockDisplay()}
          {renderPrices()}
          <Box height={15} />
          {renderCartActions()}
        </Flex>
      </ReferenceCardWrapper>
    );
  };

  return renderReferenceCard();
};

export default CrossReferenceCard;
