import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderDeliveryType } from '@1po/1po-bff-fe-spec/generated/basket/request/CreateOrderFromBasket';
import { ReferenceDiscount } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceDiscount';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import bigDecimal from 'js-big-decimal';
import { RootState } from 'app/AppStore';
import { calculatePriceAfterDiscount } from 'components/Discount';
import {
  getEstimateById,
  getEstimateOrderItemDetails,
  orderFromEstimateB2BRequest,
  setEstimateOrderMktpDeliveryMode,
} from 'domains/estimate/Estimate.store';
import {
  getDefaultDeliveryAddress,
  getDefaultPaymentMethod,
  setDefaultValidationDeliveryAddressSaga,
  setDefaultValidationPaymentMethodSaga,
} from 'domains/orderValidation';
import { getDiscounts, getPricesMap, ReferencePriceType } from 'domains/references';
import { getCurrency, getPossibleDelegateSessionBuyers, getUserCommercialLink } from 'domains/user';
import CheckoutCardSection from 'pages/CartPage/CartStep/CheckoutCardSection';
import BillingAccountSection from 'pages/CartPage/ValidationStep/BillingAccountSection';
import DeliveryAddressSection from 'pages/CartPage/ValidationStep/DeliveryAddressSection';
import { getBillingAccountOptions } from 'pages/CartPage/ValidationStep/ValidationStep';
import { Flex } from 'UI';
import { getData, getSearchData, SearchData } from 'utils';
import { ReferenceMap } from 'utils/dataStructure/ReferenceMap';

export const getTotalDiscountPrice = (
  references: Reference[],
  prices: ReferenceMap<SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  const totalWithoutDiscounts = references.reduce((total, ref) => {
    const price = getSearchData(prices.get(ref.referenceNumber));
    const itemPrice = Number(price?.garageView?.vatExcludedPrice) * ref.quantity;
    return total.add(new bigDecimal(itemPrice));
  }, new bigDecimal(0));
  return totalWithoutDiscounts.subtract(getTotalVatExclPriceForClient(references, prices, discounts)).getValue();
};

export const getTotalVatExclPriceForClient = (
  references: Reference[],
  prices: ReferenceMap<SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  return references.reduce((total, ref) => {
    const price = getSearchData(prices.get(ref.referenceNumber));
    const itemPrice = calculatePriceAfterDiscount(discounts.get(ref.referenceNumber), price, false) * ref.quantity;
    return total.add(new bigDecimal(itemPrice));
  }, new bigDecimal(0));
};

export const getTotalVatInclPriceForClient = (
  references: Reference[],
  prices: ReferenceMap<SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  return references.reduce((total, ref) => {
    const price = getSearchData(prices.get(ref.referenceNumber));
    const itemPrice = calculatePriceAfterDiscount(discounts.get(ref.referenceNumber), price, true) * ref.quantity;
    return total.add(new bigDecimal(itemPrice));
  }, new bigDecimal(0));
};

export const getTotalVatPriceForClient = (
  references: Reference[],
  prices: ReferenceMap<SearchData<ReferencePriceType>>,
  discounts: Map<string, ReferenceDiscount>,
) => {
  return getTotalVatInclPriceForClient(references, prices, discounts).subtract(
    getTotalVatExclPriceForClient(references, prices, discounts),
  );
};

const OrderEstimateValidationStep = ({ estimateId, references }: { estimateId: string; references: Reference[] }) => {
  const dispatch = useDispatch();
  const estimate = getData(useSelector((state: RootState) => getEstimateById(state, estimateId)));
  const currency = useSelector(getCurrency);
  const isAnyMktpReference =
    estimate?.referenceSubsection?.references.some((r) => r.referenceSource === 'MARKETPLACE') ?? false;
  const defaultDeliveryAddress = useSelector(getDefaultDeliveryAddress);
  const defaultPaymentMethod = useSelector(getDefaultPaymentMethod);
  const estimateOrderDetails = useSelector(getEstimateOrderItemDetails);
  const commercialLink = useSelector(getUserCommercialLink);
  const delegationBuyers = useSelector((state: RootState) =>
    getPossibleDelegateSessionBuyers(state, commercialLink?.tresorCode),
  )?.data;
  const buyerData = delegationBuyers?.find((d) => d.tresorCode === estimate?.clientContact.tresorCode);
  const billingOptions = buyerData ? getBillingAccountOptions(buyerData) : undefined;
  const discounts = useSelector((state: RootState) =>
    getDiscounts(
      state,
      references.map((r) => r.referenceNumber),
      estimate?.garageId,
      estimate?.clientContact.tresorCode,
    ),
  );

  const prices = useSelector((state: RootState) =>
    getPricesMap(
      state,
      references.map((r) => r.referenceNumber),
      estimate?.garageId ?? '',
      estimate?.clientContact.tresorCode ?? '',
    ),
  );

  const totalReferences =
    (estimate?.referenceSubsection.references.length ?? 0) + (estimate?.tireSubsection.references.length ?? 0);
  const updateDeliveryAddress = (address: OrderDeliveryType) => {
    dispatch(setDefaultValidationDeliveryAddressSaga(address));
  };

  const updateBillingAccount = (account: string) => {
    dispatch(setDefaultValidationPaymentMethodSaga(account));
  };

  const onCheckout = () => {
    dispatch(orderFromEstimateB2BRequest({ estimateId }));
  };

  useEffect(() => {
    if (!defaultPaymentMethod && buyerData) {
      updateBillingAccount(buyerData.primaryClientCode);
    }
    // eslint-disable-next-line
  }, [buyerData]);

  return (
    <Flex direction={'column'} gap={15}>
      <DeliveryAddressSection
        validationDeliveryAddress={defaultDeliveryAddress}
        updateDeliveryAddress={updateDeliveryAddress}
        orderMKTPDeliveryMode={estimateOrderDetails.mktpDeliveryMode}
        basketHasMKTPReference={isAnyMktpReference}
        updateOrderMKTPDeliveryMode={(v) => dispatch(setEstimateOrderMktpDeliveryMode(v))}
        activeSeller={commercialLink?.seller ?? null}
        compact
      />
      <BillingAccountSection
        updateBillingAccount={updateBillingAccount}
        billingOptions={billingOptions}
        selectedBillingAccount={defaultPaymentMethod ?? buyerData?.primaryClientCode}
        compact
      />
      <CheckoutCardSection
        totalPriceVatIncluded={getTotalVatInclPriceForClient(references, prices, discounts).getValue()}
        totalPriceVatExcluded={getTotalVatExclPriceForClient(references, prices, discounts).getValue()}
        totalDiscount={Number(getTotalDiscountPrice(references, prices, discounts))}
        totalVat={getTotalVatPriceForClient(references, prices, discounts).getValue()}
        vehicles={[]}
        externalBaskets={[]}
        currency={currency}
        onCheckout={onCheckout}
        estimateTotalReferences={totalReferences}
      />
    </Flex>
  );
};

export default OrderEstimateValidationStep;
