import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getEstimateById, getRealEstimateReferencesCount } from 'domains/estimate/Estimate.store';
import { getDiscounts, getPricesMap } from 'domains/references';
import { getCurrency } from 'domains/user';
import {
  getTotalDiscountPrice,
  getTotalVatExclPriceForClient,
  getTotalVatInclPriceForClient,
  getTotalVatPriceForClient,
} from 'pages/EstimatePage/OrderEstimate/OrderEstimateValidationStep';
import { Divider, Flex, LightCard, Text } from 'UI';
import { getData } from 'utils';
import { textFormatter } from 'utils/format';

export interface TotalSectionProps {
  estimateId: string;
}

const TotalSection = ({ estimateId }: TotalSectionProps) => {
  const { t } = useTranslation();
  const numberOfItems = useSelector((state: RootState) => getRealEstimateReferencesCount(state, estimateId));
  const estimateData = getData(useSelector((state: RootState) => getEstimateById(state, estimateId)));
  const references = estimateData?.referenceSubsection.references || [];
  const tires = estimateData?.tireSubsection.references || [];
  const refNumbers = references.map((r) => r.referenceNumber).concat(tires.map((tire) => tire.referenceNumber));
  const prices = useSelector((state: RootState) =>
    getPricesMap(state, refNumbers, estimateData?.garageId, estimateData?.clientContact.tresorCode),
  );
  const discounts = useSelector((state: RootState) =>
    getDiscounts(state, refNumbers, estimateData?.garageId, estimateData?.clientContact.tresorCode),
  );
  const totalVatExclPrice = getTotalVatExclPriceForClient(references, prices, discounts);
  const totalVatInclPrice = getTotalVatInclPriceForClient(references, prices, discounts);
  const totalVatPrice = getTotalVatPriceForClient(references, prices, discounts);
  const totalDiscountPrice = getTotalDiscountPrice(references, prices, discounts);
  const currency = useSelector(getCurrency);
  return (
    <LightCard
      title={<Trans i18nKey="estimate.pdf.estimate_total_items">Estimate total: {{ numberOfItems }} items</Trans>}
    >
      <Flex>
        <Text type={'section'}>{`${t('common.price.total_VAT_excl', 'Total VAT. Excl')}:`}</Text>
        <Flex />
        <Text type={'section'}>{textFormatter.formatCurrency(totalVatExclPrice.getValue(), currency)}</Text>
        <Text type={'section'}>{}</Text>
      </Flex>
      <Flex>
        <Text type={'section'}>{t('common.price.vat', 'VAT:')}</Text>
        <Flex />
        <Text type={'section'}>{textFormatter.formatCurrency(totalVatPrice.getValue(), currency)}</Text>
        <Text type={'section'}>{}</Text>
      </Flex>
      {Number(totalDiscountPrice) > 0 && (
        <Flex>
          <Text type={'section'}>{`${t('common.discount', 'Discount')}:`}</Text>
          <Flex />
          <Text type={'section'}>{textFormatter.formatCurrency(totalDiscountPrice, currency)}</Text>
        </Flex>
      )}
      <Divider />
      <Flex>
        <Text type={'price_tag'}>{`${t('common.price.total_vat_incl', 'Total VAT. Incl')}:`}</Text>
        <Flex />
        <Text type={'price_tag'}>{textFormatter.formatCurrency(totalVatInclPrice.getValue(), currency)}</Text>
        <Text type={'price_tag'}>{}</Text>
      </Flex>
    </LightCard>
  );
};

export default TotalSection;
