import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  CheckoutOverallStatus,
  OrderCheckoutResult,
  VehicleDetail,
} from '@1po/1po-bff-fe-spec/generated/order/response/CheckoutOrderResponse';
import { OrderStatusType } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { TFunction } from 'i18next';
import { ROUTER_HOME, ROUTER_IN_PROGRESS_ORDERS } from 'app/AppRouter';
import {
  ArrowUpRightIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  InfoCircleIcon,
  TimesCircleIcon,
} from 'assets/icons';
import B2BComponent from 'components/B2BComponents/B2BComponent';
import Loader from 'components/Loader';
import { getCheckoutStatus } from 'domains/order/Order.store';
import {
  resetValidationDeliveryAddressSaga,
  resetValidationInstructionsSaga,
  resetValidationPaymentMethodSaga,
} from 'domains/orderValidation/OrderValidation.store';
import { theme } from 'styles';
import { BlackButton, Box, Flex, Grid, Icon, MarginBox, Text, WhiteButton } from 'UI';
import VehicleFlag from 'UI/Icon/VehicleFlag';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { AlignLeft, AlignRight, ConfirmationDivider, ValidationStatusWrapper } from './ConfirmationStep.styled';

const getColor = (status: CheckoutOverallStatus) => {
  switch (status) {
    case 'PLACED':
      return theme.color.selected;
    case 'R1_REVIEW':
      return theme.color.info;
    case 'NOT_PLACED':
      return theme.color.error;
    case 'NOT_EVERYTHING_PLACED':
      return theme.color.warning;
    default:
      return theme.color.info;
  }
};

const getItemColor = (status: GroupStatusType, externalBasketId: string | undefined) => {
  if (externalBasketId) {
    return theme.color.grey45;
  }
  switch (status) {
    case 'CREATED':
      return theme.color.selected;
    case 'R1_REVIEW':
      return theme.color.info;
    case 'NOT_PLACED':
      return theme.color.error;
    default:
      return theme.color.info;
  }
};

const getIcon = (status: CheckoutOverallStatus) => {
  switch (status) {
    case 'PLACED':
      return CheckCircleIcon;
    case 'R1_REVIEW':
      return InfoCircleIcon;
    case 'NOT_PLACED':
      return TimesCircleIcon;
    case 'NOT_EVERYTHING_PLACED':
      return ExclamationCircleIcon;
    default:
      return InfoCircleIcon;
  }
};

const getItemIcon = (status: GroupStatusType, externalBasketId: string | undefined) => {
  if (externalBasketId) {
    return ArrowUpRightIcon;
  }
  switch (status) {
    case 'CREATED':
      return CheckCircleIcon;
    case 'R1_REVIEW':
      return InfoCircleIcon;
    case 'NOT_PLACED':
      return TimesCircleIcon;
    default:
      return InfoCircleIcon;
  }
};

const getTitle = (t: TFunction, status: CheckoutOverallStatus) => {
  switch (status) {
    case 'PLACED':
      return t('order.confirmation.status.success', 'Thank you for your order');
    case 'R1_REVIEW':
      return t('order.confirmation.status.review', 'Action required');
    case 'NOT_PLACED':
      return t('order.confirmation.status.error', 'Error placing order');
    case 'NOT_EVERYTHING_PLACED':
      return t('order.confirmation.status.incomplete', 'Incomplete order validation');
    default:
      return '';
  }
};

const getSubtitle = (t: TFunction, status: CheckoutOverallStatus) => {
  switch (status) {
    case 'PLACED':
      return t('order.confirmation.status.success.description', 'Your order is being processed by our delivery team.');
    case 'R1_REVIEW':
      /* eslint-disable max-len */
      return t(
        'order.confirmation.status.review.description',
        'Your order is under your R1 Dealer review. You\'ll be able to verify if your order has been validated or declined in " My order."',
      );
    case 'NOT_PLACED':
      return t(
        'order.confirmation.status.error.description',
        'Sorry, an error occured while placing your order, please try again later.',
      );
    case 'NOT_EVERYTHING_PLACED':
      return t(
        'order.confirmation.status.incomplete.description',
        "Some of your orders couldn't be validated, go to My orders to verify your order status.",
      );
    default:
      return '';
  }
};

const ValidationStatus = ({ status }: { status: CheckoutOverallStatus }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const goHome = useCallback(() => history.push(ROUTER_HOME), [history]);
  const goOrders = useCallback(() => history.push(ROUTER_IN_PROGRESS_ORDERS), [history]);
  const icon = getIcon(status);
  const color = getColor(status);

  return (
    <ValidationStatusWrapper status={status}>
      <MarginBox my={25}>
        {icon && <Icon IconComponent={icon} color={color} background={'transparent'} size={80} />}
      </MarginBox>
      <Text type={'h2'} dataCy={'message-confirmation'}>
        {getTitle(t, status)}
      </Text>
      <MarginBox mt={8}>
        <Text type={'light_14_black_45'} dataCy={'sub-message-confirmation'}>
          {getSubtitle(t, status)}
        </Text>
      </MarginBox>
      <B2BComponent
        b2c={
          <MarginBox my={25}>
            <Flex>
              <WhiteButton onClick={goOrders} size={'middle'} dataCy={'button-my-orders'}>
                {t('order.my_orders', 'My Orders')}
              </WhiteButton>
              <Box width={2} />
              <BlackButton onClick={goHome} size={'middle'} dataCy={'button-home'}>
                {t('common.home', 'Home')}
              </BlackButton>
            </Flex>
          </MarginBox>
        }
      />
      <Box height={35} />
    </ValidationStatusWrapper>
  );
};

const renderCheckoutVehicleDescription = (
  t: TFunction,
  vehicle: VehicleDetail,
  externalBasketId: string | undefined,
  sia: string | undefined,
) => {
  if (externalBasketId) {
    return t('order.sia_external_order', '{{sia}} External order', { sia });
  }

  if (!vehicle) {
    return t('cart.other_articles', 'Other articles in your cart');
  }

  if (vehicle.vin)
    return (
      <Flex>
        {vehicle.dataHubVehicle?.name ??
          `${vehicle.vehicleBrand ?? ''} ${vehicle.model ?? ''} ${vehicle.iamVehicle?.phase ?? ''}`}
        <MarginBox mr={6} />
        <VehicleFlag vehicle={vehicle} />
        <MarginBox mr={6} />
        {vehicle.vrn}
      </Flex>
    );
  else {
    return `${
      vehicle.dataHubVehicle?.name ??
      `${vehicle.vehicleBrand ?? ''} ${vehicle.model ?? ''} ${vehicle.iamVehicle?.phase ?? ''}`
    }`;
  }
};

const ConfirmationItem = ({
  vehicle,
  orderId,
  groupStatusType,
  externalBasketId,
  sia,
  errorReason,
  orderStatusType,
}: {
  vehicle: VehicleDetail;
  orderId?: string;
  groupStatusType: GroupStatusType;
  externalBasketId: string | undefined;
  sia: string | undefined;
  errorReason: string | undefined;
  orderStatusType: OrderStatusType;
}) => {
  const { t } = useTranslation();
  const icon = getItemIcon(groupStatusType, externalBasketId);
  const color = getItemColor(groupStatusType, externalBasketId);
  return (
    <MarginBox my={15}>
      <Flex>
        <MarginBox mx={15} mt={5}>
          {icon && <Icon IconComponent={icon} color={color} background={'white'} />}
        </MarginBox>
        <Flex direction={'column'} justify={'center'}>
          <AlignLeft>
            <Text type={'text'}>{renderCheckoutVehicleDescription(t, vehicle, externalBasketId, sia)}</Text>
          </AlignLeft>
          {vehicle && vehicle.vin && (
            <AlignLeft>
              <Text type={'light_12_black_65'}>VIN: {vehicle.vin}</Text>
            </AlignLeft>
          )}
          {vehicle && !vehicle.vin && (
            <AlignLeft>
              <Text type={'light_12_black_65'}>{`${vehicle.dataHubVehicle?.modelType}`}</Text>
            </AlignLeft>
          )}
        </Flex>
        <Flex />
        {errorReason && (
          <MarginBox mt={15}>
            <strong>{errorReason}</strong>
          </MarginBox>
        )}
        {!errorReason && orderId && (
          <Box>
            <AlignRight>
              <Text type={'text'}>
                <strong>{t('order', 'Order')}</strong>
              </Text>
            </AlignRight>
            <AlignRight>
              <Text type={'text'}>
                <strong>
                  {orderStatusType === 'MAYBE_PLACED'
                    ? t('my_orders.details.order_processing_after_timeout', 'Processing')
                    : orderId}
                </strong>
              </Text>
            </AlignRight>
          </Box>
        )}
      </Flex>
    </MarginBox>
  );
};

type GroupStatusType = 'CREATED' | 'NOT_PLACED' | 'R1_REVIEW';

const getGroupTitle = (status: GroupStatusType) => {
  switch (status) {
    case 'CREATED':
      return <Trans i18nKey={'order.confirmation.status.success.orders'}>Orders successfully placed</Trans>;
    case 'NOT_PLACED':
      return (
        <Trans i18nKey={'order.confirmation.status.error.orders'}>The following orders have not been placed</Trans>
      );
    case 'R1_REVIEW':
      return <Trans i18nKey={'order.confirmation.status.review.orders'}>Orders currently under R1 Review</Trans>;
  }
};

const ConfirmationGroup = ({
  status,
  group,
}: {
  status: GroupStatusType;
  group: OrderCheckoutResult[] | undefined;
}) => {
  if (!group || group?.length === 0) {
    return null;
  }
  const isDivider = (index: number) =>
    (group.length % 2 === 0 && index + 2 < group.length) || (group.length % 2 === 1 && index + 1 < group.length);

  return (
    <>
      <AlignLeft>
        <Text type={'h2'}>{getGroupTitle(status)}</Text>
      </AlignLeft>
      <Box height={30} />
      <Flex direction={'column'}>
        <Grid columns={[1, 2]} gutter={30}>
          {group.map((order, index) => (
            <React.Fragment key={`confirmation-item-${index}`}>
              <ConfirmationItem
                groupStatusType={status}
                key={`confirmation-item-${index}`}
                vehicle={order.vehicleDetail}
                orderId={order.orderId}
                externalBasketId={order.externalBasketId}
                sia={order.sia}
                orderStatusType={order.status}
                errorReason={order.errorReason}
              />
              {isDivider(index) && <ConfirmationDivider />}
            </React.Fragment>
          ))}
        </Grid>
      </Flex>
      <Box height={60} />
    </>
  );
};

const ConfirmationStep = ({ noReset = false, noDetail = false }: { noReset?: boolean; noDetail?: boolean }) => {
  const dispatch = useDispatch();
  const checkoutStatus = useSelector(getCheckoutStatus);
  const placedGroup: OrderCheckoutResult[] | undefined = checkoutStatus?.placedOrders?.filter(
    ({ status }) => status === 'IN_PROGRESS',
  );
  const failedGroup: OrderCheckoutResult[] | undefined = checkoutStatus?.failedOrders;
  const reviewGroup: OrderCheckoutResult[] | undefined = checkoutStatus?.placedOrders?.filter(
    ({ status }) => status === 'CREATED',
  );
  const orderOverallStatus: CheckoutOverallStatus | undefined = checkoutStatus?.ordersOverallStatus;
  checkoutStatus?.placedOrders.forEach((order) => {
    const vehicleKey = order.vehicleDetail?.vehicleKey;
    const externalBasketId = order.externalBasketId;
    if (!noReset) {
      dispatch(resetValidationPaymentMethodSaga({ vehicleKey, externalBasketId }));
      dispatch(resetValidationDeliveryAddressSaga({ vehicleKey, externalBasketId }));
      dispatch(resetValidationInstructionsSaga({ vehicleKey, externalBasketId }));
    }
  });

  useResetScroll();

  return (
    <>
      {orderOverallStatus ? <ValidationStatus status={orderOverallStatus} /> : <Loader />}
      {!noDetail && (
        <MarginBox mt={30}>
          <Flex direction={'column'}>
            <ConfirmationGroup group={failedGroup} status={'NOT_PLACED'} />
            <ConfirmationGroup group={reviewGroup} status={'R1_REVIEW'} />
            <ConfirmationGroup group={placedGroup} status={'CREATED'} />
          </Flex>
        </MarginBox>
      )}
    </>
  );
};

export default ConfirmationStep;
