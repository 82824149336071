import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import QuantityBox from 'components/QuantityBox';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { getCreateOrderList, resetOrderListReference, setOrderListReference } from 'domains/orderList/OrderList.store';
import { CreateOrderList, OrderListReferenceLocal } from 'domains/orderList/OrderList.types';
import { getDHReference } from 'domains/references';
import { STable } from 'pages/EstimatePage/EstimatePage.styled';
import { GetTitle } from 'pages/EstimatePage/TableSection/tableComponents';
import { Box, Flex, Input, Spin, Text, TrashAltButton } from 'UI';
import { ERROR, getData, hasData, LOADING, NOT_FOUND } from 'utils';

const OrderListReferenceDesignation = ({ referenceNumber }: { referenceNumber?: string }) => {
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey: undefined, referenceNumber }),
  );
  const referenceData = getData(reference);
  return (
    <Flex justify={'center'} align={'center'}>
      {hasData(reference) && (
        <Text type={'light_12'} ellipsis>
          {referenceData?.name}
        </Text>
      )}
    </Flex>
  );
};

const OrderListQuantityModule = ({
  orderListReference,
  quantity,
}: {
  quantity?: number;
  orderListReference: OrderListReferenceLocal;
}) => {
  const dispatch = useDispatch();
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey: undefined, referenceNumber: orderListReference.referenceNumber }),
  );
  const referenceData = getData(reference);

  const onQuantityChange = useCallback(
    (qty: string | number) => {
      dispatch(
        setOrderListReference({
          index: orderListReference.index,
          reference: {
            ...orderListReference,
            quantity: Number(qty),
          },
        }),
      );
    },
    [dispatch, orderListReference],
  );

  useEffect(() => {
    if (referenceData && quantity === 0) {
      onQuantityChange(1);
    }
  }, [referenceData, quantity, onQuantityChange]);

  return (
    <Flex justify={'center'} align={'center'}>
      <QuantityBox value={quantity ?? 0} onChange={onQuantityChange} maxValue={9999} minValue={1} />
    </Flex>
  );
};

export function getListRefOccurrences(orderList: CreateOrderList, referenceNumber?: string) {
  return orderList.references.filter((ref) => ref.referenceNumber === referenceNumber).length;
}

const OrderListReferenceNumber = ({
  orderListReference,
  handleUnknownReferenceChange,
}: {
  orderListReference: OrderListReferenceLocal;
  handleUnknownReferenceChange: (referenceIndex: number, action: 'add' | 'remove') => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderList = useSelector(getCreateOrderList);
  const count = getListRefOccurrences(orderList, orderListReference.referenceNumber);
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey: undefined, referenceNumber: orderListReference.referenceNumber }),
  );
  const [referenceErrorMessage, setReferenceErrorMessage] = useState<string | undefined>(undefined);

  useFetchSingleReference(orderListReference.referenceNumber, null, undefined);

  const handleReferenceChange = (referenceNumber: string) => {
    dispatch(
      setOrderListReference({
        index: orderListReference.index,
        reference: {
          ...orderListReference,
          referenceNumber: referenceNumber.length > 0 ? referenceNumber : undefined,
        },
      }),
    );
    if (referenceNumber.length === 0) {
      handleUnknownReferenceChange(orderListReference.index, 'remove');
    }
  };

  useEffect(() => {
    if (reference === NOT_FOUND || reference === ERROR) {
      handleUnknownReferenceChange(orderListReference.index, 'add');
    } else if (hasData(reference)) {
      handleUnknownReferenceChange(orderListReference.index, 'remove');
    }
    // eslint-disable-next-line
  }, [orderListReference.index, reference]);

  useEffect(() => {
    if (reference === LOADING) {
      return;
    }
    if (orderListReference.referenceNumber === undefined) {
      setReferenceErrorMessage(undefined);
    } else if (count > 1) {
      setReferenceErrorMessage(
        `${t('common.form.validation.reference.already_in_list', 'This reference is already part of this list.')}`,
      );
    } else if (reference === NOT_FOUND || reference === ERROR) {
      setReferenceErrorMessage(
        `${t('common.form.validation.reference.unknown_reference', 'Unknown reference number.')}`,
      );
    } else if (count <= 1) {
      setReferenceErrorMessage(undefined);
    }
    // eslint-disable-next-line
  }, [t, count, orderListReference.referenceNumber, reference]); // focused intentionally left out

  const inputSuffix = reference === LOADING ? <Spin size={'small'} /> : <span />;

  return (
    <Flex direction={'column'} justify={'center'} align={'flex-start'}>
      <Box height={12} />
      <Input
        value={orderListReference.referenceNumber}
        onChange={(value) => handleReferenceChange(value.trim().toUpperCase())}
        placeholder={t('catalog.order_list.reference_number_here', 'Reference number here')}
        status={referenceErrorMessage ? 'error' : undefined}
        maxLength={63}
        suffix={inputSuffix}
        bordered
      />
      <Box height={12} align={'left'}>
        <Flex overflowY={'hidden'}>
          <Text type={'light_12'} displayStyle={'error'} ellipsis noWrap>
            {referenceErrorMessage}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};

const OrderListTable = ({
  references,
  handleUnknownReferenceChange,
}: {
  references: OrderListReferenceLocal[];
  handleUnknownReferenceChange: (referenceIndex: number, action: 'add' | 'remove') => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tableColumns = [
    {
      title: GetTitle(t('common.designation', 'Designation')),
      dataIndex: 'name',
      width: '30%',
      render: function rowSelector(_name: string, row: OrderListReferenceLocal) {
        return <OrderListReferenceDesignation referenceNumber={row?.referenceNumber} />;
      },
    },
    {
      title: GetTitle(t('common.quantity', 'Quantity')),
      dataIndex: 'quantity',
      width: 115,
      render: function rowSelector(quantity: number | undefined, row: OrderListReferenceLocal) {
        return <OrderListQuantityModule quantity={quantity} orderListReference={row} />;
      },
    },
    {
      title: GetTitle(t('order.reference_table.reference_number', 'Reference number')),
      dataIndex: 'referenceNumber',
      width: '45%',
      render: function rowSelector(_referenceNumber: string, row: OrderListReferenceLocal) {
        return (
          <OrderListReferenceNumber
            orderListReference={row}
            handleUnknownReferenceChange={handleUnknownReferenceChange}
          />
        );
      },
    },
    {
      width: 30,
      // eslint-disable-next-line
      render: function rowSelector(_key: string, row: OrderListReferenceLocal) {
        return (
          <TrashAltButton
            onClick={() => {
              handleUnknownReferenceChange(row.index, 'remove');
              dispatch(resetOrderListReference({ index: row.index }));
            }}
          />
        );
      },
    },
  ];

  return (
    <STable
      dataSource={references}
      columns={tableColumns}
      rowKey={(row) => `order-list-table-${row.index}`}
      pagination={false}
    />
  );
};

export default OrderListTable;
