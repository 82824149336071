import {
  GET_IAM_TIRES_RESPONSE,
  GET_MPR_VALIDATION_RESPONSE,
  GET_TIRE_BRAND_CATEGORIES_RESPONSE,
  TIRES_FULLTEXT_SEARCH_RESPONSE,
  TIRES_SEARCH_PRODUCTS_RESPONSE,
  TIRES_SEARCH_UNIQUE_DIMENSIONS_RESPONSE,
  VEHICLE_TIRES_RESPONSE,
} from '@1po/1po-bff-fe-spec/generated/common/ResponseType';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TireBrandDetail } from '@1po/1po-bff-fe-spec/generated/tire/model/TireBrandDetail';
import { VehicleTires } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { MprValidationResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/MprValidationResponse';
import { TireBrandCategoriesResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/TireBrandCategoriesResponse';
import { TireSearchResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from 'app/AppStore';
import { FiltersRange, TextFacetLocal, TextFilterItem } from 'components/Filter/Filter.types';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import {
  CatalogTires,
  GET_IAM_TIRES_REQUEST,
  IAMVehicleTires,
  TIRE_BRAND_CATEGORIES_REQUEST,
  TIRE_BRAND_CATEGORY_ENTRY,
  TIRE_BRAND_CATEGORY_MEDIUM,
  TIRE_BRAND_CATEGORY_PREMIUM,
  TIRE_NAMESPACE,
  TIRE_SEARCH_PRODUCTS,
  TireBrandCategoryType,
  TireBrandDetailLocal,
  TIRES_FULLTEXT_SEARCH_REQUEST,
  TIRES_UNIQUE_DIMENSIONS_SEARCH_REQUEST,
  TIRE_REPLACEMENT_PRODUCTS_REQUEST,
  TireSearchDimensions,
  TireSearchInputDimensions,
  TireSet,
  TireState,
  MPR_VALIDATION_REQUEST,
} from 'domains/tires/Tire.types';
import { FOUND, mergeObjectInMap, NO_DATA, NOT_FOUND, SEARCH_STATUS, SearchData } from 'utils';

// Saga actions
export const tireProductsSearchRequestSaga = createAction<{
  query: string;
  season: Season | undefined;
  brands: string[];
  chargeIndicatorSeparator?: string;
}>(TIRE_SEARCH_PRODUCTS);
export const tireProductsSearchResponseSaga = createAction(TIRES_SEARCH_PRODUCTS_RESPONSE);
export const vehicleTiresResponseSaga = createAction(VEHICLE_TIRES_RESPONSE);
export const fetchIAMTiresRequestSaga = createAction<{
  versionCode: string;
}>(GET_IAM_TIRES_REQUEST);
export const fetchIAMTiresResponseSaga = createAction(GET_IAM_TIRES_RESPONSE);
export const tiresFulltextSearchRequestSaga = createAction<{
  fulltextSearch: string;
}>(TIRES_FULLTEXT_SEARCH_REQUEST);
export const tireReplacementProductsRequestSaga = createAction<{
  reference: string;
}>(TIRE_REPLACEMENT_PRODUCTS_REQUEST);
export const mprValidationRequestSaga = createAction<{
  references: string[];
}>(MPR_VALIDATION_REQUEST);

export const tiresFulltextSearchResponseSaga = createAction(TIRES_FULLTEXT_SEARCH_RESPONSE);
export const fetchUniqueTireDimensionsRequestSaga = createAction(TIRES_UNIQUE_DIMENSIONS_SEARCH_REQUEST);
export const uniqueDimensionsResponseSaga = createAction(TIRES_SEARCH_UNIQUE_DIMENSIONS_RESPONSE);
export const tireBrandCategoriesRequestSaga = createAction(TIRE_BRAND_CATEGORIES_REQUEST);
export const tireBrandCategoriesResponseSaga = createAction(GET_TIRE_BRAND_CATEGORIES_RESPONSE);
export const mprValidationResponseSaga = createAction(GET_MPR_VALIDATION_RESPONSE);

// Init state
const initialState: TireState = {
  catalogTires: new Map(),
  vehicleTires: new Map(),
  iamVehicleTires: new Map(),
  searchParamsBase64: undefined,
  tireSetParam: undefined,
  tireFilters: {
    textFilters: new Map<string, string[]>(),
    rangeFilters: new Map<string, FiltersRange>(),
  },
  searchQuery: '',
  fulltextSearch: { searchStatus: undefined },
  searchDimensions: undefined,
  tireSearchInputDimensions: undefined,
  brandCategories: { data: undefined, searchStatus: undefined },
  mprValidation: { data: undefined, searchStatus: undefined },
};

function getBrandCategoriesMap(
  brandCategories: SearchData<Map<string, TireBrandDetailLocal>>,
): Map<string, TireBrandDetailLocal> {
  return brandCategories?.data ?? new Map<string, TireBrandDetailLocal>();
}

function setBrandCategories(
  brandCategories: Map<string, TireBrandDetailLocal>,
  brandList: TireBrandDetail[] | undefined,
  category: TireBrandCategoryType,
): Map<string, TireBrandDetailLocal> {
  brandList?.map((brand) => {
    brandCategories.set(brand.brandId, { ...brand, category } as TireBrandDetailLocal);
  });
  return brandCategories;
}

// Slice
const slice = createSlice({
  name: TIRE_NAMESPACE,
  initialState,
  reducers: {
    setInitialState: () => initialState,
    setSearchParams: (state, action: PayloadAction<{ searchParamsBase64: string }>) => {
      const { searchParamsBase64 } = action.payload;
      state.searchParamsBase64 = searchParamsBase64;
    },
    setTireResult: (state, action: PayloadAction<TireSearchResponse>) => {
      const { searchParamsBase64, tires, filters } = action.payload;
      const textFilters = filters?.map((f) => {
        return {
          ...f,
          active: true,
        } as TextFacetLocal;
      });
      const result: SearchData<CatalogTires> = {
        searchStatus: tires ? FOUND : NOT_FOUND,
        data: { tires, textFilters, rangeFilters: undefined },
      };
      state.catalogTires.set(searchParamsBase64, result);
    },
    setTireSearchStatus: (
      state,
      action: PayloadAction<{
        searchParamsBase64: string;
        status: SEARCH_STATUS;
      }>,
    ) => {
      const { searchParamsBase64, status } = action.payload;
      mergeObjectInMap(state.catalogTires, searchParamsBase64, { searchStatus: status });
    },
    setVehicleTires: (
      state,
      action: PayloadAction<{
        vehicleKey: string;
        tires: VehicleTires;
      }>,
    ) => {
      const { vehicleKey, tires } = action.payload;
      mergeObjectInMap(state.vehicleTires, vehicleKey, { ...tires });
    },
    setVehicleTiresStatus: (
      state,
      action: PayloadAction<{
        vehicleKey: string;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleKey, status } = action.payload;
      mergeObjectInMap(state.vehicleTires, vehicleKey, status);
    },
    setTireTextFilter: (state, { payload }: PayloadAction<TextFilterItem>) => {
      const { id, item } = payload;
      const idItems = state.tireFilters.textFilters.get(id) ?? [];
      const newItems = idItems.includes(item) ? idItems.filter((x) => x !== item) : [...idItems, item];
      if (newItems.length > 0) {
        state.tireFilters.textFilters.set(id, newItems);
      } else {
        state.tireFilters.textFilters.delete(id);
      }
    },
    setTireTextFilters: (state, { payload }: PayloadAction<TextFilterItem[]>) => {
      payload.forEach(({ id, item }) => {
        const idItems = state.tireFilters.textFilters.get(id) ?? [];
        const newItems = idItems.includes(item) ? idItems.filter((x) => x !== item) : [...idItems, item];
        if (newItems.length > 0) {
          state.tireFilters.textFilters.set(id, newItems);
        } else {
          state.tireFilters.textFilters.delete(id);
        }
      });
    },
    setTireRangeFilter: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: string;
        range?: FiltersRange;
      }>,
    ) => {
      const { id, range } = payload;
      if (range) {
        state.tireFilters.rangeFilters.set(id, range);
      } else {
        state.tireFilters.rangeFilters.delete(id);
      }
    },
    resetTireFilters: (state) => {
      state.tireFilters.textFilters.clear();
      state.tireFilters.rangeFilters.clear();
    },
    resetTireTextFilter: (state, { payload }: PayloadAction<{ filterOption: string }>) => {
      const { filterOption } = payload;
      state.tireFilters.textFilters.delete(filterOption);
    },
    setTireSetParam: (state, { payload }: PayloadAction<TireSet | undefined>) => {
      state.tireSetParam = payload;
    },
    setIAMTires: (
      state,
      {
        payload,
      }: PayloadAction<{
        vehicleKey: string;
        iamTires: IAMVehicleTires;
      }>,
    ) => {
      const { vehicleKey, iamTires } = payload;
      mergeObjectInMap(state.iamVehicleTires, vehicleKey, iamTires);
    },
    setIAMTiresNoDataStatus: (
      state,
      action: PayloadAction<{
        vehicleCode: string | undefined;
        status: NO_DATA;
      }>,
    ) => {
      const { vehicleCode, status } = action.payload;
      mergeObjectInMap(state.iamVehicleTires, vehicleCode, status);
    },
    setFulltextSearchData: (state, action: PayloadAction<string[]>) => {
      state.fulltextSearch.data = action.payload.sort();
      state.fulltextSearch.searchStatus = action.payload.length > 0 ? FOUND : NOT_FOUND;
    },
    setFulltextSearchStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.fulltextSearch.searchStatus = action.payload;
    },
    setMprValidationStatus: (state, action: PayloadAction<SEARCH_STATUS>) => {
      state.mprValidation.searchStatus = action.payload;
    },
    setTireSearchQuery: (state, action: PayloadAction<{ query: string }>) => {
      state.searchQuery = action.payload.query.toUpperCase();
    },
    setSearchDimensions: (state, action: PayloadAction<TireSearchDimensions>) => {
      const { width, series, diameter, speedIndicator, chargeIndicator } = action.payload;
      state.searchDimensions = {
        width,
        series,
        diameter,
        speedIndicator,
        chargeIndicator,
      };
    },
    setTireSearchInputDimensions: (state, action: PayloadAction<TireSearchInputDimensions>) => {
      const { width, series, diameter, speedIndicator, chargeIndicator } = action.payload;
      state.tireSearchInputDimensions = {
        width,
        series,
        diameter,
        speedIndicator,
        chargeIndicator,
      };
    },
    setTireBrandCategoriesSearchStatus: (state, action: PayloadAction<{ searchStatus: SEARCH_STATUS }>) => {
      const { searchStatus } = action.payload;
      state.brandCategories.searchStatus = searchStatus;
    },
    setTireBrandCategories: (state, action: PayloadAction<TireBrandCategoriesResponse>) => {
      const { premiumBrandList, mediumBrandList, entryBrandList } = action.payload;
      const brandCategoriesMap = getBrandCategoriesMap(state.brandCategories);
      setBrandCategories(brandCategoriesMap, premiumBrandList, TIRE_BRAND_CATEGORY_PREMIUM);
      setBrandCategories(brandCategoriesMap, mediumBrandList, TIRE_BRAND_CATEGORY_MEDIUM);
      setBrandCategories(brandCategoriesMap, entryBrandList, TIRE_BRAND_CATEGORY_ENTRY);
      state.brandCategories.data = brandCategoriesMap;
      state.brandCategories.searchStatus = FOUND;
    },
    setMprValidation: (state, action: PayloadAction<MprValidationResponse>) => {
      state.mprValidation.data = action.payload;
      state.mprValidation.searchStatus = FOUND;
    },
  },
});

// Actions
export const {
  setInitialState,
  setSearchParams,
  setTireResult,
  setTireSearchStatus,
  setVehicleTires,
  setVehicleTiresStatus,
  setTireTextFilter,
  setTireTextFilters,
  setTireRangeFilter,
  resetTireFilters,
  resetTireTextFilter,
  setIAMTires,
  setIAMTiresNoDataStatus,
  setTireSetParam,
  setFulltextSearchData,
  setFulltextSearchStatus,
  setMprValidationStatus,
  setTireSearchQuery,
  setSearchDimensions,
  setTireSearchInputDimensions,
  setTireBrandCategoriesSearchStatus,
  setTireBrandCategories,
  setMprValidation,
} = slice.actions;

// Export reducer
export default slice.reducer;

// Getters/Selectors
export const getTireSearchParams = createSelector(
  (state: RootState) => state.tires.searchParamsBase64,
  (searchParamsBase64) => searchParamsBase64,
);

export const getTireSearchResult = createSelector(
  (state: RootState) => state.tires.catalogTires,
  (_state: RootState, params: { searchParamsBase64: string }) => params,
  (catalogTires, params) => {
    const { searchParamsBase64 } = params;
    return catalogTires.get(searchParamsBase64);
  },
);

export const getTireCatalogFilters = createSelector(
  (state: RootState) => state.tires.tireFilters,
  (tireFilters) => tireFilters,
);

export const getVehicleTires = createSelector(
  (state: RootState) => state.tires.vehicleTires,
  getLastSearchedVehicleKey,
  (vehicleTires, lastSearchedVehicleKey) =>
    lastSearchedVehicleKey ? vehicleTires.get(lastSearchedVehicleKey) : undefined,
);

export const getIAMTires = createSelector(
  (state: RootState) => state.tires.iamVehicleTires,
  getLastSearchedVehicleKey,
  (iamTires, vehicleKey) => (vehicleKey ? iamTires.get(vehicleKey) : undefined),
);

export const getTireSetParam = createSelector(
  (state: RootState) => state.tires.tireSetParam,
  (tireSetParam) => tireSetParam,
);

export const getFulltextSearchData = createSelector(
  (state: RootState) => state.tires.fulltextSearch,
  (fulltextSearch) => fulltextSearch,
);

export const getTireSearchDimensions = createSelector(
  (state: RootState) => state.tires.searchDimensions,
  (searchDimensions) => searchDimensions,
);

export const getTireSearchInputDimensions = createSelector(
  (state: RootState) => state.tires.tireSearchInputDimensions,
  (tireSearchInputDimensions) => tireSearchInputDimensions,
);

export const getTireSearchQuery = createSelector(
  (state: RootState) => state.tires.searchQuery,
  (searchQuery) => searchQuery,
);

export const getTireBrandCategoriesMap = createSelector(
  (state: RootState) => state.tires.brandCategories,
  (brandCategories) => brandCategories,
);
