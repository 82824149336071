/* eslint-disable max-len */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PromotionType } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/Promotion';
import {
  Reference,
  ReferencesSection,
} from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/ReferencesSection';
import { InfoCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { matchRoute, ROUTER_CART, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { calculatePriceAfterDiscount, DiscountCorner, FlashCertificate } from 'components/Discount/Discount';
import QuantityModule from 'components/QuantityModule';
import { QuantityModuleUpdateComponentType } from 'components/QuantityModule/QuantityModule';
import { ReferenceUnavailable } from 'components/ReferenceUnavailableBox/ReferenceUnavailable';
import StockDisplay from 'components/StockInfo';
import PrivateComponent from 'composers/PrivateComponent';
import {
  addOtherSectionReferenceRequest,
  getBasketOtherSection,
  updateReferenceQuantityRequest,
} from 'domains/basket/Basket.store';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { removeCartReferenceInCatalog } from 'domains/catalog/Catalog.store';
import { STANDARD } from 'domains/catalog/Catalog.types';
import { getDHReference, getDiscount, getIsStockAvailable } from 'domains/references';
import { useFetchFullTradingData, usePrice } from 'domains/references/References.requests';
import { getCurrency, getTradingProfile, UserRole } from 'domains/user';
import { getSectionHeader } from 'pages/PromotionPage/PromotionPageView';
import { theme } from 'styles';
import {
  BlackButton,
  Box,
  Flex,
  InfiniteScroll,
  Link,
  Lowercase,
  MarginBox,
  Panel,
  SectionCollapse,
  Text,
  useInfiniteScroll,
  WithTooltip,
} from 'UI';
import { NotificationLink, notifyTop } from 'UI/Notification/notification';
import { getData, getSearchData, hasData, textFormatter, useSmall } from 'utils';
import {
  TRACKING_EVENT_ADD_PROMOTION_TO_CART,
  TRACKING_EVENT_CART_PART_QUANTITY_ZERO,
  TRACKING_EVENT_GO_TO_CART_SHORTCUT,
  TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION,
  TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
} from 'utils/eventTracker/EventTracker.types';
import { SListItem } from './PromotionPage.styled';

type ReferenceRowProps = {
  referenceNumber: string;
  discountPercent: number;
  promotionType: PromotionType;
  endDate: string;
  addToCartDisabled: boolean;
};

const ReferenceRow = ({
  referenceNumber,
  discountPercent,
  promotionType,
  endDate,
  addToCartDisabled,
}: ReferenceRowProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber, vehicleKey: undefined }),
  );
  const otherSection = useSelector(getBasketOtherSection);
  const basketReferences = otherSection?.references;
  const refQuantity = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber)?.quantity as number;
  const small = useSmall();

  const priceState = usePrice(referenceNumber);
  const price = getSearchData(priceState);
  const currency = useSelector(getCurrency);
  const discount = useSelector((state: RootState) => getDiscount(state, referenceNumber));
  const vatExcludedPrice = calculatePriceAfterDiscount(discount, price, false);
  const vatIncludedPrice = calculatePriceAfterDiscount(discount, price, true);
  const tradingProfile = useSelector(getTradingProfile);
  const getTimeLeft = (units: 'd' | 'h' | 'm') => moment(endDate).diff(moment(), units);
  const availableInUserCountry = useSelector((state: RootState) => getIsStockAvailable(state, referenceNumber));

  const daysLeft = getTimeLeft('d');
  const hoursLeft = getTimeLeft('h');
  const minutesLeft = getTimeLeft('m') % 60;

  useFetchSingleReference(referenceNumber, null, undefined);
  useFetchFullTradingData(referenceNumber, undefined);

  const getUnitsText = () => {
    if (daysLeft > 0) {
      return (
        <Trans count={daysLeft} i18nKey={'backoffice.promotion.banner.days_left'}>
          {'Only {{count}} day left.'}
        </Trans>
      );
    }
    if (hoursLeft > 0) {
      return (
        <Trans i18nKey={'backoffice.promotion.banner.hours_left'} tOptions={{ hoursLeft, minutesLeft }}>
          {'Only {{hoursLeft}}h : {{minutesLeft}}m left.'}
        </Trans>
      );
    }
    return (
      <Trans i18nKey={'backoffice.promotion.banner.minutes_left'} tOptions={{ minutesLeft }}>
        {'Only {{minutesLeft}}m left.'}
      </Trans>
    );
  };

  const handleAddToCartClick = () => {
    if (
      tradingProfile &&
      price?.garageView?.vatExcludedPrice &&
      price.garageView?.vatIncludedPrice &&
      hasData(reference)
    ) {
      notifyTop(
        'success',
        <Trans i18nKey={'catalog.reference_card.added_to_basket.description'}>
          {'Reference has been added to your cart'}
        </Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            trackAppEvent(TRACKING_EVENT_GO_TO_CART_SHORTCUT);
            history.push(ROUTER_CART);
          }}
        >
          <Trans i18nKey={'catalog.reference_card.added_to_basket.go_to_cart'}>{'Go to cart'}</Trans>
        </NotificationLink>,
      );
      trackAppEvent(TRACKING_EVENT_ADD_PROMOTION_TO_CART);
      dispatch(
        addOtherSectionReferenceRequest({
          reference,
        }),
      );
    }
  };

  function quantityCallback(quantity: number, type?: QuantityModuleUpdateComponentType) {
    if (quantity === 0) {
      dispatch(
        removeCartReferenceInCatalog({
          basketReferenceType: 'OTHER',
          vehicleKey: undefined,
          referenceType: getData(reference)?.type ?? STANDARD,
          referenceNumber,
          referenceSource: 'STANDARD',
          origin: undefined,
          supplierCode: undefined,
        }),
      );
      if (type === 'INPUT') {
        trackAppEvent(TRACKING_EVENT_CART_PART_QUANTITY_ZERO);
      } else {
        trackAppEvent(
          matchRoute(location.pathname, ROUTER_PRODUCT)
            ? TRACKING_EVENT_PAGE_PRODUCT_PART_DELETION
            : TRACKING_EVENT_PAGE_RESULT_PART_DELETION,
        );
      }
    } else {
      dispatch(
        updateReferenceQuantityRequest({
          basketReferenceType: 'OTHER',
          vehicleKey: undefined,
          externalBasketId: undefined,
          referenceNumber,
          newQuantity: quantity,
          referenceType: getData(reference)?.type ?? STANDARD,
        }),
      );
    }
  }

  const renderStockInfo = () => {
    switch (promotionType) {
      case 'DISCOUNT':
      case 'BANNER':
        return (
          <MarginBox mx={15}>
            <StockDisplay
              vehicleKey={undefined}
              isApplicableToCurrentVehicle={getData(reference)?.isApplicableToCurrentVehicle ?? true}
              referenceNumber={referenceNumber}
              small={small}
            />
          </MarginBox>
        );
      default:
        return <></>;
    }
  };

  const renderFlashCertificate = () => {
    switch (promotionType) {
      case 'PROMOTION_FLASH_QUANTITY_LIMITED':
      case 'PROMOTION_FLASH_TIME_LIMITED':
        return (
          <Flex direction={'row'} align={'center'} justify={'flex-start'} minWidth={160} maxWidth={160}>
            <Box width={60} height={50}>
              <FlashCertificate reference={referenceNumber} type={'promotion'} staticPercent={discountPercent} />
            </Box>
            <MarginBox mr={7} />
            <Text type={'light_12'} displayStyle={'success'}>
              {getUnitsText()}
            </Text>
            <MarginBox mr={15} />
          </Flex>
        );
      default:
        return <></>;
    }
  };

  const PriceUnavailable = () => {
    return (
      <Flex direction={'row'}>
        <MarginBox mr={330} />
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <InfoCircleFilled style={{ color: theme.color.warning, marginRight: '5px' }} />
            <Text type={'light_12'} displayStyle={'warning'} cursor={'pointer'} decoration={'underline'}>
              <Trans i18nKey={'common.price.unavailable'}>{'Price currently unavailable'}</Trans>
            </Text>
          </Flex>
          <Text type={'section'}>
            <Trans i18nKey={'common.price.unavailable.description'}>{'Please try again later.'}</Trans>
          </Text>
        </Flex>
        <MarginBox mr={15} />
      </Flex>
    );
  };

  const getReferenceLink = () => {
    const isRef = hasData(reference);
    const text = (
      <Flex direction={'row'} align={'center'}>
        <Text
          type={availableInUserCountry ? 'h6' : 'h6_black_45'}
          hoverUnderLine={isRef}
          cursor={isRef ? 'pointer' : 'initial'}
        >
          {`${t('catalog.reference_card.reference_number', 'Ref:')} ${referenceNumber}`}
        </Text>
        <MarginBox mr={10} />
        <CopyToClipboardButton
          textToCopy={referenceNumber}
          message={t(
            'catalog.reference_card.reference_number.copied_to_clipboard',
            'Reference number {{referenceNumber}} copied to clipboard',
            { referenceNumber },
          )}
        />
      </Flex>
    );

    if (!isRef) {
      return text;
    }
    return <Link to={`${ROUTER_PRODUCT}/${referenceNumber}`}>{text}</Link>;
  };

  return (
    <SListItem
      direction={useSmall() ? 'column' : 'row'}
      minHeight={60}
      align={'center'}
      justify={'space-between'}
      size={5}
    >
      {availableInUserCountry && (
        <DiscountCorner
          reference={referenceNumber}
          type={'promotion'}
          staticPercent={discountPercent}
          staticType={promotionType}
        />
      )}
      <Flex direction={'row'}>
        {!small && <MarginBox mr={40} />}
        <Flex direction={'column'}>
          {getReferenceLink()}
          <WithTooltip title={getData(reference)?.name ?? ''}>
            <Text type={availableInUserCountry ? 'section' : 'light_14_black_45'} transform={'capitalize'} ellipsis>
              <Lowercase>{getData(reference)?.name}</Lowercase>
            </Text>
          </WithTooltip>
        </Flex>
      </Flex>
      <Flex justify={'center'}>{renderStockInfo()}</Flex>
      {availableInUserCountry ? (
        <DataContainer
          data={priceState}
          NotFound={() => <PriceUnavailable />}
          ErrorState={() => (
            <ErrorWithLabel
              label={t('common.price.backend_error', 'Price temporarily unavailable, please try again later.')}
              narrow
            />
          )}
        >
          {renderFlashCertificate()}
          <Flex minWidth={160} maxWidth={160} align={'center'}>
            <Text type={'light_12_black_90'}>
              {t('backoffice.promotion.reference.including_discount', 'Including a {{discount_value}}% Discount', {
                discount_value: price?.garageView?.discountRate,
              })}
            </Text>
            <MarginBox mr={15} />
          </Flex>
          <Flex direction={'row'} minWidth={145} maxWidth={145} align={'center'}>
            <Flex direction={'column'} align={'flex-start'}>
              <Text type={'text'}>{`${textFormatter.formatCurrency(vatExcludedPrice, currency)} ${t(
                'backoffice.promotion.reference.VATExcl',
                'VAT.Excl',
              )}`}</Text>
              <Text type={'text'}>
                {`${textFormatter.formatCurrency(vatIncludedPrice, currency)} ${t(
                  'backoffice.promotion.reference.VATIncl',
                  'VAT.Incl',
                )}`}
              </Text>
            </Flex>
          </Flex>
          <MarginBox mx={15} my={15}>
            <Flex minWidth={180} maxWidth={180} direction={'row'}>
              <PrivateComponent
                render={() => (
                  <>
                    {basketReferences?.find((ref) => ref.referenceNumber === referenceNumber) ? (
                      <QuantityModule
                        value={refQuantity}
                        onChange={quantityCallback}
                        showDelete={true}
                        disabled={addToCartDisabled}
                      />
                    ) : (
                      <BlackButton
                        size={'middle'}
                        onClick={() => {
                          handleAddToCartClick();
                        }}
                        disabled={addToCartDisabled}
                        stretch
                      >
                        {t('cart.action.add_to_cart', 'Add to cart')}
                      </BlackButton>
                    )}
                  </>
                )}
                requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
              />
            </Flex>
          </MarginBox>
        </DataContainer>
      ) : (
        <Flex justify={'flex-end'} maxWidth={400} size={3}>
          <MarginBox mt={15} mb={15} mr={15}>
            <ReferenceUnavailable />
          </MarginBox>
        </Flex>
      )}
    </SListItem>
  );
};

const ReferenceSectionInfinite = ({
  refs,
  discountPercent,
  promotionType,
  endDate,
  addToCartDisabled,
}: {
  refs: Reference[];
  discountPercent: number;
  promotionType: PromotionType;
  endDate: string;
  addToCartDisabled: boolean;
}) => {
  const { hasMore, loadMore, currentData } = useInfiniteScroll<string>({
    data: refs.map((ref) => ref.referenceNumber),
    chunkSize: 10,
  });

  return (
    <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
      {currentData.map((referenceNumber, index) => (
        <React.Fragment key={`${referenceNumber}${index}`}>
          <ReferenceRow
            referenceNumber={referenceNumber}
            discountPercent={discountPercent}
            promotionType={promotionType}
            endDate={endDate}
            addToCartDisabled={addToCartDisabled}
          />
        </React.Fragment>
      ))}
    </InfiniteScroll>
  );
};

const ReferenceSection = ({
  references,
  promotionType,
  endDate,
  addToCartDisabled,
}: {
  references: ReferencesSection[] | undefined;
  promotionType: PromotionType;
  endDate: string;
  addToCartDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const referencesCount = references?.map((section) => section.items).reduce((acc, next) => acc.concat(next), [])
    .length;

  if (!references || referencesCount === 0) {
    return null;
  }
  const defaultPanelsReferences = references.map((_ref, index) => `references-${index}`) ?? [];

  return (
    <SectionCollapse position={'end'} defaultActiveKey={defaultPanelsReferences}>
      {references.map((reference, index) => (
        <React.Fragment key={index}>
          <Panel
            key={`references-${index}`}
            header={getSectionHeader(
              t('backoffice.promotion.references_header', 'List of reference(s) in Promotion'),
              reference?.discount,
            )}
          >
            <Box height={45} />
            <ReferenceSectionInfinite
              refs={reference.items}
              discountPercent={reference.discount}
              promotionType={promotionType}
              endDate={endDate}
              addToCartDisabled={addToCartDisabled}
            />
          </Panel>
          <Box height={45} />
        </React.Fragment>
      ))}
    </SectionCollapse>
  );
};

export default ReferenceSection;
