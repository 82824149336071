/* eslint-disable max-len */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { DataContainer } from 'components/DataContainer';
import { getBasketOtherSection, getBasketVehicles } from 'domains/basket/Basket.store';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { getDHReference, getDHReferences } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import CrossReferenceCard from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/CrossSellingSection/CrossReferenceCard';
import { ArrowLeftButtonRound, ArrowRightButtonRound, CenteredSpin, Flex, MarginBox, Text } from 'UI';
import { FOUND, getData, LOADING, NO_DATA } from 'utils';

const CATALOG_PAGING = 3;
const PRODUCT_PAGING = 4;
const MAX_CROSS_SELLING = 10;

const Loading = () => (
  <MarginBox my={30}>
    <CenteredSpin />
  </MarginBox>
);

const CrossSellingSection = ({
  mainRef,
  crossSellingReferences,
  type,
}: {
  mainRef: string;
  crossSellingReferences: string[] | NO_DATA;
  type: 'product' | 'catalog';
}) => {
  const { t } = useTranslation();
  const getSizeType = () => {
    switch (type) {
      case 'catalog':
        return CATALOG_PAGING;
      case 'product':
        return PRODUCT_PAGING;
    }
  };
  const size = getSizeType();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const references = useSelector((state: RootState) =>
    getDHReferences(state, { referenceNumbers: getData(crossSellingReferences), vehicleKey }),
  );
  const mainReference = getData(
    useSelector((state: RootState) => getDHReference(state, { vehicleKey, referenceNumber: mainRef })),
  );
  const basketVehicles = useSelector(getBasketVehicles);
  const basketOtherSection = useSelector(getBasketOtherSection);
  const [cursor, setCursor] = useState(0);
  const refNumbers = references.map((r) => r.referenceNumber);
  const prices = useFetchPrices(refNumbers, vehicleKey);
  const isPricesLoading = Array.from(prices.values(), (p) => {
    return { price: p };
  }).some((p) => p.price.searchStatus === LOADING);

  const filteredReferences = references
    .filter((ref) => {
      const price = prices.get(ref.referenceNumber);
      const isCompatible = vehicleKey ? ref.isApplicableToCurrentVehicle : true;
      return price?.searchStatus === FOUND && isCompatible;
    })
    .slice(0, MAX_CROSS_SELLING);
  const referencesOfCurrentVeh = basketVehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)
    ?.references;
  const isApplicableOrInCurrVehCart = () => {
    if (vehicleKey) {
      return (
        mainReference?.isApplicableToCurrentVehicle ||
        referencesOfCurrentVeh?.find((ref) => ref.referenceNumber === mainRef)
      );
    } else {
      return false;
    }
  };
  const getBasketReferences = () =>
    isApplicableOrInCurrVehCart() ? referencesOfCurrentVeh : basketOtherSection?.references;
  const basketReferences = getBasketReferences();
  const showArrows = filteredReferences.length > size;

  if (
    (type !== 'product' && !basketReferences?.find((ref) => ref.referenceNumber === mainRef)) ||
    filteredReferences.length === 0
  ) {
    return <></>;
  }
  if (isPricesLoading) {
    return <Loading />;
  }

  const currentData = filteredReferences.slice(cursor, cursor + Math.min(size, filteredReferences.length));
  const browse = (offset: number) => setCursor(cursor + offset);
  const next = () => browse(1);
  const prev = () => browse(-1);
  const hasNext = cursor + size < filteredReferences.length;
  const hasPrev = cursor > 0;

  return (
    <DataContainer data={crossSellingReferences} NotFound={() => <></>} Loading={() => <Loading />}>
      <div id={'product_cross_selling'}>
        {type === 'product' && vehicleKey && (
          <MarginBox mt={90} ml={50} mb={15}>
            <Text type={'h1_banner_light'}>
              {t('cross_selling.compatible_products', 'Products compatible with your vehicle')}
            </Text>
          </MarginBox>
        )}
        <MarginBox my={30}>
          <Flex align={'center'}>
            {showArrows && (
              <ArrowLeftButtonRound color={'brand_black'} onClick={prev} disabled={!hasPrev} disabledColor={'grey65'} />
            )}
            <Flex justify={'center'}>
              {currentData.map((ref) => (
                <CrossReferenceCard key={ref.referenceNumber} reference={ref} type={type} />
              ))}
            </Flex>
            {showArrows && (
              <ArrowRightButtonRound
                color={'brand_black'}
                onClick={next}
                disabled={!hasNext}
                disabledColor={'grey65'}
              />
            )}
          </Flex>
        </MarginBox>
      </div>
    </DataContainer>
  );
};
export default CrossSellingSection;
