import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { CheckCircleIcon } from 'assets/icons';
import { getLastSearchedVehicleKey, getLastVehicleDetail } from 'domains/catalog/Catalog.store';
import { OEM_BRAND_KEY, OTHER_BRAND_KEY } from 'domains/catalog/Catalog.types';
import { fillEstimateFromMaintenancePlan, getEstimateSettings } from 'domains/estimate/Estimate.store';
import { EstimateTabName, EstimateTabParam } from 'domains/estimate/Estimate.types';
import {
  getMaintenancePlanLaborTimes,
  getMaintenancePlanReferences,
} from 'domains/maintenancePlan/MaintenancePlan.mapper';
import {
  fetchIAMChecklistRequestSaga,
  fetchIAMServiceOperationsRequestSaga,
  getIAMAvailableModules,
  getIAMServiceChecklist,
  getLastSearchServiceOperations,
  getServiceOperationsReferences,
  resetServiceChecklist,
} from 'domains/maintenancePlan/MaintenancePlan.store';
import { getIAMReferences, ReferencePriceType } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { getCurrency, getIamCatalogBrandsView, getUserRights, setIamCatalogBrandsView, UserRole } from 'domains/user';
import { printChecklist } from 'pages/CatalogPage/IAM/MaintenancePlan/ChecklistPdf';
import DefaultMaintenance from 'pages/CatalogPage/IAM/MaintenancePlan/DefaultMaintenance/DefaultMaintenance';
import { printMaintenancePlan } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlanPdf';
import Overview from 'pages/CatalogPage/IAM/MaintenancePlan/Overview/Overview';
import ServiceOperations from 'pages/CatalogPage/IAM/MaintenancePlan/ServiceOperations/ServiceOperations';
import VehicleCriterias from 'pages/CatalogPage/IAM/MaintenancePlan/VehicleCriterias/VehicleCriterias';
import {
  Box,
  EstimateButtonRound,
  Flex,
  Icon,
  imageSrcSwitch,
  MarginBox,
  PaddingBox,
  PrintButtonRound,
  Steps,
  Text,
  URL,
} from 'UI';
import { getData, getSearchData, hasUserAnyRight } from 'utils';
import { scrollToTopSmooth } from 'utils/hooks/useResetScroll';

interface MaintenancePlanProps {
  setCatalogShowMenu: (v: boolean) => void;
}

const MaintenancePlan = ({ setCatalogShowMenu }: MaintenancePlanProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const operations = useSelector(getLastSearchServiceOperations);
  const operationsData = getData(operations);
  const history = useHistory();
  const vehicleDetail = useSelector(getLastVehicleDetail);
  const allReferences = Array.from(getData(useSelector(getServiceOperationsReferences))?.values() ?? []).flat();
  const estimateSettings = useSelector(getEstimateSettings);
  const estimateSettingsData = getData(estimateSettings);
  const serviceChecklist = useSelector(getIAMServiceChecklist);
  const carImageUrl = useMemo(() => imageSrcSwitch(URL, vehicleDetail?.imageUrl), [vehicleDetail?.imageUrl]);
  const userRights = getData(useSelector(getUserRights));
  const selectedReferencesByOperation = useSelector(getServiceOperationsReferences);
  const referencesPrices = useFetchPrices(allReferences.map((r) => r.referenceNumber));
  const availableDataModule = getData(useSelector(getIAMAvailableModules));
  const showPlanProposal = availableDataModule?.availableData.find((d) => d.name === 'servicePlanProposal')
    ?.dataAvailable;
  const referencesPricesData = useMemo(
    () =>
      new Map<string, undefined | ReferencePriceType>(
        Array.from(referencesPrices.entries()).map(([key, value]) => [key.referenceNumber, getSearchData(value)]),
      ),
    [referencesPrices],
  );
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const referencesData = useSelector((state: RootState) =>
    getIAMReferences(state, {
      referenceNumbers: allReferences.map((r) => r.referenceNumber),
      vehicleKey,
    }),
  );
  const currency = useSelector(getCurrency);
  const iamCatalogBrandsView = useSelector(getIamCatalogBrandsView);
  const references = useMemo(() => getMaintenancePlanReferences(allReferences, referencesData, referencesPrices), [
    allReferences,
    referencesData,
    referencesPrices,
  ]);
  const laborTimes = useMemo(() => getMaintenancePlanLaborTimes(operationsData, estimateSettings), [
    operationsData,
    estimateSettings,
  ]);

  useEffect(() => {
    if (iamCatalogBrandsView === OEM_BRAND_KEY) {
      dispatch(setIamCatalogBrandsView(OTHER_BRAND_KEY));
    }
  }, [dispatch, iamCatalogBrandsView]);

  useEffect(() => {
    setCatalogShowMenu(false);
    return () => {
      setCatalogShowMenu(true);
    };
  }, [setCatalogShowMenu]);

  useEffect(() => {
    if (serviceChecklist && serviceChecklist.searchStatus === 'found') {
      printChecklist(serviceChecklist?.data, vehicleDetail, carImageUrl);
    }
    return () => {
      dispatch(resetServiceChecklist());
    };
  }, [dispatch, serviceChecklist, vehicleDetail, carImageUrl]);

  const handleValidate = useCallback(() => {
    if (!vehicleDetail) return;
    dispatch(fillEstimateFromMaintenancePlan({ vehicleDetail, references, laborTimes }));
    const params = new URLSearchParams();
    params.set(EstimateTabParam, EstimateTabName);
    history.push(`${ROUTER_ESTIMATE}?${params}`);
  }, [dispatch, vehicleDetail, references, laborTimes, history]);

  const handlePrintChecklist = useCallback(() => {
    dispatch(fetchIAMChecklistRequestSaga());
  }, [dispatch]);

  const handlePrintMaintenance = useCallback(() => {
    printMaintenancePlan(
      operationsData,
      selectedReferencesByOperation,
      referencesPricesData,
      referencesData,
      estimateSettingsData,
      vehicleDetail,
      carImageUrl,
      currency,
    );
  }, [
    operationsData,
    selectedReferencesByOperation,
    referencesPricesData,
    referencesData,
    estimateSettingsData,
    vehicleDetail,
    carImageUrl,
    currency,
  ]);

  const handleSetCurrentStep = (step: number) => {
    setCurrentStep(step);
    scrollToTopSmooth();
  };

  const createNewMaintenancePlan = useCallback(() => {
    if (
      hasUserAnyRight([UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS], userRights)
    ) {
      dispatch(fetchIAMServiceOperationsRequestSaga());
    }
    handleSetCurrentStep(showPlanProposal ? 1 : 2);
  }, [dispatch, userRights, showPlanProposal]);

  return (
    <>
      {currentStep !== 0 && (
        <PaddingBox py={30}>
          <Text type={'h1_banner_light'}>
            <Trans i18nKey={'catalog.maintenance.new_maintenance_plan.title'}>New maintenance plan</Trans>
          </Text>
          <Box height={60}>
            <Flex size={1} justify={'flex-end'}>
              {currentStep === 3 && (
                <>
                  <PaddingBox py={'auto'} pr={30}>
                    <PrintButtonRound onClick={handlePrintMaintenance} />
                  </PaddingBox>
                  <PaddingBox py={'auto'} pr={30}>
                    <EstimateButtonRound onClick={handleValidate} />
                  </PaddingBox>
                </>
              )}
            </Flex>
            <MarginBox mb={10} />
          </Box>
          <Steps
            currentStep={currentStep}
            setCurrentStep={handleSetCurrentStep}
            clickable
            data={[
              { title: t('catalog.maintenance.new_maintenance_plan', 'New maintenance plan') },
              { title: t('catalog.maintenance.vehicle_details', 'Vehicle details'), disabled: !showPlanProposal },
              { title: t('catalog.maintenance.operations', 'Operations') },
              { title: t('catalog.maintenance.overview', 'Overview') },
              {
                title: ' ',
                icon: <Icon IconComponent={CheckCircleIcon} display={'flex'} />,
              },
            ]}
          />
        </PaddingBox>
      )}
      {currentStep === 0 && <DefaultMaintenance handleNext={createNewMaintenancePlan} />}
      {currentStep === 1 && <VehicleCriterias vehicleDetail={vehicleDetail} handleNext={handleSetCurrentStep} />}
      {currentStep === 2 && <ServiceOperations operations={operations} handleNext={handleSetCurrentStep} />}
      {currentStep === 3 && <Overview handlePrint={handlePrintChecklist} handleValidate={handleValidate} />}
      <PaddingBox py={35} />
    </>
  );
};
export default MaintenancePlan;
