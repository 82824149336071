import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addEmptyOrderListRow,
  addReferenceListToCartRequest,
  getCreateOrderList,
  getCreateOrderListErrorRows,
  setOrderListFileReferencesIsModalOpen,
} from 'domains/orderList/OrderList.store';
import { CreateOrderList } from 'domains/orderList/OrderList.types';
import { getUserCommercialLink } from 'domains/user';
import { FileReferencesRowsErrors } from 'pages/CartPage/CartStep/FileUpload';
import OrderListTable, { getListRefOccurrences } from 'pages/OrderListPage/OrderListTable';
import { Box, CenteredSpin, Flex, MarginBox, notifyTop, PlusCircleButton, Text, WhiteButton, YellowButton } from 'UI';
import { LOADING, SEARCH_STATUS, useLarge } from 'utils';
import OrderListReferencesFileUpload from './OrderListFileUpload/OrderListReferencesFileUpload';

function isOrderListValid(orderList: CreateOrderList, hasUnknownReference: boolean) {
  const isAnyReferenceFilled = orderList.references.some((ref) => ref.referenceNumber);
  return (
    isAnyReferenceFilled &&
    !hasUnknownReference &&
    !(
      orderList.references.length > 0 &&
      !!orderList.references
        .filter((row) => row.referenceNumber)
        .find((leftRow) => {
          return !leftRow.quantity || getListRefOccurrences(orderList, leftRow.referenceNumber) !== 1;
        })
    )
  );
}

const AddReferenceListToCartButton = ({
  searchStatus,
  hasUnknownReference,
}: {
  searchStatus: SEARCH_STATUS;
  hasUnknownReference: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const orderList = useSelector(getCreateOrderList);

  const isListValid = useMemo(() => {
    return isOrderListValid(orderList, hasUnknownReference);
  }, [orderList, hasUnknownReference]);

  const handleAddToCard = () => {
    dispatch(addReferenceListToCartRequest());
  };

  return (
    <>
      {searchStatus === LOADING ? (
        <Box height={40}>
          <CenteredSpin size={'small'} />
        </Box>
      ) : (
        <YellowButton onClick={handleAddToCard} disabled={!isListValid}>
          <Text type={'h4_paragraph'}>{t('catalog.order_list.add_to_my_cart', 'Add to my cart')}</Text>
        </YellowButton>
      )}
    </>
  );
};

const UploadReferencesFromCSVButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const commercialLink = useSelector(getUserCommercialLink);

  function openModal() {
    if (commercialLink === null) {
      notifyTop('error', t('cart.file_upload.seller_is_missing', 'Seller is missing, contact your R1.'), null);
    } else {
      dispatch(setOrderListFileReferencesIsModalOpen(true));
    }
  }

  return (
    <WhiteButton onClick={openModal}>
      <Text type={'h4_paragraph'}>{t('catalog.order_list.upload_your_csv', 'Upload your CSV')}</Text>
    </WhiteButton>
  );
};

const splitOrderList = (orderList: CreateOrderList) => {
  return {
    referencesLeftColumn: [...orderList.references].filter((_v, i) => !(i % 2)),
    referencesRightColumn: [...orderList.references].filter((_v, i) => i % 2),
  };
};

const CreateOrderListPage = () => {
  const { t } = useTranslation();
  const orderList = useSelector(getCreateOrderList);
  const dispatch = useDispatch();
  const large = useLarge();
  const addToCartResponseStatus = useSelector(getCreateOrderListErrorRows);
  const [unknownReferences, setUnknownReferences] = useState<number[]>([]);
  const { referencesLeftColumn, referencesRightColumn } = splitOrderList(orderList);

  const addRow = () => {
    dispatch(addEmptyOrderListRow());
  };

  const handleUnknownReferenceChange = (referenceIndex: number, action: 'add' | 'remove') => {
    if (action === 'add') {
      if (unknownReferences.find((idx) => idx === referenceIndex) === undefined) {
        setUnknownReferences([...unknownReferences, referenceIndex]);
      }
    } else {
      setUnknownReferences(unknownReferences.filter((ref) => ref !== referenceIndex));
    }
  };

  return (
    <Flex direction={'column'}>
      <Flex>
        <Flex direction={'column'} justify={'flex-start'} size={1}>
          <Text type={'h4_paragraph_title'}>{t('catalog.order_list.create_order_list', 'Create order list')}</Text>
          {/* Hide undeveloped features for Anthony's Request*/}
          {/*<MarginBox mt={60} />*/}
          {/*<Box height={40} width={390}>*/}
          {/*  <Input*/}
          {/*    value={orderList.name}*/}
          {/*    onChange={(name) => dispatch(setOrderListName(name))}*/}
          {/*    placeholder={t('catalog.order_list.order_list_name', 'Order list name')}*/}
          {/*    bordered*/}
          {/*    maxLength={63}*/}
          {/*  />*/}
          {/*</Box>*/}
        </Flex>
        <Flex direction={'column'} justify={'flex-end'} size={0}>
          <Box width={240}>
            <AddReferenceListToCartButton
              searchStatus={addToCartResponseStatus?.searchStatus}
              hasUnknownReference={unknownReferences.length > 0}
            />
            <MarginBox mt={30} />
            <UploadReferencesFromCSVButton />
          </Box>
        </Flex>
      </Flex>
      <Flex direction={'column'} justify={'center'}>
        <FileReferencesRowsErrors errorRows={addToCartResponseStatus?.data ?? []} />
        <OrderListReferencesFileUpload />
        <MarginBox mt={30} />
        {large ? (
          <Flex direction={'row'}>
            <OrderListTable
              references={referencesLeftColumn}
              handleUnknownReferenceChange={handleUnknownReferenceChange}
            />
            <MarginBox mr={30} />
            <OrderListTable
              references={referencesRightColumn}
              handleUnknownReferenceChange={handleUnknownReferenceChange}
            />
          </Flex>
        ) : (
          <OrderListTable
            references={orderList.references}
            handleUnknownReferenceChange={handleUnknownReferenceChange}
          />
        )}
      </Flex>
      <MarginBox mt={30} />
      <PlusCircleButton onClick={addRow}>
        <Text type={'link_bold'}>{t('catalog.order_list.add_new_reference', 'Add new reference')}</Text>
      </PlusCircleButton>
      <MarginBox mt={30} />,
    </Flex>
  );
};

export default CreateOrderListPage;
