import { Client } from '@stomp/stompjs';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { getAuthenticatedUser } from 'domains/auth/Auth.util';
import { sendMessage } from 'domains/webSockets/utils/sendMessage';
import { WebSocketAction } from 'utils/domainStore';
import { RequestState } from './enum/RequestState';
import { setupStompClient } from './setupStompClient';
import { checkAddSetIsExpired } from './utils/utils';
import { addRequest, isConnected } from './WebSocket.store';
import { WS_CONNECT, WS_DISCONNECT, WS_SEND_REQUEST } from './WebSocket.types';

export const socketMiddleware = (): Middleware => {
  let stompClient: Client | null = null;

  window.addEventListener('unload', () => {
    if (stompClient?.connected) {
      stompClient.deactivate();
    }
  });

  return ({ dispatch, getState }: MiddlewareAPI) => (next: Dispatch) => async (action: WebSocketAction) => {
    switch (action.type) {
      case WS_SEND_REQUEST: {
        const isExpired = await checkAddSetIsExpired(dispatch);
        if (isConnected(getState()) && !isExpired) {
          sendMessage(stompClient, action, getState, dispatch);
          dispatch(addRequest(action));
        } else {
          action.state = RequestState.PENDING;
          dispatch(addRequest(action));
        }
        break;
      }

      case WS_CONNECT: {
        if (isConnected(getState())) {
          console.warn('WS connect received, but connection is already established');
          break;
        }
        const user = await getAuthenticatedUser();
        if (user?.expired) {
          console.error('Token is expired - Connection to BE aborted');
        } else if (!user?.access_token) {
          console.error('WS connect received, but user is not logged in');
        } else {
          stompClient?.deactivate();
          stompClient = setupStompClient(dispatch, getState);
          stompClient.activate();
        }
        break;
      }

      case WS_DISCONNECT: {
        stompClient?.deactivate();
        break;
      }

      default:
        return next(action);
    }
    return undefined;
  };
};
