import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  AngleDownIcon,
  AngleRightIcon,
  BarsIcon,
  CarIcon,
  CogsIcon,
  FileAltIcon,
  HourglassIcon,
  LocationIcon,
} from 'assets/icons';
import { getLastSearchVehicleTechnicalData } from 'domains/catalog/Catalog.store';
import { FLUIDS_ID, FLUIDS_TAB, INTERVALS_ID, INTERVALS_TAB, MaintenanceTabsType } from 'domains/catalog/Catalog.types';
import { getBannersCount } from 'domains/information/Information.store';
import { getUserRights, UserRole } from 'domains/user';
import { BottomStickyBox } from 'pages/CatalogPage/IAM/MaintenancePlan/BottomStickyBox';
import {
  ServicesContent,
  SText,
} from 'pages/CatalogPage/IAM/MaintenancePlan/DefaultMaintenance/DefaultMaintenance.styled';
import DefaultMaintenanceTable from 'pages/CatalogPage/IAM/MaintenancePlan/DefaultMaintenance/DefaultMaintenanceTable';
import { MaintenanceCard } from 'pages/CatalogPage/IAM/MaintenancePlan/MaintenancePlan.styled';
import { theme } from 'styles';
import { BlueButton, Box, CenterFlex, Flex, Icon, IconType, Image, PaddingBox, Text } from 'UI';
import { getData, hasUserAnyRight } from 'utils';
import {
  OFFSET_LINK_TABS,
  OFFSET_PADDING,
  OFFSET_SUB_HEADER,
  OFFSET_VEHICLE_INFO_COMPACT,
} from 'utils/hooks/useOffsetTop';
import { scrollToElementSmooth } from 'utils/hooks/useResetScroll';

interface MaintenanceTabsDataType {
  id: MaintenanceTabsType;
  icon: IconType;
  title: string;
  description: string;
  action: () => void;
  requiredRights: UserRole[];
}

const getHeader = (tab: MaintenanceTabsType | undefined) => {
  switch (tab) {
    case INTERVALS_TAB:
      return (
        <SText type={'h4_paragraph_title'}>
          <Trans i18nKey={'catalog.maintenance.change_intervals.title'}>Change intervals</Trans>
        </SText>
      );
    case FLUIDS_TAB:
      return (
        <SText type={'h4_paragraph_title'}>
          <Trans i18nKey={'catalog.maintenance.fluids.title'}>Fluids</Trans>
        </SText>
      );
    default:
      break;
  }
  return <></>;
};

const DefaultMaintenance = ({ handleNext }: { handleNext: () => void }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<MaintenanceTabsType | undefined>(undefined);
  const isBanner = useSelector((state: RootState) => getBannersCount(state, 'OTHER_BRANDS_CATALOG')) > 0;
  const userRights = getData(useSelector(getUserRights));
  const technicalData = useSelector(getLastSearchVehicleTechnicalData);
  const fluids = technicalData?.find((d) => d.id === FLUIDS_ID);
  const intervals = technicalData?.find((d) => d.id === INTERVALS_ID);

  const scrollToTabContent = () => {
    const subcategoryElement = document.getElementById('default-maintenance-tab-table');
    if (subcategoryElement) {
      scrollToElementSmooth(
        subcategoryElement,
        isBanner,
        OFFSET_SUB_HEADER + OFFSET_PADDING + OFFSET_VEHICLE_INFO_COMPACT + OFFSET_LINK_TABS,
      );
    }
  };

  const maintenanceTabsData: MaintenanceTabsDataType[] = [
    {
      id: INTERVALS_TAB,
      icon: HourglassIcon,
      title: t('catalog.maintenance.change_intervals.title', 'Change intervals'),
      description: t(
        'catalog.maintenance.change_intervals.description',
        'Replacement intervals recommended by manufacturer.',
      ),
      action: () => {
        setTab(INTERVALS_TAB);
        scrollToTabContent();
      },
      requiredRights: [],
    },
    {
      id: FLUIDS_TAB,
      icon: LocationIcon,
      title: t('catalog.maintenance.fluids.title', 'Fluids'),
      description: t(
        'catalog.maintenance.fluids.description',
        'Car fluids that need to be flushed and replenished in your vehicle.',
      ),
      action: () => {
        setTab(FLUIDS_TAB);
        scrollToTabContent();
      },
      requiredRights: [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
    },
  ];

  const getServicesData = () => {
    return [
      {
        id: '1',
        icon: <Icon IconComponent={CogsIcon} size={48} color={theme.color.info} display={'block'} noPointer />,
        title: t('catalog.maintenance.services.identify_operations.title', 'Identify servicing operations'),
        paragraphs: [
          {
            text: t(
              'catalog.maintenance.services.identify_operations.description',
              //eslint-disable-next-line
              'From routine checks like oil changes and brake inspections to comprehensive guides for all car models. Ensure optimal vehicle performance and longevity with our detailed, model-specific servicing insights.',
            ),
          },
        ],
      },
      {
        id: '2',
        icon: <Icon IconComponent={CarIcon} size={48} color={theme.color.info} display={'block'} noPointer />,
        title: t('catalog.maintenance.services.operation_parts.title', 'Find parts for each operation'),
        paragraphs: [
          {
            text: t(
              'catalog.maintenance.services.operation_parts.description',
              //eslint-disable-next-line
              'Effortlessly locate and acquire essential auto parts tailored for every maintenance task. Ensure your vehicle always receives the right components to sustain peak performance navigating through our comprehensive database.',
            ),
          },
        ],
      },
      {
        id: '3',
        icon: <Icon IconComponent={FileAltIcon} size={48} color={theme.color.info} display={'block'} noPointer />,
        title: t('catalog.maintenance.services.generate_estimate.title', 'Generate estimate with labor time'),
        paragraphs: [
          {
            text: t(
              'catalog.maintenance.services.generate_estimate.description',
              //eslint-disable-next-line
              'Ensures transparent and accurate service quotes by calculating precise labor hours for each maintenance task. Navigate with ease, tailor your services, and receive instant, clear, and detailed estimates to plan.',
            ),
          },
        ],
      },
      {
        id: '4',
        icon: <Icon IconComponent={BarsIcon} size={48} color={theme.color.info} display={'block'} noPointer />,
        title: t('catalog.maintenance.services.create_checklist.title', 'Create revisions control checklist'),
        paragraphs: [
          {
            text: t(
              'catalog.maintenance.services.create_checklist.description',
              //eslint-disable-next-line
              "Streamline your vehicle's maintenance schedule, ensuring no service milestone is overlooked. Utilize our interactive checklist to systematically track, manage, and organize all your client’s car care needs, prioritizing efficiency and longevity in every drive.",
            ),
          },
        ],
      },
    ];
  };

  const getTechnicalData = () => {
    switch (tab) {
      case FLUIDS_TAB:
        return fluids;
      default:
        return intervals;
    }
  };

  return (
    <>
      <SText type={'h4_paragraph_title'}>
        {t('catalog.maintenance.new_maintenance_plan.main_title', 'Create new maintenance plan')}
      </SText>
      <PaddingBox py={15} />
      <CenterFlex direction={'column'}>
        <Image alt={'avatar'} src={'catalog/maintenance-plan-group.png'} width={395} />
        <PaddingBox py={15} />
        {hasUserAnyRight(
          [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
          userRights,
        ) && (
          <Box width={150}>
            <BlueButton shape={'round'} size={'large'} onClick={() => handleNext()}>
              {t('catalog.maintenance.new_maintenance_plan.start', 'Start')}
            </BlueButton>
          </Box>
        )}
      </CenterFlex>
      <PaddingBox py={15} />
      {getHeader(tab)}
      <PaddingBox py={15} />
      <Flex direction={'row'} justify={'center'} gap={20}>
        {maintenanceTabsData.map((item) => {
          const isActive = item.id === tab;
          return (
            hasUserAnyRight(item.requiredRights, userRights) && (
              <MaintenanceCard
                key={item.id}
                active={isActive}
                onClick={item.action}
                maxWidth={800}
                minHeight={130}
                maxHeight={130}
                direction={'row'}
                align={'center'}
                gap={15}
                padding={15}
              >
                <CenterFlex
                  style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: theme.color.ice_blue,
                  }}
                >
                  <Icon IconComponent={item.icon} size={24} color={theme.color.info} />
                </CenterFlex>
                <Flex direction={'column'}>
                  <Text type={'text_dim_bold'}>{item.title}</Text>
                  <Text type={'search_result_base'}>{item.description}</Text>
                </Flex>
                <CenterFlex
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                >
                  <Icon IconComponent={isActive ? AngleDownIcon : AngleRightIcon} size={24} color={theme.color.grey} />
                </CenterFlex>
              </MaintenanceCard>
            )
          );
        })}
      </Flex>
      <PaddingBox py={15} />
      <CenterFlex id={'default-maintenance-tab-table'} direction={'column'}>
        <DefaultMaintenanceTable tab={tab} technicalData={getTechnicalData()} />
      </CenterFlex>
      <PaddingBox py={15} />
      <SText type={'h4_paragraph_title'}>{t('catalog.maintenance.services.title', 'Services')}</SText>
      <PaddingBox py={15} />
      <ServicesContent>
        <Flex direction={'row'} justify={'center'} align={'flex-start'} gap={32}>
          {getServicesData().map((service) => {
            return (
              <Flex maxWidth={400} direction={'column'} key={service.id}>
                <Flex>{service.icon}</Flex>
                <PaddingBox pt={30} pb={12}>
                  <Text type={'h4_paragraph'}>{service.title}</Text>
                </PaddingBox>
                {service.paragraphs.map((linkItem) => (
                  <Box key={uuidv4()}>
                    <Text type={'text'}>{linkItem.text}</Text>
                  </Box>
                ))}
              </Flex>
            );
          })}
        </Flex>
      </ServicesContent>
      {hasUserAnyRight(
        [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
        userRights,
      ) && (
        <BottomStickyBox
          text1={t('catalog.maintenance.new_maintenance_plan.start_title', 'Start new maintenance plan')}
          text2={t(
            'catalog.maintenance.new_maintenance_plan.start_description',
            'Identify servicing operations and find parts for each operations.',
          )}
          callback={handleNext}
          buttonLabel={t('catalog.maintenance.new_maintenance_plan.start', 'Start')}
        />
      )}
    </>
  );
};

export default DefaultMaintenance;
