import styled from 'styled-components';
import { Box, defaultBoxShadow, Flex } from 'UI';

export const SCard = styled.div<{ isSquared?: boolean; isCompact?: boolean }>`
  position: relative;
  padding: ${({ isCompact }) => (isCompact ? '10px 10px' : '15px 15px')};
  transition: height 2s;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ isSquared }) => (isSquared ? '14px 14px 0 0' : '14px')};
  border-bottom-style: ${({ isSquared }) => isSquared && 'none'};
  ${defaultBoxShadow};
`;

export const SBrandImageBox = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.45;
`;

export const SReferenceCardLaborTimesWrapper = styled(Flex)<{
  isProduct?: boolean;
  isSquared?: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ isSquared }) => (isSquared ? '0' : '0 0 14px 14px')};
  border-top-style: none;
  ${({ isSquared }) => isSquared && 'border-top-style: none;'}
  ${({ theme, isProduct }) => (isProduct ? `box-shadow: 0 4px 10px 0 ${theme.color.grey75};` : '')}
  ${defaultBoxShadow};
`;

export const SBox = styled(Box)`
  position: absolute;
  left: 41px;
  top: -15px;
`;

export const CirclePositionWrapper = styled.div`
  height: 100%;
  width: 8px;
  display: flex;
  align-items: center;
`;

export const ReferenceImageContainer = styled(Flex)`
  position: absolute;
  bottom: -11px;
  left: 111px;
`;
