/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { VehicleBrandType } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { DHReferenceLocal } from 'domains/references';
import { usePrice } from 'domains/references/References.requests';
import { getCatalogReferenceView, SparePartsViewType, StandardView } from 'domains/user';
import CrossSellingSection from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard/CrossSellingSection/CrossSellingSection';
import ReferenceCardWrapper from 'pages/CatalogPage/DH/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCard/ReferenceCardWrapper';
import { getSearchData } from 'utils';

export interface PlateReferenceCardProps {
  reference: DHReferenceLocal;
  vehicleBrand: VehicleBrandType;
  sparePartsView: SparePartsViewType;
  displayAdditionalInfo: boolean;
  plateId?: string;
  firstRefWithPrice: string | undefined;
  firstRefWithLaborTimes: string | undefined;
  firstRefWithPriceNotInCart: string | undefined;
  refIndex: number;
  displaySupersessionTooltip: boolean | undefined;
  index?: number;
}

const PlateReferenceCard = ({
  reference,
  vehicleBrand,
  sparePartsView,
  displayAdditionalInfo,
  plateId,
  firstRefWithPrice,
  firstRefWithLaborTimes,
  firstRefWithPriceNotInCart,
  refIndex,
  displaySupersessionTooltip,
  index,
}: PlateReferenceCardProps) => {
  const price = getSearchData(usePrice(reference?.referenceNumber));
  const catalogRefView = useSelector(getCatalogReferenceView);

  return (
    <>
      <ReferenceCardWrapper
        referenceNumber={reference.referenceNumber}
        name={reference.name}
        referenceBrand={reference.brand}
        vehicleBrand={vehicleBrand}
        price={price}
        sparePartsView={sparePartsView}
        additionalInfo={reference.additionalInformation}
        displayAdditionalInfo={displayAdditionalInfo}
        plateId={plateId}
        linkedReferences={reference.linkedReferences ?? []}
        isApplicableToCurrentVehicle={reference.isApplicableToCurrentVehicle}
        firstRefWithPrice={firstRefWithPrice}
        firstRefWithLaborTimes={firstRefWithLaborTimes}
        firstRefWithPriceNotInCart={firstRefWithPriceNotInCart}
        refIndex={refIndex}
        genericPart={reference.genericPart}
        displaySupersessionTooltip={displaySupersessionTooltip}
        useCompactView
        index={index}
      />
      {catalogRefView === StandardView && (
        <CrossSellingSection
          mainRef={reference.referenceNumber}
          crossSellingReferences={reference.crossSelling}
          type={'catalog'}
        />
      )}
    </>
  );
};

export default PlateReferenceCard;
